.fragment--product-image {
  .product-image__tags {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;

    @include desktop {
      flex-direction: row;
      left: 1rem;
      top: 1rem;
    }

    @include touch {
      left: 0;
      top: -0.25rem;
    }
  }

  ul {
    padding: 0;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .thumbails__thumbnail {
    border: 1px solid $color-border-default;
    position: relative;
    width: 5.4rem;
  }

  .thumbails__thumbnail.active {
    &::after {
      background-color: $color-tetrad;
      content: '';
      height: 0.25rem;
      position: absolute;
      top: calc(100% - 0.25rem);
      width: 100%;
    }
  }

  .glide--product-images {
    padding: 2rem 0;

    @include touch {
      padding: 6rem 0 1rem 0;
    }
  }

  .product-image__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .show-dialog__product-image {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .product-image__zoom-notification {
    align-items: center;
    background-color: $color-tetrad;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    max-width: 52%;
    opacity: 0;
    padding: 1rem;
    position: absolute;
    transition: opacity $speed-fast;
  }

  .product-image__zoom-notification.is-visible-in-start {
    opacity: 0.9;
  }

  .show-dialog__product-image:hover .product-image__zoom-notification {
    opacity: 0.9;
  }

  .product-image__zoom-notification-desktop {
    color: $color-grey;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding: 0.56rem 1.63rem 0 0;
  }

  .product-image__zoom-notification-text {
    @include font-small-body-copy();

    color: $color-white;
    padding: 0.75rem 0.25rem;
    text-align: center;
  }

  .product-image__zoom-notification-icons {
    position: relative;
  }

  .glide__track {
    @include touch {
      border: 0;
    }
  }

  .glide__arrow.glide__arrow--small {
    background-color: $color-light;
    margin: 0;
    top: 45%;
  }

  .slider__bullets {
    bottom: 1.5rem;
    display: inline-flex;
    left: 50%;
    list-style: none;
    margin: 4.25rem 0 0.25rem 0;
    position: relative;
    transform: translateX(-50%);
    z-index: 2;
  }

  .slider__bullet {
    background-color: $color-light;
    border: 2px solid transparent;
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 0.56rem;
    line-height: 0;
    margin: 0 0.25rem;
    padding: 0;
    width: 0.56rem;
  }

  .slider__bullet[class*=active] {
    background-color: $color-grey;
  }

  /** PRODUCT IMAGE ZOOM **/
  .glide--product-images-with-zoom,
  .glide__track,
  .glide__slides,
  .product-image-zoom__container {
    height: 100%;
  }

  .product-image-zoom__wrapper {
    height: 100%;
    margin: 0;
    padding: 0;

    @include touch {
      margin-top: 4.8rem;
    }
  }

  .product-image-zoom__main-image-wrapper {
    border-right: 1px solid $color-border-default;
    padding: 0;

    @include touch {
      border: 0;
    }
  }

  .product-image-zoom__container {
    align-items: center;
    display: flex;
    overflow: hidden;

    @include touch {
      padding-top: 3.56rem;
    }
  }

  .product-image-zoom {
    height: 100%;
    object-fit: contain;

    @include touch {
      height: auto;
    }

    &.zoom-out {
      transform: 0;

      &:hover {
        cursor: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMzRweCcgaGVpZ2h0PSczNHB4JyB2aWV3Qm94PScwIDAgMzQgMzQnIHZlcnNpb249JzEuMScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB4bWxuczp4bGluaz0naHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayc+PCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Mi42ICg2NzQ5MSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+PHRpdGxlPnpvb20taW48L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPjxnIGlkPSdQYWdlLTEnIHN0cm9rZT0nbm9uZScgc3Ryb2tlLXdpZHRoPScxJyBmaWxsPSdub25lJyBmaWxsLXJ1bGU9J2V2ZW5vZGQnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCc+PGcgaWQ9J0FBX1Byb2R1Y3Qtem9vbS1pbi0oZCknIHRyYW5zZm9ybT0ndHJhbnNsYXRlKC01ODEuMDAwMDAwLCAtNDM5LjAwMDAwMCknIHN0cm9rZT0nIzM4MkQ1NScgc3Ryb2tlLXdpZHRoPScyJz48ZyBpZD0nem9vbS1pbicgdHJhbnNmb3JtPSd0cmFuc2xhdGUoNTgyLjAwMDAwMCwgNDQwLjAwMDAwMCknPjxwYXRoIGQ9J00zMiwzMiBMMjIuNjIwNjg5NywyMi42MjA2ODk3JyBpZD0nUGF0aCc+PC9wYXRoPjxjaXJjbGUgaWQ9J092YWwnIGN4PScxMy4yNDEzNzkzJyBjeT0nMTMuMjQxMzc5Mycgcj0nMTMuMjQxMzc5Myc+PC9jaXJjbGU+PHBhdGggZD0nTTEzLjI0MTM3OTMsOC44Mjc1ODYyMSBMMTMuMjQxMzc5MywxNy42NTUxNzI0JyBpZD0nUGF0aCc+PC9wYXRoPjxwYXRoIGQ9J00xNy42NTUxNzI0LDEzLjI0MTM3OTMgTDguODI3NTg2MjEsMTMuMjQxMzc5MycgaWQ9J1BhdGgnPjwvcGF0aD48L2c+PC9nPjwvZz48L3N2Zz4=), zoom-in;
      }
    }

    &.zoom-in {
      transform: scale(2, 2) translateZ(100px);

      &:hover {
        cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0nMS4wJyBlbmNvZGluZz0nVVRGLTgnPz48c3ZnIHdpZHRoPSczNHB4JyBoZWlnaHQ9JzM0cHgnIHZpZXdCb3g9JzAgMCAzNCAzNCcgdmVyc2lvbj0nMS4xJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJz48IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT48dGl0bGU+em9vbS1vdXQ8L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPjxnIGlkPSdQYWdlLTEnIHN0cm9rZT0nbm9uZScgc3Ryb2tlLXdpZHRoPScxJyBmaWxsPSdub25lJyBmaWxsLXJ1bGU9J2V2ZW5vZGQnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCc+PGcgaWQ9J0FBX1Byb2R1Y3Qtem9vbS1vdXQoZCknIHRyYW5zZm9ybT0ndHJhbnNsYXRlKC01ODEuMDAwMDAwLCAtNDM5LjAwMDAwMCknIHN0cm9rZT0nIzM4MkQ1NScgc3Ryb2tlLXdpZHRoPScyJz48ZyBpZD0nem9vbS1vdXQnIHRyYW5zZm9ybT0ndHJhbnNsYXRlKDU4Mi4wMDAwMDAsIDQ0MC4wMDAwMDApJz48cGF0aCBkPSdNMzIsMzIgTDIyLjQsMjIuNCcgaWQ9J1BhdGgnPjwvcGF0aD48Y2lyY2xlIGlkPSdPdmFsJyBjeD0nMTMuMicgY3k9JzEzLjInIHI9JzEzLjInPjwvY2lyY2xlPjxwYXRoIGQ9J00xNy42LDEzLjIgTDguOCwxMy4yJyBpZD0nUGF0aCc+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==), zoom-out;
      }
    }
  }

  .dialog-thumbails__list,
  .thumbails__list {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
  }

  .dialog-thumbails__thumbnail {
    border: 1px solid $color-border-default;
    margin: 2%;
    position: relative;
    width: 4.5rem;
  }

  .dialog-thumbails__product-more-info {
    padding: 0.25rem;
  }

  .dialog-thumbails__container {
    padding: 3.56rem 1.56rem 0 1.25rem;
    width: 100%;

    @include touch {
      padding: 0.875rem;
    }
  }

  .dialog-thumbails__thumbnail.active {
    &::after {
      background-color: $color-tetrad;
      content: '';
      height: 0.25rem;
      position: absolute;
      top: calc(100% - 0.25rem);
      width: 100%;
    }
  }

  @include desktop {
    .product-image__fallback {
      max-width: 100%;
      padding: 0 2rem;
    }
  }
}
