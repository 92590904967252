.component--inline-progress-indicator {
  .progress-indicatior__list {
    display: flex;
    position: relative;

    &::before {
      @include tick($color: $color-grey, $size: 0.7em, $tickness: 2px);

      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.in-progress::before {
      border-color: $color-medium;
    }
  }

  .progress-indicator__item {
    @include fancy-border($allPositions: true, $width: 4px, $color-border: $color-border-default);

    background-color: $color-complementary;
    border-width: 2px;
    display: inline-block;
    height: 2rem;
    line-height: calc(2rem - 4px);
    text-align: center;
    transform: rotate(-45deg);
    width: 2rem;

    // &.in_progress {
    //   @include fancy-border($allPositions: true, $width: 4px, $color-border: $color-medium, $color-outline: $color-lighter);

    //   background-color: $color-complementary-tint;
    //   border-width: 2px;
    // }
  }

  .progress-indicator__number {
    @include font-family-title();

    color: $color-white;
    transform: rotate(45deg);
  }

  .progress-indicatior__list-item {
    position: relative;
    text-align: center;
    width: 50%;

    &::before,
    &::after {
      background: $color-medium;
    }

    &::before {
      @include background-line($position: left, $width: calc(50% - 5px - 1rem), $zIndex: 1);
    }

    &::after {
      @include background-line($position: right, $width: calc(50% - 5px - 1rem), $zIndex: 1);
    }

    &:first-child::before {
      @include background-line($position: left, $width: 500%, $sitePosition: calc(-450% - 2rem + 10px), $zIndex: 1);

      @include touch {
        left: -1rem;
        width: calc(50% - 5px);
      }
    }

    &:last-child::after {
      @include background-line($position: right, $width: 500%, $sitePosition: calc(-450% - 2rem + 10px), $zIndex: 1);

      @include touch {
        right: -1rem;
        width: calc(50% - 5px);
      }
    }

    &.to_do::before,
    &.to_do::after,
    &.in_progress::after,
    &.to_do > .progress-indicator__item {
      opacity: 0.25;
    }

    &.failure {
      .progress-indicator__item {
        background-color: $color-notification-error-tint;
        border-color: $color-notification-error;
      }

      .progress-indicator__number {
        color: $color-notification-error;
      }
    }
  }

  .progress-indicator__text-wrapper {
    display: flex;
    padding: 0.5rem 0 $space-1x 0;
  }

  .progress-indicator__text {
    @include font-h5();
    @include text-uppercase();

    color: $color-complementary !important;
    text-align: center;
    width: 50%;

    @include desktop {
      @include font-h4();
    }

    &.failure {
      color: $color-notification-error !important;
    }
  }
}

.page--payment .component--inline-progress-indicator {
  margin: 0 -1rem;
}

.container--order-confirmation {
  margin-top: 5rem;
}
