.fragment--product-specifications {
  .product-specifications__category-container {
    flex-direction: row;

    @include touch {
      flex-direction: column;
    }
  }

  .product-specifications__attribute-list {
    > div {
      padding: 0.375rem 0;
    }

    > div + div {
      border-top: 1px solid $color-light;
    }

    dt {
      color: $color-grey;
    }
  }
}
