.container--accordion {
  .accordion__list {
    @include fancy-border($positions: top);

    > li {
      border-bottom: 1px solid $color-light;
      padding: 1.125rem 0;
    }
  }

  .accordion__title {
    @include font-h3;
    @include text-uppercase;

    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;

    &::after {
      @include tick($direction: bottom, $color:  $color-tetrad, $size: 0.5em, $tickness: 2px);

      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  .accordion__content {
    display: none;
  }

  input[type='checkbox']:checked ~ .accordion__title,
  .accordion__title.active {
    &::after {
      @include tick($direction: top, $color:  $color-tetrad, $size: 0.5em, $tickness: 2px);
    }

    ~ .accordion__content {
      display: block;
    }
  }
}
