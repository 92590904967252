figure {
  margin: 0;
}

img {
  max-width: 100%;
}

video {
  //
}
