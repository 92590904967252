.autosuggest__results {
  background-color: $color-background-white;
  border: 1px solid $color-border-light;
  margin-top: 0.25rem;
  padding: 1rem 0.5rem;
  width: 100%;

  > ul {
    margin: 0;
    padding-left: 0;

    > li {
      border-bottom: solid 1px $color-light;
      padding: 0.5rem 0.5rem;
    }

    > li:last-child {
      border: 0;
    }

    > li::before {
      display: none;
    }

    > li:not(.autosuggest__results_item-highlighted) a {
      @extend %link-ui-grey;
    }

    > li.autosuggest__results_item-highlighted {
      background-color: $color-background-grey;
    }

    > li.autosuggest__results_item-highlighted a {
      @extend %link-ui-dark;
    }
  }

  span {
    + span {
      opacity: 0.25;
    }

    > span {
      font-weight: bold;
    }
  }

  span:first-child:not(:last-child)::before {
    padding-right: 0.5rem;
  }
}

.autosuggest__results-container {
  position: relative;

  .autosuggest__results {
    position: absolute;
    z-index: $zindex__component--search-bar__autosuggest__results;
  }
}
