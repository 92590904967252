.container--product-information {
  .product-information__container {
    margin: 0 auto;
    position: relative;

    @include desktop {
      @include fancy-border($allPositions: true, $width: 8px);

      margin-top: 2.5rem;
      max-width: calc(100% - 18px);
    }
  }

  .product-detail__image-wrapper {
    margin: 0 auto;
    width: calc(100% - 2em);

    @include desktop {
      border-right: 1px solid $color-border-default;
      margin-right: 0;
      width: 100%;
    }
  }
}
