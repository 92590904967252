.tooltip {
  display: block !important;
  z-index: 10000;

  > .wrapper {
    margin: initial;
    max-width: initial;
    padding-left: initial;
    padding-right: initial;
    position: initial;
  }

  .tooltip-inner {
    @include font-small-body-copy;

    background: $color-tetrad;
    border-radius: 1rem;
    color: $color-white;
    max-width: 120px;
    padding: 0.75em 1rem;
  }

  .tooltip-arrow {
    border-color: $color-tetrad;
    border-style: solid;
    height: 0;
    margin: 0.75em;
    position: absolute;
    width: 0;
    z-index: 1;
  }

  &:not([x-placement]) {
    @include touch {
      margin-top: 0.75em;

      .tooltip-arrow {
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        border-width: 0 0.75em 0.75em 0.75em;
        left: calc(50% - 0.75em);
        margin-bottom: 0;
        margin-top: 0;
        top: -0.75em;
      }
    }

    @include desktop {
      margin-left: 0.75em;

      .tooltip-arrow {
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-width: 0.75em 0.75em 0.75em 0;
        left: -0.75em;
        margin-left: 0;
        margin-right: 0;
        top: calc(50% - 0.75em);
      }
    }
  }

  &[x-placement^='top'] {
    margin-bottom: 0.75em;

    .tooltip-arrow {
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-width: 0.75em 0.75em 0 0.75em;
      bottom: -0.75em;
      left: calc(50% - 0.75em);
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 0.75em;

    .tooltip-arrow {
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      border-width: 0 0.75em 0.75em 0.75em;
      left: calc(50% - 0.75em);
      margin-bottom: 0;
      margin-top: 0;
      top: -0.75em;
    }
  }

  &[x-placement^='right'] {
    margin-left: 0.75em;

    .tooltip-arrow {
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-width: 0.75em 0.75em 0.75em 0;
      left: -0.75em;
      margin-left: 0;
      margin-right: 0;
      top: calc(50% - 0.75em);
    }
  }

  &[x-placement^='left'] {
    margin-right: 0.75em;

    .tooltip-arrow {
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      border-width: 0.75em 0 0.75em 0.75em;
      margin-left: 0;
      margin-right: 0;
      right: -0.75em;
      top: calc(50% - 0.75em);
    }
  }

  &[aria-hidden='true'] {
    opacity: 0;
    transition: opacity $speed-fast;
    visibility: hidden;
  }

  &[aria-hidden='false'] {
    opacity: 1;
    transition: opacity $speed-fast;
    visibility: visible;
  }

  // &.info {
  //   $color: rgba(#004499, .9);

  //   .tooltip-inner {
  //     background: $color;
  //     color: white;
  //     padding: 24px;
  //     border-radius: 0.75em;
  //     box-shadow: 0 0.75em 30px rgba(black, .1);
  //   }

  //   .tooltip-arrow {
  //     border-color: $color;
  //   }
  // }

  &.popover {
    .popover-inner {
      background: $color-white;
      border: 1px solid $color-border-default;
      border-radius: 0;
      color: $color-text-default;
      max-width: 300px;
      padding: 1rem 1.25rem;
    }

    .popover-arrow {
      border-color: $color-border-default;

      &::after {
        background: $color-white;
        content: '';
        display: block;
        height: 1em;
        left: 0;
        position: absolute;
        transform: rotate(45deg) translate(-1px, calc(-10px + 1px));
        transform-origin: center center;
        width: 1em;
      }
    }
  }
}

.fragment--tooltip {
  .tooltip-close-button {
    cursor: pointer;
    display: none;
    float: right;
    margin: 0 0 0.75em 0.75em;
  }
}
