%ui-dark {
  color: $color-dark;

  a {
    @extend %link-ui-dark;
  }
}

%ui-light {
  color: $color-white;

  a {
    @extend %link-ui-light;
  }
}

.has-dark-ui {
  @extend %ui-dark;
}

.has-light-ui {
  @extend %ui-light;
}
