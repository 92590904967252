// Do not show this on mobile and tablet
@include touch {
  .fragment--list-tile-switch {
    display: none !important;
  }
}

.fragment--list-tile-switch {
  display: flex;
  font-size: 0;
  justify-content: flex-end;
  line-height: 0;

  .is-toggle-trigger:not(:checked) + .is-toggle-label {
    cursor: pointer;
  }

  .is-tile-view:not(:checked) + .is-toggle-label {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIG9wYWNpdHk9Ii4yIj4KICAgICAgICA8cGF0aCBkPSJNLjUuNWgzdjNoLTN6TTYuNS41aDN2M2gtM3pNMTIuNS41aDN2M2gtM3pNLjUgNi41aDN2M2gtM3pNNi41IDYuNWgzdjNoLTN6TTEyLjUgNi41aDN2M2gtM3pNLjUgMTIuNWgzdjNoLTN6TTYuNSAxMi41aDN2M2gtM3pNMTIuNSAxMi41aDN2M2gtM3oiLz4KICAgIDwvZz4KPC9zdmc+Cg==');
  }

  .is-tile-view:checked + .is-toggle-label {
    background-color: $color-light;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMzgyRDU1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0uNS41aDN2M2gtM3pNNi41LjVoM3YzaC0zek0xMi41LjVoM3YzaC0zek0uNSA2LjVoM3YzaC0zek02LjUgNi41aDN2M2gtM3pNMTIuNSA2LjVoM3YzaC0zek0uNSAxMi41aDN2M2gtM3pNNi41IDEyLjVoM3YzaC0zek0xMi41IDEyLjVoM3YzaC0zeiIvPgogICAgPC9nPgo8L3N2Zz4K');
  }

  .is-list-view:not(:checked) + .is-toggle-label {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9IiM5QjlCOUIiIGZpbGwtcnVsZT0ibm9uemVybyIgb3BhY2l0eT0iLjIiPgogICAgICAgIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIyIiByeD0iMSIvPgogICAgICAgIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIyIiB5PSI3IiByeD0iMSIvPgogICAgICAgIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIyIiB5PSIxNCIgcng9IjEiLz4KICAgIDwvZz4KPC9zdmc+Cg==');
  }

  .is-list-view:checked + .is-toggle-label {
    background-color: $color-light;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9IiMzODJENTUiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjIiIHJ4PSIxIi8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjIiIHk9IjciIHJ4PSIxIi8+CiAgICAgICAgPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjIiIHk9IjE0IiByeD0iMSIvPgogICAgPC9nPgo8L3N2Zz4K');
  }
}

.toggle--list-tile-switch.is-toggle-label {
  background-color: $color-background-white;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px $color-light;
  display: inline-block;
  height: 40px;
  width: 40px;
}
