.container--product-list {
  margin: 0;
  position: relative;

  @include desktop {
    .product-list__results-container {
      padding-left: 2rem;
      padding-top: 2rem;
    }
  }

  @include desktop {
    background: linear-gradient(to right, $color-background-white 0%, $color-background-white 25%, $color-background-white-shade 25%, $color-background-white-shade 100%);
  }

  @include fullhd {
    background: linear-gradient(to right, $color-background-white 0%, $color-background-white 30%, $color-background-white-shade 30%, $color-background-white-shade 100%);
  }
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}
