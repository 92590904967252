/* stylelint-disable no-descending-specificity */
a.button,
button,
.button,
input[type='button'],
input[type='submit'] {
  @include font-family-title();
  @include text-uppercase();

  border-radius: 0;
  box-decoration-break: clone;
  cursor: pointer;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 0.85rem 1.5rem;
  text-align: center;
  // transition: background-color $speed-slow;

  @include touch {
    font-size: 1.35rem;
  }

  &.is-primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $color-white;

    &:not([disabled]):hover {
      background-color: $color-text-primary-hover;
      border: 1px solid $color-text-primary-hover;
      color: $color-white;
    }

    &:focus {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;
    }

    &[disabled],
    &[readonly] {
      background-color: $color-primary;
      border-color: $color-primary;
      cursor: default;
      opacity: 0.2;
    }

    &.has-icon {
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
    }

    &.has-icon svg {
      height: calc(2rem - 2px);
      vertical-align: middle;

      &.has-fill {
        fill: $color-light;
      }

      &.has-stroke {
        stroke: $color-light;
      }
    }

    &:not([disabled]).has-icon:hover svg {
      &.has-fill {
        fill: $color-dark;
      }

      &.has-stroke {
        stroke: $color-dark;
      }
    }

    &.is-approved {
      background-color: $color-notification-success;
      border-color: $color-notification-success;
    }

    &.is-color-complementary {
      background-color: $color-complementary;
      border: 1px solid $color-complementary;
      color: $color-white;

      &:not([disabled]):hover {
        background-color: $color-complementary-hover;
        border-color: $color-complementary-hover;
        text-decoration: none;
      }

      &:focus {
        background-color: $color-complementary;
        border: 1px solid $color-complementary;
        color: $color-white;
      }

      &[disabled],
      &[readonly] {
        border-color: $color-complementary;
        color: $color-white;
        cursor: default;
        opacity: 0.2;
      }

      &.is-approved {
        background-color: $color-notification-success;
        border-color: $color-notification-success;
      }
    }
  }

  &.is-secondary {
    background-color: $color-white;
    border: 1px solid $color-primary;
    color: $color-primary;

    &:not([disabled]):hover {
      border-color: $color-text-primary-hover;
      color: $color-text-primary-hover;
      text-decoration: none;
    }

    &:focus {
      background-color: $color-white;
      border: 1px solid $color-primary;
      color: $color-primary;
    }

    &[disabled],
    &[readonly] {
      background-color: $color-white;
      border: 1px solid $color-primary;
      cursor: default;
      opacity: 0.2;
    }

    &.has-icon svg {
      .has-fill {
        fill: $color-dark;
      }

      .has-stroke {
        stroke: $color-dark;
      }
    }

    &:not([disabled]).has-icon:hover svg {
      .has-fill {
        fill: $color-light;
      }

      .has-stroke {
        stroke: $color-light;
      }
    }

    &.is-color-complementary {
      background-color: $color-white;
      border: 1px solid $color-complementary;
      color: $color-complementary;

      &:not([disabled]):hover {
        border-color: $color-complementary-hover;
        color: $color-complementary-hover;
        text-decoration: none;
      }

      &:focus {
        background-color: $color-white;
        border: 1px solid $color-complementary;
        color: $color-complementary;
      }

      &[disabled],
      &[readonly] {
        border-color: $color-complementary;
        color: $color-complementary;
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  &.is-tertiary {
    background-color: transparent;
    border: 0;
    color: $color-primary;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: 0;

    @include desktop {
      font-size: 0.875rem;
    }

    &:not([disabled]):hover {
      color: $color-text-primary-hover;
    }

    &[disabled],
    &[readonly] {
      background-color: transparent;
      cursor: default;
      opacity: 0.2;
    }

    &.is-color-complementary {
      color: $color-complementary;

      &:not([disabled]):hover {
        color: $color-complementary-hover;
      }

      &:focus {
        color: $color-complementary;
      }

      &[disabled],
      &[readonly] {
        color: $color-complementary;
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  &.is-small {
    font-size: 1rem;
    height: 2.5rem;
    padding: 0.75rem 1.5rem;

    &.is-single-line,
    &.is-single-line:hover {
      padding: 0;
      line-height: 2.5rem;
      border-width: 0;
    }
  }

  // https://github.com/tobiasahlin/SpinKit/blob/master/scss/spinners/7-three-bounce.scss
  > span.is-state-loading,
  > span.is-state-approved {
    display: none;
  }

  &.is-loading > span.is-state-default,
  &.is-approved > span.is-state-default {
    display: none;
  }

  &.is-loading > span.is-state-loading {
    display: inline-flex;
    margin: 0 0.5rem;
    position: relative;
    text-indent: -9999rem;

    &,
    &::after,
    &::before {
      animation: button-loading 1.4s infinite ease-in-out both;
      background-color: $color-complementary-tint;
      border-radius: 100%;
      height: 12px;
      transform-origin: center center;
      vertical-align: middle;
      width: 12px;
    }

    &::after,
    &::before {
      animation-delay: -0.16s;
      content: '';
      position: absolute;
    }

    &::before {
      left: -1rem;
    }

    &::after {
      right: -1rem;
    }
  }

  &.is-approved > span.is-state-approved {
    display: block;
  }

  &.is-approved {
    background-color: $color-notification-success;
    border-color: $color-notification-success;
  }

  &:not(.has-no-max-width) {
    max-width: 300px;
  }

  &.is-fullwidth {
    display: flex;
    max-width: none;
    width: 100%;
  }

  &:not(.has-no-margin) {
    margin: 1.25rem 1.25rem 1.25rem 0;
  }
}

a.button {
  text-decoration: none;
}

.button::-moz-focus-inner {
  border: 0;
  margin: 0;
  padding: 0;
}

svg[role='button'] {
  cursor: pointer;
}

.button-container {
  @include desktop {
    display: flex;
    flex-wrap: wrap;
  }

  a.button,
  button,
  .button,
  input[type='button'],
  input[type='submit'] {
    display: block;
    margin-right: 0;
    margin-top: 1rem;
    width: 100%;

    @include desktop {
      margin-right: 1.25rem;
      width: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@keyframes button-loading {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@include desktop {
  .buttons-list {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .button-container {
      margin-right: 1rem;
    }
  }
}

/* stylelint-enable no-descending-specificity */
