ol,
ul {
  list-style-position: inside;

  &.is-unstyled {
    list-style-type: none;
  }

  &.has-no-indent {
    padding-left: 0;
  }

  &.is-forced-on-one-line {
    white-space: nowrap;
  }

  &.has-no-margin {
    margin: 0;
  }

  &.has-no-padding {
    padding: 0;
  }

  &.has-icon-left[data-icon] {
    > li {
      padding-left: 1.5rem;
      position: relative;
    }

    &[data-icon='download'] > li::before {
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzk3OTc5NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMTIuMzgzIDkuNjd2Mi4xN2MwIC4zLS4zMjEuNTQzLS43MTcuNTQzSDIuMzM0Yy0uMzk2IDAtLjcxNy0uMjQzLS43MTctLjU0MlY5LjY2OSIvPjxwYXRoIGQ9Ik00LjM3NSAwTDQuMzc1IDguNzVNOC43NSA0LjM3NUw0LjM3NSA4Ljc1IDAgNC4zNzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuNjI1IDEuMzEzKSIvPjwvZz48L3N2Zz4=') no-repeat;
      bottom: 0;
      content: '';
      height: 1rem;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(0.5rem);
      width: 1rem;
    }
  }
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: $space-1x;
  margin-top: 0;
}

.list-info {
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      flex: 1;
      margin: 0;
    }
  }
}

ol:not(.is-unstyled) {
  counter-reset: item;
  list-style-type: none;

  > li::before {
    content: counter(item) '. ';
    counter-increment: item;
    font-weight: bold;
  }
}

ul:not(.is-unstyled) {
  list-style-type: none;

  > li::before {
    background: $color-tetrad;
    bottom: 0;
    content: '';
    height: 0.375rem;
    top: 0.7rem;
    transform: rotate(45deg);
    width: 0.375rem;
  }
}

ol:not(.is-unstyled) > li,
ul:not(.is-unstyled) > li {
  padding-left: 1.5rem;
  position: relative;

  &::before {
    left: 0;
    position: absolute;
  }
}

.dlist-info {
  dt {
    @include font-weight-bold();
  }

  dd {
    margin-bottom: $space-1x;
  }
}

dl {
  &.has-no-indent dd {
    margin-left: 0;
  }

  &:not(.has-no-margin) {
    margin: 1rem 0;
  }

  &.has-no-margin {
    margin: 0;
  }

  &.has-no-padding {
    padding: 0;
  }

  &.has-space-between dd {
    padding-bottom: 1rem;
  }

  &.has-bold-dt dt {
    @include font-weight-bold();
  }
}
