.component--assortment-tile {
  a {
    text-decoration: none;
  }

  li {
    @include text-uppercase;
  }

  .assortment-tile__centered {
    align-items: center;
  }

  .side-heading__content-container__link {
    line-height: 1.5rem;
    padding-left: 1.3rem;
    white-space: nowrap;
  }

  .assortment-tile__wrapper {
    background-color: $color-light;
    position: relative;
    z-index: 0;

    &::after {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2NkYGD4z8DAwMiAH/yHKcCnGCyHbBI2xXAxdCuRFaNoxOY2kAIQQJEjWyFRVhPlGaKCh6gABwBcgBIJm5yNfAAAAABJRU5ErkJggg==');
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.05;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }

    @include touch {
      display: flex;
      flex-direction: column;
      padding: 3.125rem 0;
    }
  }

  .assortment-tile__container {
    display: grid;
    grid-auto-rows: minmax(16.1875rem, auto);
    grid-gap: 2.25rem;
    grid-template-columns: repeat(4, 1fr);
    padding: 2.5rem 3.75rem;

    @include touch {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
    }
  }

  .assortment-tile__header {
    @include text-uppercase;
    @include font-h1;

    padding: 2rem 0 1rem 3.3rem;

    @include touch {
      padding: 0 0 2rem  1.3rem;
      text-align: left;
    }
  }

  .assortment-tile_tile {
    background-color: $color-light;
    margin: 0.5rem 1.3rem;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @include touch {
      border: 1px solid $color-border-default;
      width: 100%;
    }
  }

  .assortment-tile_tile a {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: 1;

    @include touch {
      flex-direction: row;
    }
  }

  .assortment-tile__media-figure-container {
    @include fancy-border($allPositions: true, $width: 0.5rem);

    overflow: hidden;
    transform: rotate(45deg) scale(1.6);

    @include touch {
      max-width: 6rem;
      position: relative;
      right: 0.8rem;
      transform: rotate(45deg) scale(1.5);
    }
  }

  .assortment-tile__text {
    @include font-h4;

    color: $color-primary;
    overflow: hidden;

    @include touch {
      margin-left: 3.4rem;
      width: 90%;
    }
  }

  .assortment-tile__image {
    object-fit: cover;
    transform: rotate(-45deg) scale(1.5);
    transform-origin: center center;

    @include touch {
      transform: rotate(-45deg) translateX(22%);
    }
  }

  .assortment-tile__mobile-text-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -0.875rem;
  }

  @include desktop {
    .assortment-tile_tile {
      background-color: transparent;
      overflow: visible;
    }

    .assortment-tile__media-figure-container {
      height: 9.75rem;
      position: relative;
      transform: rotate(45deg);
      width: 9.75rem;
      z-index: 0;
    }

    .assortment-tile__text-container {
      background-color: #ebebeb;
      border-bottom: 1px solid #979797;
      border-top: 1px solid #979797;
      box-shadow: 0 0 0 0.5rem #ebebeb;
      display: flex;
      justify-content: center;
      position: relative;
      top: -1.5rem;
      width: 12.1875rem;
    }
  }
}
