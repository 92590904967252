.page--password-forgotten {
  .password-forgotten__input-captcha {
    padding-top: 2rem;
  }

  input[type='email'][pattern]:invalid:not(:focus):not(.placeholder-shown) {
    background-color: $color-white;
    border: 1px solid $color-light;

    ~ span {
      color: $color-grey;
    }

    ~ .form-notifications .is-invalid {
      display: none;
    }
  }

  input[type='email'][pattern]:invalid:not(:focus):not(.placeholder-shown).has-error,
  .allow-validation input[type='email'][pattern]:invalid:not(:focus):not(.placeholder-shown) {
    @include placeholder-shown-invalid;

    ~ span {
      color: $color-notification-error;
    }
  }

  .password-forgotten__description {
    padding: 2rem 0 1.5rem 0;
  }

  .password-forgotten__title {
    @include font-personal-titles();
  }
}

.recaptcha__error {
  color: $color-notification-error;
  font-size: 0.85em;
}
