.product-tags--lifecycle {
  @include font-small-body-copy;
  @include font-weight-bold;
  @include text-align-center;
  @include text-uppercase;

  background-color: $color-tetrad;
  clip-path: polygon(calc(100% - 5px) 0, 100% 50%, calc(100% - 5px) 100%, 5px 100%, 0% 50%, 5px 0%);
  color: $color-secondary-promotion;
  display: inline-block;
  letter-spacing: 0.75px;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0 0.75rem;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
