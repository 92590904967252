$path-fonts: '../fonts';
$path-images: '../images';

$radius: 0.5rem;
$radius-small: 0.125rem;

// $speed-fast-extra
$speed-fast: 120ms;
$speed: 300ms;
$speed-slow: 768ms;
// $speed-slow-extra

$opacity: 0.5;

$wrapper-max-width: 1162px;
$distance-between-components: 5rem;

$product-tile-container-full-height: 625px;
$product-tile-container-mobile-height: 565px;

$notifications--font-size: 0.75rem !default;
$notifications--line-height: 1.67 !default;
