.page--product-list {
  .product-list__view-options {
    padding: 0 0.4rem 0 0.5rem;

    &.columns {
      flex-wrap: wrap;

      .column.is-full-width {
        flex-basis: 100%;
      }

      .is-grey {
        color: $color-grey;
        font-size: 0.7em;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .product-list__switch {
    @include desktop {
      padding-right: 1.5rem;
    }
  }
}
