.component--quick-order {
  position: relative;
  z-index: 2;

  @include desktop {
    margin: 0;

    .form-row > :first-child:not(:last-child) {
      flex-basis: calc(66.6667% - 0.5rem);
    }

    .form-row > :last-child:not(:first-child) {
      flex-basis: calc(33.3334% - 0.5rem);
    }
  }

  @include touch {
    margin-bottom: 0.5rem;

    .column {
      padding-bottom: 0;
    }
  }

  .quick-order__links {
    display: flex;

    &.quick-order__links-with-spol {
      @include desktop {
        float: left;
      }
    }
  }

  .quick-order__search-field {
    position: relative;

    &::after {
      content: '';
      height: 1rem;
      position: absolute;
      right: 0.5rem;
      top: 1rem;
      width: 1rem;
    }
  }

  .quick-order__quantity-field {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include touch {
      padding-top: 1rem;
    }

    > label {
      flex-basis: calc(33.3334% - 0.5rem);
    }

    > button {
      flex-basis: calc(66.6667% - 0.5rem);
    }
  }

  .quick-order__import-container {
    padding-top: 1rem;
  }

  .quick-order__import-button,
  .quick-order__download-button {
    @extend %link-ui-primary;

    cursor: pointer;
    font-size: 14px;
    line-height: initial;

    @include touch {
      display: block;
      margin: 1rem 0;
    }
  }

  .quick-order__import-button {
    margin-right: 1.5rem;
  }

  .quick-order__download-button {
    @include desktop {
      margin-right: 1.5rem;
    }
  }

  .quick-order__import-button::before,
  .quick-order__download-button::before {
    content: '';
    display: inline-block;
    height: 12px;
    margin-right: 0.5rem;
    vertical-align: middle;
    width: 12px;
  }

  .quick-order__import-button::before {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI0YwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgICAgICAgPHBhdGggZD0iTTEwLjYxNCA4LjI4OHYxLjg2MWMwIC4yNTctLjI3NS40NjUtLjYxNS40NjVIMi4wMDFjLS4zNCAwLS42MTUtLjIwOC0uNjE1LS40NjV2LTEuODYiLz4NCiAgICAgICAgPHBhdGggZD0iTTYgMS4xMjVMNiA4LjYyNSIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMCA5Ljc1KSIvPg0KICAgICAgICA8cGF0aCBkPSJNOS43NSAxLjEyNUw2IDQuODc1IDIuMjUgMS4xMjUiIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIC0xIDAgNikiLz4NCiAgICA8L2c+DQo8L3N2Zz4NCg==') no-repeat;
  }

  .quick-order__download-button::before {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI0YwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgICAgICAgPHBhdGggZD0iTTEwLjYxNCA4LjI4OHYxLjg2MWMwIC4yNTctLjI3NS40NjUtLjYxNS40NjVIMi4wMDFjLS4zNCAwLS42MTUtLjIwOC0uNjE1LS40NjV2LTEuODYiLz4NCiAgICAgICAgPGc+DQogICAgICAgICAgICA8cGF0aCBkPSJNMy43NSAwTDMuNzUgNy41IiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAyLjI1IDguNjI1KSBtYXRyaXgoMSAwIDAgLTEgMCA3LjUpIi8+DQogICAgICAgICAgICA8cGF0aCBkPSJNNy41IDBMMy43NSAzLjc1IDAgMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMi4yNSA4LjYyNSkgbWF0cml4KDEgMCAwIC0xIDAgMy43NSkiLz4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg0K') no-repeat;
  }
}
