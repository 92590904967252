.fragment--cookie-banner {
  background: $color-background-white;
  border: 1px solid $color-border-default;
  left: 0;
  margin: 0 1.5rem;
  max-width: $wrapper-max-width;
  padding: 1.5rem 1.5rem 2rem;
  position: fixed;
  right: 0;
  top: 2.5rem;
  z-index: $zindex__fragment--cookie-banner;

  @include desktop {
    border-bottom: 0;
    bottom: 0;
    margin: 0 auto;
    top: initial;
  }

  .cookie-banner_close-button {
    @include text-align-right;
  }
}

body.has-cookiebanner-opened::before {
  @include backdrop();

  top: 0;
  z-index: $zindex__fragment--cookie-banner__body;
}

.otnotice {
  .otnotice-content {
    padding-left: 0;
    padding-right: 0;
  }

  .otnotice-sections {
    margin-left: 0;
    margin-right: 0;

    section.otnotice-section h2.otnotice-section-header,
    section.otnotice-section div.otnotice-section-content {
      margin-left: 0;
    }
  }
}

.ot-pc-footer,
.ot-main-content,
.save-preference-btn-container {
  .ot-pc-footer-logo a.powered-by-logo[href]::after,
  .ot-pc-footer-logo a[href][target]::after {
    margin-left: 3.5rem;

    @include desktop {
      margin-left: -0.75rem;
    }
  }
}

/* stylelint-disable selector-max-id */
#ot-sdk-cookie-policy,
.otnotice,
.ot-wrapper {
  margin: 0 auto;
  max-width: $wrapper-max-width;
  padding-left: 1rem;
  padding-right: 1rem;
}

#onetrust-banner-sdk.otFloatingRoundedIcon.default {
  left: 50%;
  transform: translateX(-50%);
}

#onetrust-pc-sdk button {
  &#close-pc-btn-handler.ot-close-icon,
  &#vendor-close-pc-btn-handler.ot-close-icon {
    right: 15px;
  }
}

/* stylelint-enable selector-max-id */
