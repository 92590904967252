.component--request-and-order-popup {
  text-align: left;

  h2.title {
    color: $color-tetrad;
    text-transform: none;
    font-size: 32px;
    font-weight: 500;
    line-height: 2.3rem;
  }

  .wraper {
    display: flex;
    width: 100%;
  }

  @include touch {
    .content {
      margin-top: 0;
    }
  }

  .bottom-items {
    display: flex;
    margin: 1.8rem 0;

    @include touch {
      flex-direction: column;
      gap: 2rem;
    }
  }

  .form-group {
    align-content: flex-start;
    width: min-content;

    @include touch {
      width: 100%;
    }
  }

  .form-input {
    width: 4.5rem;
    min-width: 4.5rem;
  }

  .form-input .form-notifications {
    width: max-content;
    max-width: 400%;

    @include touch {
      max-width: calc(100vw - 96px);
    }

    .is-invalid {
      position: static;
      background: transparent;
      max-width: 100%;
    }

    .is-help.text-grey {
      padding: 0;
    }
  }

  .form-label {
    position: relative;
    width: 100%;
    order: 0;
    flex-basis: 100%;
    font-size: 0.75rem;

    .product-tile__notifications__cart {
      &.product-cart-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: initial;
        left: initial;
      }

      &--inner.font-family-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
        top: 0;
        right: 0;
        justify-content: flex-end;
        border: 0;
      }

      &--inner.font-family-title svg {
        height: 16px;
        width: 16px;
        stroke: $color-tetrad;
        opacity: 1;
      }

      &--inner.font-family-title span {
        color: $color-tetrad;
      }

      &--inner::before,
      &--inner::after {
        display: none;
      }

      &--inner .product-tile__notifications__cart__total-of-product::before {
        content: '(';
      }

      &--inner .product-tile__notifications__cart__total-of-product::after {
        content: ')';
      }
    }
  }

  .form-addon {
    width: 100%;

    button {
      min-width: 9rem;
      max-width: unset;
    }
  }

  .order-quantity {
    margin-right: 1.8rem;

    @include desktop {
      max-width: 50%;
    }
  }

  .request-quantity .is-state-default {
    white-space: nowrap;
  }

  .hint__icon {
    display: inline-flex;
    cursor: pointer;
  }

  input[type='number'].is-small {
    margin-right: 0;
  }

  div[name='orderQuantity'] .form-notifications {
    z-index: 2;
    background: $color-background-white;

    .is-invalid {
      padding: 0;
    }
  }

  div[name='orderQuantity'] > .form-notifications {
    z-index: 1;
  }

  a.button:not(.has-no-max-width),
  button:not(.has-no-max-width),
  .button:not(.has-no-max-width),
  input[type='button']:not(.has-no-max-width),
  input[type='submit']:not(.has-no-max-width) {
    width: 100%;
    min-width: 12rem;
  }

  &.request-and-notify-popup {
    .fragment--notification {
      align-items: unset;
      font-size: 1rem;

      @include desktop {
        font-size: 0.875rem;
      }
    }

    .hint__icon {
      vertical-align: middle;
      margin-left: 5px;
    }

    .form-label {
      font-size: 1rem;

      @include desktop {
        font-size: 0.875rem;
      }
    }
  }
}

.fragment--product-tile .component--request-and-order-popup {
  .form-group {
    width: 50%;
    flex-direction: column;
    height: fit-content;
  }
}

.fragment--product-summary .component--request-and-order-popup {
  input[type='number'].is-small {
    width: 4.5rem;
  }
}
