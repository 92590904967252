html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: $color-primary;
  color: $color-white;
  text-shadow: none;
}

.wrapper {
  margin: auto;
  max-width: $wrapper-max-width;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative; /* needed to postion the edit document elements in cms */

  &.no-padding {
    padding: 0;
  }
}

.is-page-relative-background {
  .container--container {
    position: relative;

    .wrapper {
      position: static;
    }

    .no-padding {
      padding: 0;
    }
  }
}

.is-hidden-anker {
  display: none !important;
}
