.component--rich-text {
  overflow: hidden;

  .rich-text__content {
    @include touch {
      width: 100%;
    }

    @include desktop {
      &.is-full-width {
        width: 100%;
      }
    }
  }

  h1 {
    @include font-hero();
  }

  h2 {
    @include font-h1();
  }

  h3 {
    @include font-h3();
  }

  a {
    color: inherit;
  }

  table {
    margin: $space-1x 0;

    @include touch {
      max-width: calc(100vw - 28px);
    }
  }

  ol,
  ul {
    @extend .has-no-indent;

    margin-bottom: $space-1x;
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }
}
