.fragment--slidedown-panel {
  position: relative;

  .header {
    @include fancy-border($positions: bottom);

    cursor: pointer;
    padding-bottom: 1rem;

    &.opened {
      box-shadow: none;
      border: 0;
    }

    &::after {
      @include tick(
        $direction: bottom,
        $color: $color-text-dimmed,
        $size: 0.35em,
        $tickness: 2px
      );

      transition: transform $speed-fast;
      position: absolute;
      top: 0.25em;
      right: 0;
    }

    &.opened::after {
      @include tick(
        $direction: top,
        $color: $color-text-dimmed,
        $size: 0.35em,
        $tickness: 2px
      );
    }
  }
}
