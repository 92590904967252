.page--registration {
  margin-bottom: 0;

  .component--service-block {
    margin-bottom: 0;
  }

  .container--contact {
    display: block;
  }

  .container--container > * {
    margin-top: 0;
  }
}
