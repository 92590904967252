.component--main-navigation {
  ul {
    @extend .link-ui-dark;
  }

  ul > li {
    @include font-h3();
    @include text-uppercase;
  }

  @include touch {
    ul {
      > li {
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      > li:not(:last-child) {
        border-bottom: 1px solid $color-border-light;
      }
    }
  }

  @include desktop {
    ul {
      font-size: 0;
      line-height: 0.5rem;
      white-space: nowrap;
    }

    ul > li {
      @include font-h4();

      border-left: 1px solid $color-border-default;
      display: inline-block;
      padding: 0 1rem;
      vertical-align: text-top;

      &:nth-child(2) {
        padding: 0 0.3334rem;
      }

      &.category-list-button,
      &.category-list-button + li {
        border-left: 1px solid $color-primary;
      }
    }

    ul:hover > li > a {
      opacity: 0.5;
    }

    ul:hover > li:hover > a {
      opacity: 1;
    }
  }

  .toggle--search-navigation {
    cursor: pointer;

    span {
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjFweCIgaGVpZ2h0PSIyMXB4IiB2aWV3Qm94PSIwIDAgMjEgMjEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29uL1NlYXJjaDIxeDIxPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9Ikljb24vU2VhcmNoMjF4MjEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9Inpvb20iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIuMDAwMDAwLCAyLjAwMDAwMCkiIHN0cm9rZT0iIzk3OTc5NyIgc3Ryb2tlLXdpZHRoPSIxLjUiPgogICAgICAgICAgICA8cGF0aCBkPSJNMTUuOTI2Mzk5MiwxNi4xMjA3ODE5IEwxMiwxMiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgY3g9IjciIGN5PSI3IiByPSI3Ij48L2NpcmNsZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==) center center no-repeat;
      display: block;
      height: 1.5rem;
      margin: auto 0 auto auto;
      width: 1.5rem;
    }
  }
}
