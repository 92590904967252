/* @TODO: Outsource background styling to helpers? */
.order-summary__background {
  margin: $space-2x 0;
  padding: 2rem 0;
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2NkYGD4z8DAwMiAH/yHKcCnGCyHbBI2xXAxdCuRFaNoxOY2kAIQQJEjWyFRVhPlGaKCh6gABwBcgBIJm5yNfAAAAABJRU5ErkJggg==);
    opacity: 0.08;
  }

  &::after {
    background: $color-complementary;
    opacity: 0.1;
  }

  @include desktop {
    .column {
      padding: 1rem 0.625rem;
    }
  }
}

.fragment--order-summary {
  margin: $space-2x 0;

  .field-list {
    margin-bottom: 1rem;
  }

  .order-summary__box-border {
    @include negative-border-radius($color: $color-grey, $position: all, $size: 1.25rem);

    padding: 2px;
    width: 100%;
  }

  .order-summary__box {
    @include negative-border-radius($color: $color-white, $position: all, $size: 1.25rem);

    height: 100%;
    padding: 2rem 3rem;
    text-align: center;

    @include desktop {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 2rem 1.5rem 1rem;
    }
  }

  .order-summary__box-content {
    span {
      display: block;
    }

    p {
      margin: 0 0 0.75rem;
    }
  }

  .order-summary__edit-address {
    margin-top: auto;
  }
}
