.component--teaser {
  .teaser__main-heading {
    margin: 1.3rem 0 2.2rem 0;
  }

  .teaser__text-container > * {
    margin: 0.5rem 0;
  }

  .teaser__text-container-container {
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .teaser__image {
    @include background-image-props-for-banners;

    width: 100%;
  }

  .teaser__text-link {
    align-self: center;
  }

  .glide--teaser {
    position: relative;

    .teaser__text-container {
      @include teaser-glide-text-container;

      height: 31%;
    }

    .teaser-title {
      @include font-h3;
    }

    .teaser__text-container > * {
      margin: 0.2rem;
    }

    .teaser__image {
      position: relative;

      &::before {
        content: '';
        display: block;
        margin-top: calc(100% + 50px);
      }
    }

    .glide__track {
      border: 0;
    }

    .glide__arrow {
      background-color: $color-tetrad;
    }

    .glide__arrow--right {
      left: calc(100% - 2.8rem);
    }

    .glide__slide {
      margin: 0 5px 0 0;
    }

    @include touch {
      .glide__slide--active + .glide__slide {
        opacity: 0.1;
      }

      .glide__slide--active + .glide__slide + .glide__slide {
        opacity: 0.1;
      }
    }
  }

  .single-banner-layout {
    @include background-image-props-for-banners;

    height: 32rem;
    margin: 0 auto;
    position: relative;
    width: 100%;

    .teaser__text-container {
      @include teaser-big-text-container();

      @include touch {
        @include teaser-glide-text-container();
      }
    }

    .teaser-title {
      @include font-h2;

      @include touch {
        @include font-h3;

        margin: 0.2rem;
      }
    }

    @include touch {
      position: relative;

      &::before {
        content: '';
        display: block;
        margin-top: calc(100% + 50px);
      }

      .teaser__text-link {
        margin: 0.2rem;
      }
    }
  }

  .two-banners-layout {
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      height: 32rem;
      justify-content: center;
    }

    .teaser-title {
      @include font-h2;
    }

    .two-banners-layout__banner-1 {
      margin-right: 1.25rem;
      width: calc(50% - 1.25rem); //minus the margin
    }

    .two-banners-layout__banner-2 {
      width: 50%;
    }

    .teaser__text-link {
      margin-bottom: 1rem;
    }

    .teaser__text-container {
      @include teaser-large-text-container;
    }
  }

  .three-banners-layout {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 16rem);
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .three-banners-layout__banner-1 {
      grid-row: span 2;
      height: 100%;

      .teaser-title {
        @include font-h2;
      }

      .teaser__text-container {
        @include teaser-large-text-container;
      }
    }

    .three-banners-layout__banner-2,
    .three-banners-layout__banner-3 {
      height: 100%;

      .teaser-subtitle {
        display: none;
      }

      .teaser-title {
        @include font-h3;
      }

      .teaser__text-container {
        @include teaser-text-container($height: 7rem, $width: 30%, $padding: 1.5rem);
      }
    }
  }

  .four-banners-layout {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: repeat(2, 16rem);
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .four-banners-layout__banner-1 {
      grid-row: span 2;
      height: 100%;

      .teaser-title {
        @include font-h2;
      }

      .teaser__text-container {
        @include teaser-large-text-container;
      }
    }

    .four-banners-layout__banner-2 {
      grid-column: span 2;
      height: 100%;

      .teaser-subtitle {
        display: none;
      }

      .teaser-title {
        @include font-h3;
      }

      .teaser__text-container {
        @include teaser-text-container($height: 7rem, $width: 30%, $padding: 1.5rem);
      }
    }

    .four-banners-layout__banner-3,
    .four-banners-layout__banner-4 {
      height: 100%;

      .teaser-subtitle {
        display: none;
      }

      .teaser-title {
        @include font-h4;
      }

      .teaser__text-container {
        @include teaser-small-text-container;
      }

      .teaser__text-container > * {
        margin: 0.2rem;
      }
    }
  }

  .five-banners-layout {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: repeat(2, 16rem);
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .five-banners-layout__banner-2,
    .five-banners-layout__banner-3,
    .five-banners-layout__banner-4,
    .five-banners-layout__banner-5 {
      height: 100%;

      .teaser-title {
        @include font-h4;
      }

      .teaser-subtitle {
        display: none;
      }

      .teaser__text-container {
        @include teaser-small-text-container;
      }

      .teaser__text-container > * {
        margin: 0.2rem;
      }
    }

    .five-banners-layout__banner-2 {
      grid-column-start: 1;
      grid-row-start: 1;
    }

    .five-banners-layout__banner-3 {
      grid-column-start: 1;
      grid-row-start: 2;
    }

    .five-banners-layout__banner-4 {
      grid-column-start: 3;
      grid-row-start: 1;
    }

    .five-banners-layout__banner-5 {
      grid-column-start: 3;
      grid-row-start: 2;
    }

    .five-banners-layout__banner-1 {
      grid-row: span 2;
      height: 100%;

      .teaser-title {
        @include font-h2;
      }

      .teaser__text-container {
        @include teaser-large-text-container;
      }
    }
  }

  .six-banners-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      justify-content: center;

      .teaser-subtitle {
        display: none;
      }
    }

    .six-banners-layout__banner-1,
    .six-banners-layout__banner-2 {
      height: 15.4rem;
      margin-bottom: 1.25rem;
      width: calc(50% - 0.625rem);

      .teaser-title {
        @include font-h3;
      }

      .teaser__text-container {
        @include teaser-text-container($height: 7rem, $width: 30%, $padding: 1.5rem);
      }
    }

    .six-banners-layout__banner-3,
    .six-banners-layout__banner-4,
    .six-banners-layout__banner-5,
    .six-banners-layout__banner-6 {
      height: 15.4rem;
      width: calc(25% - 1.125rem);

      .teaser-title {
        @include font-h4;
      }

      .teaser__text-container {
        @include teaser-small-text-container;
      }
    }
  }

  .eight-banners-layout {
    align-content: space-between;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      height: 15.4rem;
      justify-content: center;
      width: calc(25% - 0.94rem);

      .teaser__text-container {
        @include teaser-small-text-container;
      }

      .teaser__text-container > * {
        margin: 0.2rem;
      }

      .teaser__text-container .teaser-title {
        @include font-h4;
      }

      .teaser__text-container .teaser-subtitle {
        display: none;
      }
    }

    .eight-banners-layout__banner-1,
    .eight-banners-layout__banner-2,
    .eight-banners-layout__banner-3,
    .eight-banners-layout__banner-4 {
      margin-bottom: 1.25rem;
    }
  }

  .seven-banners-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .teaser {
      @include background-image-props-for-banners;

      align-items: center;
      display: flex;
      justify-content: center;

      .teaser__text-container .teaser-subtitle {
        display: none;
      }
    }

    .seven-banners-layout__banner-1 {
      height: 15.4rem;
      order: 1;
      width: calc(50% - 0.63rem);

      .teaser__text-container .teaser-title {
        @include font-h3;
      }

      .teaser__text-container {
        @include teaser-text-container($height: 7rem, $width: 30%, $padding: 1.5rem);
      }
    }

    .seven-banners-layout__banner-1,
    .seven-banners-layout__banner-2,
    .seven-banners-layout__banner-3 {
      margin-bottom: 1.25rem;
    }

    .seven-banners-layout__banner-3,
    .seven-banners-layout__banner-4,
    .seven-banners-layout__banner-5,
    .seven-banners-layout__banner-6,
    .seven-banners-layout__banner-7 {
      order: 2;
    }

    .seven-banners-layout__banner-2,
    .seven-banners-layout__banner-3,
    .seven-banners-layout__banner-4,
    .seven-banners-layout__banner-5,
    .seven-banners-layout__banner-6,
    .seven-banners-layout__banner-7 {
      height: 15.4rem;
      width: calc(25% - 0.94rem);

      .teaser__text-container {
        @include teaser-small-text-container;
      }

      .teaser__text-container .teaser-title {
        @include font-h4;
      }

      .teaser__text-container > * {
        margin: 0.2rem;
      }
    }
  }

  .teaser__image-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    img {
      flex: 1 1 auto;
      object-fit: cover;
    }

    @include touch {
      position: absolute;
    }
  }
}
