.component--hero-banner__carousel {
  margin-top: 0%;
}

.wrapper__hero-banner__carousel {
  margin: auto;
}

.glide__hero-banner__carousel {
  margin: auto;
  cursor: pointer;

  .glide__empty {
    height: 10%;
    width: 10%;
  }

  @media screen and (min-width: 1100px) {
    margin: auto;
    width: 100%;
  }
}

.glide__slides {
  align-items: center;

  &.glide__slides__hero-banner {
    padding: 0;
  }

  &__hero-banner {
    .glide__slide {
      line-height: 0px;
    }
  }
}

.glide__hero-banner__content__item {
  display: table;
  line-height: initial;
  width: 100%;

  @include touch {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.glide__slide__hero-banner {
  position: relative;

  &__content {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    height: inherit;
    padding: 1%;
    position: absolute;
    width: inherit;
    z-index: 1;

    .glide__hero-banner__content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    .glide__hero-banner__content__item {
      width: 100%;

      &.is-text-center {
        text-align: center;
      }

      &.is-text-right {
        text-align: right;
      }

      &.button,
      &.is-link {
        width: auto;
        margin-right: auto;
      }

      &.button.is-item-center,
      &.button.is-text-center,
      &.is-link.is-item-center,
      &.is-link.is-text-center {
        margin: 0.6rem auto;
      }

      &.button.is-item-right,
      &.button.is-text-right,
      &.is-link.is-item-right,
      &.is-link.is-text-right {
        margin-left: auto;
        margin-right: 0;
      }

      &.text-white {
        color: $color-white;
      }

      &.text-grey {
        color: $color-grey;
      }

      &.text-primary {
        color: $color-primary;
      }

      &.text-complementary {
        color: $color-complementary;
      }

      &.text-tertiary {
        color: $color-tetrad;
      }
    }

    @media screen and (min-width: 1000px) and (max-width: 1101px) {
      padding-top: 3.5%;
    }

    @media screen and (min-width: 1100px) and (max-width: 1301px) {
      padding-bottom: 5%;
    }
  }

  &__overlay {
    height: inherit;
    position: absolute;
    width: inherit;

    @media screen and (min-width: 1000px) and (max-width: 1101px) {
      height: 100%;
      position: absolute;
      width: inherit;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1101px) {
    height: 76%;
    position: relative;
  }
}

.glide__hero-banner__picture {
  width: 100%;
  overflow: hidden;

  img {
    width: inherit;
  }

  video {
    width: 100%;

    @include touch {
      width: 208%;
    }
  }

  &.hide__for__mobile {
    @media screen and (max-width: 1279px) {
      display: none;
    }
  }

  &.hide__for__video-banner {
    @media screen and (min-width: 1280px) {
      display: none;
    }
  }
}

.glide__arrows__hero-banner {
  .glide__arrow {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.glide__bullets__hero-banner {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);

  .glide__bullet {
    margin: 0 7px;
    padding: 0;
    height: 14px;
    width: 14px;
    min-width: auto;
    line-height: 0;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: $color-white;
    opacity: 0.5;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &--active {
      opacity: 0.9;
    }
  }
}
