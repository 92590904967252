.fragment--notification {
  align-items: center;
  display: flex;
  font-size: $notifications--font-size;
  line-height: $notifications--line-height;
  margin: 1.5rem 0;
  position: relative;

  &:not(.is-active) {
    display: none;
  }

  &.is-level-error {
    background: $color-notification-error-background;
    color: $color-notification-error-text;

    .notification__message::selection {
      background: $color-notification-error-text;
    }
  }

  &.is-level-warning {
    background: $color-notification-warning-background;
    color: $color-notification-warning-text;

    .notification__message::selection {
      background: $color-notification-warning-text;
    }
  }

  &.is-level-info {
    background: $color-notification-info-background;
    color: $color-notification-info-text;

    .notification__message::selection {
      background: $color-notification-info-text;
    }
  }

  &.is-level-success {
    background: $color-notification-success-background;
    color: $color-notification-success-text;

    .notification__message::selection {
      background: $color-notification-success-text;
    }
  }

  .notification__icon {
    display: block;

    > svg {
      margin: 0.3334rem 0.5rem 0.3334rem 0;
    }
  }

  .notification__close {
    cursor: pointer;
    height: 2rem;
    line-height: 1rem;
    padding: 0.5rem;
    width: 2rem;

    > svg {
      stroke: $color-dark;
    }
  }

  &:not(.is-compact) {
    padding: 1.5rem 1rem 0.5rem;
    width: 100%;

    .notification__close {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.is-compact {
    display: inline-flex;
    padding: 0.5rem;

    .notification__close {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.is-active.is-global {
    justify-content: center;
    left: 50%;
    margin: auto;
    max-width: $wrapper-max-width;
    position: fixed;
    right: 0;
    top: 1rem;
    transform: translateX(-50%);
    width: 100%;
    z-index: $zindex__global-notification;

    .notification__message {
      text-align: center;
    }
  }

  &:nth-child(n+1) {
    margin-top: 0;
  }
}
