.fragment--add-to-list-popup {
  .dialog__inner-wrapper {
    padding: 5rem 1rem;

    @include desktop {
      bottom: 0;
      left: 0;
      overflow-y: auto;
      padding: 4rem 2.5rem 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .fragment--message-display.message-display {
    margin-bottom: 0;
  }

  .dialog__add-to-list-items-row {
    align-items: center;
    color: $color-tetrad;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    text-decoration: none;
  }

  .back-link {
    display: inline-block;
    margin-bottom: 2.5em;

    @include desktop {
      display: none;
    }
  }

  .has-float-label {
    @include desktop {
      width: 100%;
    }
  }

  @include desktop {
    .dialog__add-to-list-popup-container {
      padding-bottom: 2.5rem;
    }

    dialog::after {
      background: $color-white;
      bottom: 0;
      content: '';
      height: 50px;
      opacity: $opacity;
      position: absolute;
      right: 2.5rem;
      width: 100%;
      z-index: 1;
    }
  }
}

.dialog__add-to-list-items__head {
  @include font-h3();
  @include text-uppercase();
}

.dialog__add-to-list-popup_new-list-container {
  .list-exist-error_container {
    .fragment--notification {
      margin: 1rem 0 0;

      @include mobile {
        margin: 0.5rem 0 0;
        width: 100%;
      }
    }
  }
}
