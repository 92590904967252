.fragment--badge {
  > span {
    @include font-h6();

    border: 1px solid $color-border-default;
    border-radius: 1em;
    color: $color-text-dimmed;
    padding: 0.025em 0.75em;
    text-transform: uppercase;
  }

  &.is-bigger span {
    @include font-h5();

    border-radius: 1rem;
    padding: 0.2rem 1rem;
  }

  &.is-error span {
    border-color: $color-notification-error;
    color: $color-notification-error;
  }

  &.is-success span {
    border-color: $color-notification-success;
    color: $color-notification-success;
  }
}
