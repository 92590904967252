// @TODO: name change to zindex__container--header
$zindex--container-header: 420;

$zindex__component--hero-banner__content-container: 5;

$zindex__component--search-bar__autosuggest__results: 440;

$zindex__fragment--cookie-banner__body: 490;
$zindex__fragment--cookie-banner: 500;

$zindex__util__loader: 405;
$zindex__util__loader--full-page: 999;

$zindex__global-notification: 850;
$z-index__popup: 1000;

$zindex__barcode-icon: 510;
