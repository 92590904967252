.fragment--404-navigational-links {
  .navigational-links__vertical-bar {
    color: $color-border-light;
  }
}

.fragment--404-message {
  .message-title {
    @include font-personal-titles();
  }
}
