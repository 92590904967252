.page--product-detail {
  .product-carousel-suplementary {
    background: $color-carousel-bg;
    margin: 0 auto;
  }

  .product-carousel-suplementary.product-carousel .product-carousel__supplementary-carousel-specifier .product-carousel__title {
    color: $color-white;
  }

  .product-carousel-suplementary .product-carousel__more a {
    color: $color-white;
  }

  .product-carousel-suplementary .glide {
    &::before,
    &::after {
      background: $color-carousel-bg;
      opacity: 0.9;
    }
  }
}
