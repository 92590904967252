.fragment--list-detail-page {
  .list-detail-page__bg--white {
    position: relative;

    @include desktop {
      padding: 1rem 2rem 0 2.5rem;
    }
  }

  .product-list__switch {
    @include touch {
      display: none;
    }
  }

  .product-list__view-options > div {
    @include touch {
      padding: 0 1rem;

      &.component--search-bar {
        margin-bottom: 1rem;
      }
    }
  }

  @include desktop {
    .show-as-product-lines .fragment--product-tile {
      .product-tile__tags {
        position: absolute;
        top: 2em;
        left: 0;
      }
    }
  }

  .show-as-product-lines .fragment--product-tile .product-tile__favorite.add-to-list_popup__button.trash-icon {
    right: 0;
    left: unset;
  }
}
