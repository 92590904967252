.component--language-switcher-list {
  .language-switcher-list {
    position: relative;
  }

  li:not(.language-switcher__label),
  .is-toggle-label {
    cursor: pointer;
  }

  ol {
    padding: 4rem 0 2.5rem;
  }

  li > a {
    color: #202a4f;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    width: 100%;

    @include touch {
      padding: 2rem;
      text-align: left;
    }
  }

  .language-switcher__heading {
    @include font-regular-body-copy();

    color: $color-grey;

    @include desktop {
      width: 100%;
    }

    @include touch {
      margin-top: 2.75rem;
    }
  }

  .language-switcher-list--list {
    @include touch {
      flex-direction: column;
      padding: 6rem 0;
    }
  }

  .language-switcher__language {
    @include font-h3();

    border: 1px solid $color-light;
    margin: 1rem 0;
    width: 100%;

    &:hover,
    &.is-active {
      border-bottom: 1px solid $color-tetrad;
      border-left: 4px solid $color-tetrad;
      border-right: 1px solid $color-tetrad;
      border-top: 1px solid $color-tetrad;
    }

    @include desktop {
      align-items: center;
      display: flex;
      height: 7rem;
      margin-right: 1rem;
      width: 10rem;

      &:hover,
      &.is-active {
        border-bottom: 4px solid $color-tetrad;
        border-left: 1px solid $color-tetrad;
        border-right: 1px solid $color-tetrad;
        border-top: 1px solid $color-tetrad;
      }
    }
  }

  .is-closing {
    background:
      linear-gradient(-45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat,
      linear-gradient(45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat;
    color: $color-grey;
    height: 1.5rem;
    right: 1rem;
    text-indent: -420rem;
    width: 1.6rem;

    @include touch {
      left: 1rem;
      position: fixed;
      top: 2rem;
    }

    @include desktop {
      left: 0;
      position: absolute;
      top: 1rem;
    }
  }
}

.toggle--language-switcher-list.is-toggle-handler {
  @include touch {
    background: $color-background-white;
    height: 100vh;
    position: fixed;
    top: 0;
    transition: transform $speed ease-in-out;
    width: 100%;
    z-index: $zindex--container-header + 20;
  }

  @include desktop {
    max-height: 0;
    overflow: hidden;
  }
}
