.fragment--page-transition {
  @include font-hero;

  align-items: center;
  background: $color-white;
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 999999999999;
}

[v-cloak='v-cloak'] {
  display: none;

  ~ .fragment--page-transition {
    display: flex;
    opacity: 1;
  }
}
