.container--product-tiles {
  margin: $distance-between-components / 2 0;

  // Inside this media-query are the contents
  // of .is-gapless from _flex-container.scss
  // but should only apply for mobile in this
  // specific container with product-tiles.
  // Only "margin-top" is disabled.
  @include touch {
    margin-left: 0;
    margin-right: 0;
    // margin-top: 0;

    > .column {
      margin: 0;
      padding: 0 !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // This below contains rules to overwrite what
  // is being done here above...
  @include touch {
    border-bottom: 1px solid $color-border-default;
    border-top: 1px solid $color-border-default;

    > .fragment--product-tile {
      &:nth-of-type(odd) {
        padding-right: 1rem;
      }

      &:nth-of-type(odd) .product-tile__container {
        padding-left: 0;
      }

      &:nth-of-type(even) {
        border-left: 1px solid $color-border-default;
        padding-left: 1rem;
      }

      &:nth-of-type(even) .product-tile__container {
        padding-right: 0;
      }

      &:nth-of-type(1n+3) {
        border-top: 1px solid $color-border-default;
      }
    }

    > :not(.fragment--product-tile) > .fragment--hr-with-markings {
      border-top: 1px solid $color-border-default;
      padding: 2rem 0;
    }
  }

  @include desktop {
    > .fragment--product-tile {
      margin-bottom: 1.5rem;
    }

    &.show-as-product-lines .fragment--product-tile {
      &:first-of-type {
        @include fancy-border($positions: (top), $width: 0.25rem);
      }

      &:last-of-type {
        @include fancy-border($positions: (bottom), $width: 0.25rem);
      }

      + .fragment--product-tile {
        border-top: 1px solid $color-border-default;
      }
    }

    > :not(.fragment--product-tile) > .fragment--hr-with-markings {
      padding: 2rem 0 3rem;
    }
  }

  .fragment--hr-with-markings p {
    color: $color-text-dimmed;
  }
}
