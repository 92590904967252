.fragment--order-line {
  padding: 2rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border-default;
  }

  .order-line {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .order-line__wrapper {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;

      @include touch {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-width: auto;
      }
    }

    @include desktop {
      align-items: center;
      justify-content: space-between;

      > * {
        align-self: flex-start;
        margin-top: 0.5rem;
        padding: 0 1rem;
      }
    }

    @include touch {
      padding-bottom: 2rem;

      > *:not(.order-line__image-container):not(.order-line__info):not(.order-line__wrapper) {
        width: 50%;
      }

      .order-line__price,
      .order-line__total-price {
        padding-left: 5.5rem;
      }
    }
  }

  .order-line__image-container {
    padding: 0 1rem;
    text-align: center;

    @include desktop {
      margin-top: 0;
      width: 7rem;
    }

    @include touch {
      width: 5.5rem;
    }

    img {
      max-height: 6rem;
      max-width: 100%;
    }
  }

  .order-line__info {
    @include desktop {
      width: 32.3334%;
    }

    @include touch {
      width: calc(100% - 5.5rem);
    }
  }

  .order-line__detail__name a {
    color: inherit;
    text-decoration: inherit;
  }

  .order-line__details__attributes {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem 0 0;
  }

  .order-line__price {
    padding-right: 1rem;
  }

  @include desktop {
    .order-line__summary {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .has-float-label {
        width: 5.5rem;
      }

      > .product-tile__notifications__out-of-stock {
        width: 100%;
      }
    }
  }

  @include touch {
    .order-line__price,
    .order-line__total-price,
    .order-line__summary {
      margin: 1rem 0;
    }

    .order-line__price {
      width: 100%;
    }

    .order-line__wrapper.trading-wrapper .order-line__price {
      width: calc(50% + 2.75rem);
    }

    .order-line__summary {
      width: calc(50% - 2.75rem);
      padding-left: 5.5rem;
    }

    .order-line__wrapper.trading-wrapper .order-line__summary {
      padding-left: 0;
    }

    .order-line__price,
    .order-line__summary {
      input {
        width: 100%;
        text-align: left;
      }
    }

    .order-line__total-price {
      width: 100%;
    }

    .order-line__price > div {
      position: relative;

      .order-line__price__excise {
        background-color: $color-white;
        border: 1px solid $color-border-light;
        padding: 1rem;
        position: absolute;
        top: calc(100% + 5px);
        width: 100%;
        z-index: 1;
      }
    }
  }

  .order-line__remove-wrapper {
    white-space: nowrap;

    > * {
      display: inline-block;
    }

    @include touch {
      bottom: 0;
      height: 2rem;
      left: 0;
      position: absolute;
      width: 100%;
      padding-left: 5.5rem;
    }
  }

  .order-line__sku {
    padding-right: 1em;
  }

  .order-line__attributes span:nth-child(2)::before {
    content: '\007C';
    padding-right: 5px;
  }

  .order-line__summary {
    @include desktop {
      .form-notifications {
        order: 3;
      }
    }

    @include mobile {
      .form-notifications {
        text-align: left;
      }
    }

    &.is-readonly,
    &:disabled {
      background: $color-background-default;
      border: 0;
      color: $color-grey;
      opacity: 1;
    }

    input[type='number'] {
      margin-right: 1rem;

      @include desktop {
        width: 4.5rem;
      }

      &.is-readonly,
      &:disabled {
        border: 0;
        opacity: 1;
      }

      &.is-readonly ~ .form-notifications,
      &:disabled ~ .form-notifications {
        opacity: 1;
      }

      ~ .form-notifications .is-help {
        color: $color-grey;
        padding: 0.4rem 0;
      }
    }

    div[name='quantity'] {
      display: flex;
      flex-direction: column;
    }

    .request-stock {
      @include font-family-default();

      padding: 0;
      margin: 0;
      color: $color-notification-error;
      font-size: 13px;
      background: none;
      border: 0;
      font-weight: 300;
      text-align: left;
      text-transform: none;
      text-decoration: underline;

      &.request-stock--disabled {
        cursor: not-allowed;
        color: $color-grey;
      }
    }
  }

  @include desktop {
    input[name='tradingPrice'] {
      width: 10rem;
    }
  }

  .order-line__info-wrapper {
    flex-grow: 1;
  }

  .toggle--price-excise.is-toggle-trigger {
    display: none;
  }

  .toggle--price-excise.is-toggle-label {
    cursor: pointer;
    user-select: none;
  }

  .toggle--price-excise.is-toggle-trigger:not(:checked) {
    ~ .toggle--price-excise.is-toggle-label div::after {
      @include tick($direction: bottom);

      margin-left: 0.5rem;
      position: relative;
      top: -0.25rem;
    }

    ~ .order-line__price__excise.is-toggle-handler {
      display: none;
    }
  }

  .toggle--price-excise.is-toggle-trigger:checked {
    ~ .toggle--price-excise.is-toggle-label div::after {
      @include tick($direction: top);

      margin-left: 0.5rem;
      position: relative;
      top: -0.25rem;
    }

    ~ .order-line__price__excise.is-toggle-handler {
      display: block;
    }
  }

  .order-line__remove-wrapper svg {
    margin-bottom: -1px;
  }

  .order-line__remove__basket {
    padding-left: 1rem;
  }

  .trading-price .has-float-label input {
    padding-left: 2rem;
    padding-right: 4rem;
  }

  .trading-price .has-float-label span.price-currency {
    font-size: 1rem;
    padding: 0;
    top: 0.9rem;
  }

  .trading-price-input span.price-currency {
    background-color: transparent;
  }

  .trading-price-input .form-notifications .is-invalid {
    max-width: fit-content;
  }

  .trading-price .has-float-label span.price-package-type {
    font-size: 1rem;
    left: 7rem;
    padding: 0;
    top: 0.9rem;
  }

  @include touch {
    .order-line__messages {
      margin-left: 20%;
    }
  }
}
