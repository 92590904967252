//
// Configuration
//

@import 'configuration/colors';
@import 'configuration/variables';
@import 'configuration/breakpoints';
@import 'configuration/spaces';
@import 'configuration/zindexes';

//
// Helpers
//

@import 'helpers/mixins';
@import 'helpers/visibility';
@import 'helpers/base';
@import 'helpers/typography';
@import 'helpers/flex-container';
@import 'helpers/ui-contrast';
@import 'helpers/icons';
// @import 'helpers/animations';
@import 'helpers/print';
%link-default {
  &,
  &:link {
    border-bottom: 1px solid currentColor;
    color: $color-complementary;
    text-decoration: none;
  }

  &:visited {
    color: $color-complementary;
  }

  &:hover {
    color: $color-complementary;
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted $color-complementary;
  }

  &:active {
    color: $color-complementary;
  }
}

%link-ui-light {
  &,
  &:link {
    color: $color-white;
    text-decoration: none;
  }

  &:visited {
    color: $color-white;
  }

  &:hover {
    // text-decoration: underline;
  }

  &:focus {
    outline: 1px dotted $color-tetrad;
  }

  &:active {
    color: $color-tetrad;
  }
}

%link-ui-dark {
  &,
  &:link {
    color: $color-tetrad;
    text-decoration: none;
  }

  &:visited {
    color: $color-tetrad;
  }

  &:hover {
    // text-decoration: underline;
  }

  &:focus {
    outline: 1px dotted $color-complementary;
  }

  &:active {
    color: $color-primary;
  }
}

%link-ui-grey {
  &,
  &:link {
    color: $color-grey;
    text-decoration: none;
  }

  &:visited {
    color: $color-grey;
  }

  &:hover {
    // text-decoration: underline;
  }

  &:focus {
    outline: 1px dotted $color-complementary;
  }

  &:active {
    color: $color-primary;
  }
}

%link-content {
  &,
  &:link {
    color: $color-complementary;
    text-decoration: none;
  }

  &:visited {
    color: $color-complementary;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px dotted $color-primary;
  }

  &:active {
    color: $color-primary;
  }
}

%link-ui-primary {
  &,
  &:link {
    color: $color-primary;
    text-decoration: none;
  }

  &:visited {
    color: $color-primary;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px dotted $color-complementary;
  }

  &:active {
    color: $color-complementary;
  }
}

.is-link-ui-light a,
a.is-link-ui-light {
  @extend %link-ui-light;
}

.link-ui-dark,
.link-ui-dark a,
a.is-link-ui-dark {
  @extend %link-ui-dark;
}

.link-ui-grey a,
a.is-link-ui-grey {
  @extend %link-ui-grey;
}

.link-ui-primary a,
a.is-link-ui-primary {
  @extend %link-ui-primary;
}

.is-content a:not(.is-ui) {
  @extend %link-content;
}

a[href][target='_blank']:not(.hide-external-link-icon)::after {
  // content: ' \29C9';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMjAyQTRGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ij4KICAgICAgICA8cGF0aCBkPSJNOSAxTDQuNzMzIDUuMjY3TTUuMjY3IDFIOXYzLjczM005IDlIMS41MzNBLjUzNC41MzQgMCAwIDEgMSA4LjQ2N1YxIi8+CiAgICA8L2c+Cjwvc3ZnPgo=');
  content: '';
  display: inline-block;
  height: 10px;
  margin-left: 0.5em;
  width: 10px;
}

.is-link {
  @include font-family-title();

  color: $color-primary;
  font-size: 1rem;
  text-decoration: none;

  &:not(.is-inactive) {
    cursor: pointer;
  }

  @include desktop {
    font-size: 0.875rem;
  }

  &:hover {
    color: $color-text-primary-hover;
  }

  &.arrow-left::before {
    @include tick($direction: left, $color: $color-primary, $size: 0.4rem, $tickness: 1px);

    margin: 0 0.35rem 0.15rem 0;
    vertical-align: middle;
  }

  &.arrow-right::after {
    @include tick($direction: right, $color: $color-primary, $size: 0.4rem, $tickness: 1px);

    margin: 0 0 0.15rem 0.35rem;
    vertical-align: middle;
  }

  &.plus-left::before {
    content: '+';
    display: inline-block;
    margin: 0 0.4rem 0.15rem 0;
  }

  &.plus-right::after {
    content: '+';
    display: inline-block;
    margin: 0 0 0.15rem 0.4rem;
  }

  &.min-left::before {
    content: '-';
    display: inline-block;
    margin: 0 0.4rem 0.15rem 0;
  }

  &.min-right::after {
    content: '-';
    display: inline-block;
    margin: 0 0 0.15rem 0.4rem;
  }

  &.recycle-left::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA0Ljg3NWwyLjI1LTIuMjUtMi4yNS0yLjI1Ii8+CiAgICAgICAgPHBhdGggZD0iTTguMTI1IDIuNjI1aC02Ljc1djYuNzVoMS4zNU04LjEyNSA3LjEyNWwtMi4yNSAyLjI1IDIuMjUgMi4yNSIvPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA5LjM3NWg2Ljc1di02Ljc1aC0xLjM1Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 0.5rem 0.15rem 0;
    vertical-align: middle;
    width: 14px;
  }

  &.recycle-right::after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA0Ljg3NWwyLjI1LTIuMjUtMi4yNS0yLjI1Ii8+CiAgICAgICAgPHBhdGggZD0iTTguMTI1IDIuNjI1aC02Ljc1djYuNzVoMS4zNU04LjEyNSA3LjEyNWwtMi4yNSAyLjI1IDIuMjUgMi4yNSIvPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA5LjM3NWg2Ljc1di02Ljc1aC0xLjM1Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 0 0.15rem 0.5rem;
    vertical-align: middle;
    width: 14px;
  }

  &.edit-left::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0xLjUgMTEuODU4aDkuNDI5TTcuODk4IDEuNDM4TDkuOTE4IDMuNSA0LjE5NCA5LjM0NCAxLjUgMTAuMDNsLjY3My0yLjc1eiIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center center;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 0.5rem 0.15rem 0;
    vertical-align: middle;
    width: 12px;
  }

  &.edit-right::after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0xLjUgMTEuODU4aDkuNDI5TTcuODk4IDEuNDM4TDkuOTE4IDMuNSA0LjE5NCA5LjM0NCAxLjUgMTAuMDNsLjY3My0yLjc1eiIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center center;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 0 0.15rem 0.5rem;
    vertical-align: middle;
    width: 12px;
  }

  &.connected-left::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik03LjcxNiA2LjIzYTIuNjI2IDIuNjI2IDAgMCAxIDAgMy43MTVMNi4yMyAxMS40MzFhMi42MjYgMi42MjYgMCAxIDEtMy43MTUtMy43MTVsMS4zMDYtMS4yNjgiLz4KICAgICAgICA8cGF0aCBkPSJNNi4yMyA3LjcxNmEyLjYyNiAyLjYyNiAwIDAgMSAwLTMuNzE0bDEuNDg2LTEuNDg2YTIuNjI2IDIuNjI2IDAgMSAxIDMuNzE1IDMuNzE1bC0xLjMwNSAxLjI2NyIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center center;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 0.5rem 0.15rem 0;
    vertical-align: middle;
    width: 12px;
  }

  &.connected-right::after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik03LjcxNiA2LjIzYTIuNjI2IDIuNjI2IDAgMCAxIDAgMy43MTVMNi4yMyAxMS40MzFhMi42MjYgMi42MjYgMCAxIDEtMy43MTUtMy43MTVsMS4zMDYtMS4yNjgiLz4KICAgICAgICA8cGF0aCBkPSJNNi4yMyA3LjcxNmEyLjYyNiAyLjYyNiAwIDAgMSAwLTMuNzE0bDEuNDg2LTEuNDg2YTIuNjI2IDIuNjI2IDAgMSAxIDMuNzE1IDMuNzE1bC0xLjMwNSAxLjI2NyIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center center;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 0 0.15rem 0.5rem;
    vertical-align: middle;
    width: 12px;
  }

  &.is-color-secondary {
    color: $color-text-dimmed;

    &:not(.is-inactive):hover {
      color: $color-text-default;
    }

    &.arrow-left::before {
      @include tick($direction: left, $color: $color-text-dimmed, $size: 0.4rem, $tickness: 1px);

      margin: 0 0.35rem 0.15rem 0.2rem;
    }

    &.arrow-right::after {
      @include tick($direction: right, $color: $color-text-dimmed, $size: 0.4rem, $tickness: 1px);

      margin: 0 0 0.15rem 0.35rem;
    }

    &.recycle-left::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA0Ljg3NWwyLjI1LTIuMjUtMi4yNS0yLjI1Ii8+CiAgICAgICAgPHBhdGggZD0iTTguMTI1IDIuNjI1aC02Ljc1djYuNzVoMS4zNU04LjEyNSA3LjEyNWwtMi4yNSAyLjI1IDIuMjUgMi4yNSIvPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA5LjM3NWg2Ljc1di02Ljc1aC0xLjM1Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0.5rem 0.15rem 0;
      vertical-align: middle;
      width: 14px;
    }

    &.recycle-right::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA0Ljg3NWwyLjI1LTIuMjUtMi4yNS0yLjI1Ii8+CiAgICAgICAgPHBhdGggZD0iTTguMTI1IDIuNjI1aC02Ljc1djYuNzVoMS4zNU04LjEyNSA3LjEyNWwtMi4yNSAyLjI1IDIuMjUgMi4yNSIvPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA5LjM3NWg2Ljc1di02Ljc1aC0xLjM1Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 0.15rem 0.5rem;
      vertical-align: middle;
      width: 14px;
    }

    &.edit-left::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0xLjUgMTEuNDJoOS40MjlNNy44OTggMWwyLjAyIDIuMDYzLTUuNzI0IDUuODQzLTIuNjk0LjY4OC42NzMtMi43NXoiLz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0.5rem 0.15rem 0;
      vertical-align: middle;
      width: 12px;
    }

    &.edit-right::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0xLjUgMTEuNDJoOS40MjlNNy44OTggMWwyLjAyIDIuMDYzLTUuNzI0IDUuODQzLTIuNjk0LjY4OC42NzMtMi43NXoiLz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 0.15rem 0.5rem;
      vertical-align: middle;
      width: 12px;
    }

    &.connected-left::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik02LjcxNiA1LjIzYTIuNjI2IDIuNjI2IDAgMCAxIDAgMy43MTVMNS4yMyAxMC40MzFhMi42MjYgMi42MjYgMCAxIDEtMy43MTUtMy43MTVsMS4zMDYtMS4yNjgiLz4KICAgICAgICA8cGF0aCBkPSJNNS4yMyA2LjcxNmEyLjYyNiAyLjYyNiAwIDAgMSAwLTMuNzE0bDEuNDg2LTEuNDg2YTIuNjI2IDIuNjI2IDAgMSAxIDMuNzE1IDMuNzE1TDkuMTI2IDYuNDk3Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0.5rem 0.15rem 0;
      vertical-align: middle;
      width: 12px;
    }

    &.connected-right::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjOTc5Nzk3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik02LjcxNiA1LjIzYTIuNjI2IDIuNjI2IDAgMCAxIDAgMy43MTVMNS4yMyAxMC40MzFhMi42MjYgMi42MjYgMCAxIDEtMy43MTUtMy43MTVsMS4zMDYtMS4yNjgiLz4KICAgICAgICA8cGF0aCBkPSJNNS4yMyA2LjcxNmEyLjYyNiAyLjYyNiAwIDAgMSAwLTMuNzE0bDEuNDg2LTEuNDg2YTIuNjI2IDIuNjI2IDAgMSAxIDMuNzE1IDMuNzE1TDkuMTI2IDYuNDk3Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 0.15rem 0.5rem;
      vertical-align: middle;
      width: 12px;
    }
  }

  &.is-color-complementary {
    color: $color-complementary;

    &:hover {
      color: $color-complementary-hover;
    }

    &.arrow-left::before {
      @include tick($direction: left, $color: $color-complementary, $size: 0.4rem, $tickness: 1px);

      margin: 0 0.35rem 0.15rem 0;
    }

    &.arrow-right::after {
      @include tick($direction: right, $color: $color-complementary, $size: 0.4rem, $tickness: 1px);

      margin: 0 0 0.15rem 0.35rem;
    }

    &.recycle-left::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNEVDMkMyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA0Ljg3NWwyLjI1LTIuMjUtMi4yNS0yLjI1Ii8+CiAgICAgICAgPHBhdGggZD0iTTguMTI1IDIuNjI1aC02Ljc1djYuNzVoMS4zNU04LjEyNSA3LjEyNWwtMi4yNSAyLjI1IDIuMjUgMi4yNSIvPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA5LjM3NWg2Ljc1di02Ljc1aC0xLjM1Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0.5rem 0.15rem 0;
      vertical-align: middle;
      width: 14px;
    }

    &.recycle-right::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNEVDMkMyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA0Ljg3NWwyLjI1LTIuMjUtMi4yNS0yLjI1Ii8+CiAgICAgICAgPHBhdGggZD0iTTguMTI1IDIuNjI1aC02Ljc1djYuNzVoMS4zNU04LjEyNSA3LjEyNWwtMi4yNSAyLjI1IDIuMjUgMi4yNSIvPgogICAgICAgIDxwYXRoIGQ9Ik01Ljg3NSA5LjM3NWg2Ljc1di02Ljc1aC0xLjM1Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 0.15rem 0.5rem;
      vertical-align: middle;
      width: 14px;
    }

    &.edit-left::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNEVDMkMyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0xLjUgMTEuNDJoOS40MjlNNy44OTggMWwyLjAyIDIuMDYzLTUuNzI0IDUuODQzLTIuNjk0LjY4OC42NzMtMi43NXoiLz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0.5rem 0.15rem 0;
      vertical-align: middle;
      width: 12px;
    }

    &.edit-right::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNEVDMkMyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik0xLjUgMTEuNDJoOS40MjlNNy44OTggMWwyLjAyIDIuMDYzLTUuNzI0IDUuODQzLTIuNjk0LjY4OC42NzMtMi43NXoiLz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 0.15rem 0.5rem;
      vertical-align: middle;
      width: 12px;
    }

    &.connected-left::before {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNEVDMkMyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik03LjcxNiA2LjIzYTIuNjI2IDIuNjI2IDAgMCAxIDAgMy43MTVMNi4yMyAxMS40MzFhMi42MjYgMi42MjYgMCAxIDEtMy43MTUtMy43MTVsMS4zMDYtMS4yNjgiLz4KICAgICAgICA8cGF0aCBkPSJNNi4yMyA3LjcxNmEyLjYyNiAyLjYyNiAwIDAgMSAwLTMuNzE0bDEuNDg2LTEuNDg2YTIuNjI2IDIuNjI2IDAgMSAxIDMuNzE1IDMuNzE1bC0xLjMwNSAxLjI2NyIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0.5rem 0.15rem 0;
      vertical-align: middle;
      width: 12px;
    }

    &.connected-right::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNEVDMkMyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIGQ9Ik03LjcxNiA2LjIzYTIuNjI2IDIuNjI2IDAgMCAxIDAgMy43MTVMNi4yMyAxMS40MzFhMi42MjYgMi42MjYgMCAxIDEtMy43MTUtMy43MTVsMS4zMDYtMS4yNjgiLz4KICAgICAgICA8cGF0aCBkPSJNNi4yMyA3LjcxNmEyLjYyNiAyLjYyNiAwIDAgMSAwLTMuNzE0bDEuNDg2LTEuNDg2YTIuNjI2IDIuNjI2IDAgMSAxIDMuNzE1IDMuNzE1bC0xLjMwNSAxLjI2NyIvPgogICAgPC9nPgo8L3N2Zz4K) no-repeat center center;
      content: '';
      display: inline-block;
      height: 12px;
      margin: 0 0 0.15rem 0.5rem;
      vertical-align: middle;
      width: 12px;
    }
  }

  &.is-color-white {
    color: $color-white;

    &.arrow-left::before {
      @include tick($direction: left, $color: $color-white, $size: 0.4rem, $tickness: 1px);

      margin: 0 0.35rem 0.15rem 0;
    }

    &.arrow-right::after {
      @include tick($direction: right, $color: $color-white, $size: 0.4rem, $tickness: 1px);

      margin: 0 0 0.15rem 0.35rem;
    }
  }
}

.is-link-in-text {
  @include font-family-default();

  color: $color-text-default;
  cursor: pointer;
  font-size: 1.14rem;
  text-decoration: underline;

  @include desktop {
    font-size: 1rem;
  }

  &:hover {
    color: $color-primary;
  }
}
@charset 'utf-8';

@import 'bootstrap';

// Apply MIXINS overrides
@import 'pre-overrides';

//
// Third-Party CSS
//

@import '/node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import '/node_modules/dialog-polyfill/dialog-polyfill';
@import '/node_modules/normalize.css/normalize.css';
//
// Elements
//

@import 'elements/html';
@import 'elements/links';
@import 'elements/lists';
@import 'elements/media';
@import 'elements/buttons';
@import 'elements/forms';
// @import 'elements/iframe';
@import 'elements/quotes';
@import 'elements/table';
@import 'elements/mark';
@import 'elements/hr';
@import 'elements/spaces';
@import 'elements/loader';

//
// Containers
//

@import 'containers/container';
@import 'containers/header';
@import 'containers/product-list';
@import 'containers/accordion';
@import 'containers/product-information';
@import 'containers/product-tiles';
@import 'containers/order-lines';
@import 'containers/bottom';
@import 'containers/header-mini';

//
// Fragments
//

@import 'fragments/page-transition';
@import 'fragments/product-tile';
@import 'fragments/product-line';
@import 'fragments/order-summary';
@import 'fragments/order-line';
@import 'fragments/order-details';
@import 'fragments/order-overview';
@import 'fragments/cart-totals';
@import 'fragments/tabs';
@import 'fragments/product-summary';
@import 'fragments/dialog';
@import 'fragments/delivery-or-pickup';
@import 'fragments/product-specifications';
@import 'fragments/my-addresses';
@import 'fragments/personal-details';
@import 'fragments/add-to-list-popup';
@import 'fragments/list-tile-switch';
@import 'fragments/tooltip';
@import 'fragments/label-badge';
@import 'fragments/place-order-and-toc';
@import 'fragments/login-box';
@import 'fragments/product-image';
@import 'fragments/product-tags';
@import 'fragments/filter-selections';
@import 'fragments/lists-overview';
@import 'fragments/order-table';
@import 'fragments/quotes';
@import 'fragments/order-detail-page';
@import 'fragments/breadcrumbs';
@import 'fragments/product-description';
@import 'fragments/page-title';
@import 'fragments/hr-with-markings';
@import 'fragments/footer-bottom';
@import 'fragments/footer-main';
@import 'fragments/cookie-banner';
@import 'fragments/notification';
@import 'fragments/notification-global';
@import 'fragments/404-navigational-links';
@import 'fragments/list-detail-page';
@import 'fragments/go-to-top-button';
@import 'fragments/order_reservation';
@import 'fragments/order-failure-message';
@import 'fragments/auto-suggest';
@import 'fragments/pagination';
@import 'fragments/rations.scss';
@import 'fragments/users-table.scss';
@import 'fragments/clear-cart-popup.scss';
@import 'fragments/discount.scss';
@import 'fragments/slidedown-panel.scss';
@import 'fragments/fragment-badge.scss';

//
// Pages
//
@import 'pages/product-detail';
@import 'pages/passsword-forgotten';
@import 'pages/password-reset';
@import 'pages/cart';
@import 'pages/orders';
@import 'pages/lists';
@import 'pages/registration';
@import 'pages/product_list';
@import 'pages/password-reset-confirmation';
@import 'pages/registration-confirmation';
@import 'pages/login';

//
// Components
//

// Above the fold (needs to load first)
@import 'components/logo';
@import 'components/service-navigation';
@import 'components/main-navigation';
@import 'components/category-navigation';
@import 'components/meta-navigation';
@import 'components/search-bar';
@import 'components/highlight-categories';
@import 'components/hero-banner';
@import 'components/rich-text';
@import 'components/cart-icon';
@import 'components/inline-progress-indicator';
@import 'components/assortment-tile-with-diamond-intro';
@import 'components/assortment-tile';
@import 'components/language-switcher';
@import 'components/language-switcher-list';
@import 'components/product-category-tile';
@import 'components/contact';
@import 'components/target-destination';
@import 'components/teaser';
@import 'components/order-actions';
@import 'components/quick-order';
@import 'components/logistic-details';
@import 'components/carousel-video';
@import 'components/popup';
@import 'components/order-bulk-upload';
@import 'components/request-and-order';

// Below the fold (can load a bit later)
@import 'fragments/product-carousel';
@import 'components/saved-items';
@import 'components/service-block';
@import 'components/inpage-navigation';
@import 'components/filter';
@import 'components/footer-category-navigation';
@import 'components/footer-navigation';
@import 'components/barcode-scanner';

// Apply overriden specific stylings
@import 'overrides';
