.container--header-mini {
  .header-background {
    background: $color-background-white-shade;
    border-bottom: solid 1px $color-border-default;
    margin-bottom: -4.5rem;
  }

  .header-mini__logo-container {
    justify-content: center;
    margin: 0;
    padding: 0.5rem 0;

    .column {
      padding: 0;
    }

    a {
      display: block;
    }
  }
}
