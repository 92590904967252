.fragment--login-box {
  .login-box {
    &_layout {
      background-position: center center;
      background-repeat: repeat;
      position: relative;

      @include desktop {
        bottom: 0;
        left: 0;
        overflow: auto;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    &_layout-shadow {
      background: rgba(56, 45, 85, 0.6);
      padding: 4rem 1rem 2rem;

      @include desktop {
        bottom: 0;
        height: 100%;
        justify-content: center;
        left: 0;
        overflow: auto;
        padding: 5rem 0 6rem 0;
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }

    &_dialog-border {
      @include negative-border-radius($color: $color-border-default, $position: all, $size: 1.25rem);

      padding: 2px;

      @include desktop {
        margin: auto;
        width: 910px;
      }
    }

    &_dialog {
      @include negative-border-radius($color: $color-white, $position: all, $size: 1.25rem);

      margin: 0;

      @include desktop {
        width: 906px;
      }
    }

    &_dialog form > div {
      margin-bottom: 1rem;

      &.form-checkbox {
        margin-bottom: 0;
        margin-top: 1.5rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_login-section {
      padding: 2rem 1.5rem;

      @include desktop {
        padding: 3.5rem;
      }
    }

    &_register-section {
      @include negative-border-radius($color: $color-lighter, $position: bottom, $size: 1.25rem);

      padding: 2rem 1.5rem 3rem;

      @include desktop {
        @include negative-border-radius($color: $color-lighter, $position: right, $size: 1.25rem);

        padding: 3.5rem;
      }
    }

    &_register-text {
      padding-bottom: 1rem;

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }

  .message-display {
    margin: 0 -1.5rem 1rem;

    @include desktop {
      margin: 0 0 1rem;
    }
  }
}

.login-box__isAccount-heading,
.login-box__noAccount-heading {
  @include font-personal-titles();

  margin-bottom: 1.5rem;
}
