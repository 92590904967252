@for $i from 1 through 10 {
  .space-mb-#{$i} {
    margin-bottom: #{$i * 0.5rem};
  }

  .space-mt-#{$i} {
    margin-top: #{$i * 0.5rem};
  }

  .space-ml-#{$i} {
    margin-left: #{$i * 0.5rem};
  }

  .space-mr-#{$i} {
    margin-right: #{$i * 0.5rem};
  }

  .space-m-#{$i} {
    margin-bottom: #{$i * 0.5rem};
    margin-top: #{$i * 0.5rem};
  }

  @include touch {
    .space-mb-touch-#{$i} {
      margin-bottom: #{$i * 0.5rem};
    }

    .space-mt-touch-#{$i} {
      margin-top: #{$i * 0.5rem};
    }

    .space-ml-touch-#{$i} {
      margin-left: #{$i * 0.5rem};
    }

    .space-mr-touch-#{$i} {
      margin-right: #{$i * 0.5rem};
    }

    .space-m-touch-#{$i} {
      margin-bottom: #{$i * 0.5rem};
      margin-top: #{$i * 0.5rem};
    }
  }

  @include desktop {
    .space-mb-desktop-#{$i} {
      margin-bottom: #{$i * 0.5rem};
    }

    .space-mt-desktop-#{$i} {
      margin-top: #{$i * 0.5rem};
    }

    .space-ml-desktop-#{$i} {
      margin-left: #{$i * 0.5rem};
    }

    .space-mr-desktop-#{$i} {
      margin-right: #{$i * 0.5rem};
    }

    .space-m-desktop-#{$i} {
      margin-bottom: #{$i * 0.5rem};
      margin-top: #{$i * 0.5rem};
    }
  }
}
