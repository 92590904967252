.fragment--my-addresses {
  @include touch {
    position: relative;
  }

  .my-addresses__edit-link {
    @extend .is-color-secondary;
  }

  .my-addresses__warning,
  .my-addresses__success {
    @include font-small-body-copy();

    margin: 1.625rem -1rem 0;
    padding: 1rem 1rem 1rem 2.5rem;
    position: relative;
    text-indent: -1rem;

    @include desktop {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .my-addresses__warning {
    background-color: $color-notification-warning;
    color: $color-notification-warning-text;

    &::before {
      background-color: $color-notification-warning-text;
      border-radius: 50%;
      color: $color-notification-warning;
      content: '!';
      display: inline-block;
      height: 1rem;
      left: -0.5rem;
      line-height: 1rem;
      position: relative;
      text-align: center;
      text-indent: 0;
      width: 1rem;
    }
  }

  .my-addresses__success {
    background-color: $color-notification-success-tint;
    color: $color-notification-success;

    &::before {
      background-color: $color-notification-success;
      border-radius: 50%;
      color: $color-notification-success-tint;
      content: '\2713';
      display: inline-block;
      height: 1rem;
      left: -0.5rem;
      line-height: 1rem;
      position: relative;
      text-align: center;
      text-indent: 0;
      width: 1rem;
    }
  }

  .my-addresses__address-boxes__wrapper {
    margin: 2rem 0.25rem;
  }

  .my-addresses--default-address {
    order: -1;
  }

  .my-addresses__address-box {
    border: 1px solid $color-border-default;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.address-box--default-delivery {
      @include fancy-border($allPositions: true, $width: 0.25rem);
    }
  }

  .my-addresses__address-box__title {
    overflow: hidden;

    &.has-border {
      border-bottom: 1px solid $color-border-default;
    }

    h3 {
      @include font-h4();
      @include text-uppercase;

      padding: 1rem 1rem calc(1rem - 1px);
    }

    @include desktop {
      height: 3.5rem;
    }
  }

  .my-addresses__address-box__content {
    padding: 0 1rem 1rem;

    label.is-link {
      @extend .is-color-secondary;
    }
  }

  .field-list {
    padding-bottom: 1rem;
  }

  .my-addresses__form {
    margin: 1rem 0;
  }

  .checkbox--address {
    &:not(:checked) ~ .checkbox__address-form,
    &:not(:checked) ~ .my-addresses__address-boxes__wrapper .checkbox__address-form {
      display: none;
    }

    &:checked ~ .checkbox__address-info,
    &:checked ~ .my-addresses__address-boxes__wrapper .checkbox__address-info {
      display: none;
    }
  }

  .checkbox__address-info {
    flex-wrap: wrap;
  }

  .my-addresses {
    &_layout {
      padding: 2.5rem 0.5rem 2.5rem 2.5rem;

      @include touch {
        padding: 1.5rem 1rem;
      }
    }

    &_dialog-border {
      @include negative-border-radius($color: $color-border-default, $position: all, $size: 1.25rem);

      padding: 2px;

      @include desktop {
        margin: auto;
      }
    }

    &_dialog {
      @include negative-border-radius($color: $color-white, $position: all, $size: 1.25rem);

      margin: 0;
      padding: 2rem;
    }
  }

  .background-image-combi {
    @include combi-background($background: (url(../images/my-account-first.png) repeat-y right 100px, url(../images/my-account-second.png) repeat-y left 100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left), $backgroundMobile: (url(/images/my-account-first.png) repeat-y right -100px, url(/images/my-account-second.png) repeat-y left -100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left));
  }

  @include desktop {
    .my-addresses__intro-text {
      width: 66.66667%;
    }

    .my-addresses__2-grid-info {
      column-gap: 2rem;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }

    .my-addresses__2-grid-input {
      column-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }
  }
}
