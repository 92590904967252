dialog {
  border: 0;
  color: $color-tetrad;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;

  @include touch {
    max-width: 100%;
    max-height: 100%;
  }

  @include desktop {
    border: 1px solid $color-border-default;
    max-height: 80%;
    max-width: 724px;
    top: 50%;
    transform: translate(0, -30%);
    width: 80%;

    &.is-full-width {
      max-width: $wrapper-max-width;
    }
  }

  &::backdrop,
  + .backdrop {
    background-color: $color-background-backdrop;
    bottom: 0;
    left: 0;
    margin: -100vw;
    position: fixed;
    right: 0;
    top: 0;
  }

  .dialog__inner-wrapper {
    padding: 3rem;
  }

  .dialog__close-icon {
    cursor: pointer;
    height: 3em;
    left: 0;
    margin: 1.5em 0 0 1.75rem;
    position: absolute;
    top: 0;
    width: 3em;
    z-index: 1;
  }
}

.has-dialog-activated {
  overflow-y: hidden;

  //iOS Safari does not support <dialog> tag. dialog-polyfill does not always work correctly.
  //This is needed to fix the context stacking overlap for target-destination menu.
  @include touch {
    .component--service-navigation {
      z-index: 440;
    }

    .service-navigation--container,
    .service-navigation--inner-container {
      position: fixed;
    }
  }
}

// these rules applies just for Safari
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    dialog {
      @include desktop {
        transform: translate(0, -50%);
      }
    }

    div.backdrop {
      background: $color-background-backdrop;
    }
  }
}

// these rules applies just for Firefox
@-moz-document url-prefix() {
  dialog {
    @include desktop {
      transform: translate(0, -50%);
    }
  }
}
