@media print {
  *,
  *::after,
  *::before {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #777;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }
}
