.fragment--lists-overview {
  .lists-overview {
    &_container {
      padding: 0;
    }

    &_favorite-list-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 1rem;

      @include tablet {
        padding-bottom: 1.5rem;
      }
    }

    &_favorite-item {
      margin: 0;
      width: 100%;

      @include mobile {
        margin-bottom: 4rem;
      }

      @include tablet {
        width: 10rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        text-decoration: none;
      }
    }

    &_favorite-title {
      align-items: center;
      border-bottom: solid 1px $color-border-light;
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.4rem;

      @include tablet {
        display: block;
      }
    }

    &_favorite-description {
      min-height: 4.25rem;
      padding: 0.5rem 0 1rem;

      @include tablet {
        min-height: 5.25rem;
      }
    }

    &_favorite-images-container {
      @include fancy-border($allPositions: true, $width: 4px);

      display: flex;
      line-height: 0;

      @include tablet {
        flex-wrap: wrap;
        width: 10rem;
      }
    }

    &_favorite-images-container > div:first-child {
      border-right: solid 1px $color-border-default;
      min-height: 11.8rem;
      padding: 0.75rem;

      @include mobile {
        width: 66.6667%;
      }

      @include tablet {
        border-bottom: solid 1px $color-border-default;
        border-right: 0;
        min-height: 10rem;
        width: 10rem;
      }
    }

    &_favorite-images-container > div:nth-child(2) {
      @include mobile {
        width: 33.3334%;
      }

      @include tablet {
        display: flex;
      }
    }

    &_favorite-images-container > div:nth-child(2) > div {
      min-height: 5.85rem;
      padding: 0.75rem;
      width: 100%;

      @include tablet {
        min-height: 4.9rem;
        width: 4.9rem;
      }
    }

    &_favorite-images-container > div:nth-child(2) > div:first-child {
      border-bottom: solid 1px $color-border-default;

      @include tablet {
        border-bottom: 0;
        border-right: solid 1px $color-border-default;
      }
    }

    &_message-container {
      margin: 1rem -1.5rem 0;

      @include tablet {
        margin: 0 -4px;
      }

      .fragment--message-display.message-display {
        margin-bottom: 0;
      }
    }

    &_new-list-button {
      margin: 0.5rem 0 0;
      text-align: right;
    }

    &_item-row-container a {
      text-decoration: none;
    }

    &_item-row {
      @include fancy-border($allPositions: true, $width: 3px);

      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 1rem;

      @include tablet {
        @include fancy-border($allPositions: true, $width: 4px);

        margin-bottom: 1.5rem;
        padding: 1.5rem;
      }
    }

    &_item-count {
      flex-shrink: 0;
    }

    &_layout {
      padding: 2.5rem 0.5rem 2.5rem 2.5rem;

      @include mobile {
        padding: 1.5rem 1rem;
      }
    }

    &_dialog-border {
      @include negative-border-radius($color: $color-border-default, $position: all, $size: 1.25rem);

      padding: 2px;

      @include tablet {
        margin: auto;
      }
    }

    &_dialog {
      @include negative-border-radius($color: $color-white, $position: all, $size: 1.25rem);

      margin: 0;
      padding: 2rem;
    }

    &_name-already-exists-error-container {
      .fragment--notification {
        margin: -1rem 0 1rem;
      }
    }
  }

  .lists-overview_new-list-container {
    background-color: $color-light;
    margin: 1rem -1.5rem;
    padding: 1.5rem;

    @include tablet {
      display: flex;
      margin: 1.75rem -4px;
      padding: 1rem 1.5rem;

      > div:first-child {
        margin-right: 1.5rem;
      }

      input {
        height: 3rem;
        margin-top: 0;
      }
    }

    .lists-overview_name-already-exists-error-container {
      .fragment--notification {
        margin: 0.5rem 0;
        padding: 0.5rem;
      }
    }
  }

  .has-float-label {
    @include tablet {
      width: 19em;
    }
  }

  .background-image-combi {
    @include combi-background($background: (url(../images/my-account-first.png) repeat-y right 100px, url(../images/my-account-second.png) repeat-y left 100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left), $backgroundMobile: (url(/images/my-account-first.png) repeat-y right -100px, url(/images/my-account-second.png) repeat-y left -100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left));
  }

  @include tablet {
    .lists-overview__intro-text {
      width: 66.66667%;
    }
  }
}

.lists-overview_item-row__head1 {
  @include font-h3();
}

.lists-overview_item-row__head2,
.lists-overview_favorite-title__products-amount {
  @include font-h5();
}
