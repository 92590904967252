.component--search-bar {
  position: relative;
  display: block;

  &:not(.product-autosuggest)::after {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29uL1NlYXJjaDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxnIGlkPSJTZWFyY2gtYmFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTg4LjAwMDAwMCwgLTguMDAwMDAwKSIgc3Ryb2tlPSIjOTc5Nzk3Ij4KICAgICAgICAgICAgPGcgaWQ9Ikljb24vU2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODguMDAwMDAwLCA4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9Inpvb20iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMy41LDEzLjUgTDEwLjM1ODg4MDcsMTAuMjAzMzc0NSIgaWQ9IlBhdGgiIHN0cm9rZS13aWR0aD0iMS41Ij48L3BhdGg+CiAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGN4PSI2IiBjeT0iNiIgcj0iNS41Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+) center center no-repeat;
    content: '';
    height: 1rem;
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    width: 1rem;
    z-index: 500;
  }

  input[type='search'].search-input {
    border: 0;
    border-bottom: 1px solid $color-light;
    padding-right: 2rem;
  }
}

@include touch {
  .toggle--hamburger.is-toggle-trigger:not(:checked) ~ .container--header .search-bar-nav {
    @include negative-border-radius($color: $color-border-default, $position: bottom, $size: 1rem);

    border-top: 1px solid $color-border-default;
    padding: 0 1px 1px;
    position: relative;
    width: 100%;

    &::before {
      @include negative-border-radius($color: $color-white, $position: bottom, $size: 1rem);

      content: '';
      height: calc(100% - 1px);
      position: absolute;
      top: 0;
      width: calc(100% - 2px);
    }

    .toggle--search {
      color: $color-grey;
      cursor: pointer;
      display: block;
      margin: 0 1rem;
      // 20px = height of background image
      padding: 1rem 1rem 2rem;
      position: relative;

      &::after {
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29uL1NlYXJjaDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTZWFyY2gtYmFyLShpbi1uYXZpZ2F0aW9uKSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iQUFfU2VhcmNoLWJhci1jbGljay0obmF2aWdhdGlvbiktKG0pIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjc4LjAwMDAwMCwgLTk1LjAwMDAwMCkiIHN0cm9rZT0iIzk3OTc5NyI+CiAgICAgICAgICAgIDxnIGlkPSJJY29uL1NlYXJjaCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjc4LjAwMDAwMCwgOTUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iem9vbSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC44NzUwMDAsIDAuODc1MDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTExLjgxMjUsMTEuODEyNSBMOS4wNjQwMjA1OSw4LjkyNzk1MjY5IiBpZD0iUGF0aCIgc3Ryb2tlLXdpZHRoPSIxLjUiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBzdHJva2Utd2lkdGg9IjEuNSIgY3g9IjUuMjUiIGN5PSI1LjI1IiByPSI0LjgxMjUiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=) center center no-repeat;
        content: '';
        height: 1rem;
        position: absolute;
        right: 0.5rem;
        top: 1.5rem;
        width: 1rem;
      }
    }
  }

  .toggle--hamburger.is-toggle-trigger:checked ~ .container--header .search-bar-nav {
    display: none;
  }

  .toggle--search-container {
    &.is-toggle-handler {
      background: $color-background-white;
      height: 100vh;
      padding: 3rem 1rem;
      position: fixed;
      top: 0;
      transform: translateX(100%);
      transition: transform $speed ease-in-out;
      width: 100%;
      z-index: $zindex--container-header + 20;
    }

    .search-back-container {
      padding-bottom: 2rem;
    }

    .is-closing {
      background:
        linear-gradient(-45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat,
        linear-gradient(45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat;
      color: $color-grey;
      cursor: pointer;
      height: 2rem;
      position: fixed;
      right: 1rem;
      text-indent: -420rem;
      top: 2rem;
      width: 2rem;
    }

    .component--search-bar::after {
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29uL1NlYXJjaDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTZWFyY2gtYmFyLShpbi1uYXZpZ2F0aW9uKSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iQUFfU2VhcmNoLWJhci1jbGljay0obmF2aWdhdGlvbiktKG0pIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjc4LjAwMDAwMCwgLTk1LjAwMDAwMCkiIHN0cm9rZT0iIzk3OTc5NyI+CiAgICAgICAgICAgIDxnIGlkPSJJY29uL1NlYXJjaCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjc4LjAwMDAwMCwgOTUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iem9vbSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC44NzUwMDAsIDAuODc1MDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTExLjgxMjUsMTEuODEyNSBMOS4wNjQwMjA1OSw4LjkyNzk1MjY5IiBpZD0iUGF0aCIgc3Ryb2tlLXdpZHRoPSIxLjUiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBzdHJva2Utd2lkdGg9IjEuNSIgY3g9IjUuMjUiIGN5PSI1LjI1IiByPSI0LjgxMjUiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=) center center no-repeat;
    }

    .component--search-bar .autosuggest__results {
      border: 0;
    }
  }

  .toggle--search.is-toggle-trigger:checked ~ .container--header {
    .toggle--search-container.is-toggle-handler {
      transform: translateX(0);
    }
  }
}

$color-background-tinted: rgba(0, 0, 0, 0.4);

.search-bar-autosuggest {
  position: relative;

  @include desktop {
    &.dropdown-expanded::before {
      content: '';
      background: $color-background-tinted;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      width: 100vw;
      z-index: 435;
    }
  }

  @include touch {
    margin: 0 -14px;
    padding: 0 14px;
    height: calc(100vh - 92px);
    overflow: auto;
    padding-bottom: 35px;
  }

  &.dropdown-expanded > div.search-bar__input input {
    z-index: 500;
    position: relative;
  }

  .search-bar__input {
    &::after {
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29uL1NlYXJjaDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxnIGlkPSJTZWFyY2gtYmFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTg4LjAwMDAwMCwgLTguMDAwMDAwKSIgc3Ryb2tlPSIjOTc5Nzk3Ij4KICAgICAgICAgICAgPGcgaWQ9Ikljb24vU2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODguMDAwMDAwLCA4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9Inpvb20iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMy41LDEzLjUgTDEwLjM1ODg4MDcsMTAuMjAzMzc0NSIgaWQ9IlBhdGgiIHN0cm9rZS13aWR0aD0iMS41Ij48L3BhdGg+CiAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGN4PSI2IiBjeT0iNiIgcj0iNS41Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+) center center no-repeat;
      content: '';
      height: 1rem;
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      width: 1rem;
      z-index: 500;

      @include desktop {
        right: 0.5rem;
      }
    }

    input {
      position: relative;
      border: 0;
      border-bottom: 1px solid $color-light;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      font-size: 1rem;
      height: 3rem;
      line-height: 1.5;
      padding: 0.6667rem;
      padding-right: 2rem;
      margin: 0;
      outline-offset: -2px;
      z-index: 500;
    }
  }

  .search-bar__dropdown {
    position: static;
    z-index: 500;
    top: 52px;
    left: 0;
    width: 100%;

    @include desktop {
      position: absolute;
      background-color: $color-background-lighter;
    }

    display: grid;
    grid-template-columns: 1fr;

    &--left {
      width: inherit;
    }

    &--left .search-results {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 18px;

      @include desktop {
        margin-top: 0;
        gap: initial;
      }
    }
  }
}

.result-section {
  padding: 6px 0;

  @include desktop {
    padding: 14px 36px;
  }

  &__heading {
    .title {
      @include font-family-title();

      font-size: 1.286rem;
      font-weight: 500;
      color: $color-text-default;
      text-transform: uppercase;

      @include desktop {
        font-size: 1rem;
      }
    }
  }

  &__content {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 4px;

      @include desktop {
        margin-top: 11px;
      }
    }

    ul li {
      padding: 10px 0;
      line-height: 18px;

      &::before {
        display: none;
      }

      @include desktop {
        padding: 11px 0;
      }
    }

    ul li a {
      cursor: pointer;
      font-size: 1rem;
      color: $color-text-black;
      text-decoration: none;

      @include font-family-default();

      @include desktop {
        font-size: 0.875rem;
      }

      span {
        font-weight: bold;
      }
    }
  }

  &:first-of-type {
    @include desktop {
      background-color: $color-background-light;
      padding-top: 20px;
    }

    ul {
      margin-top: 10px;

      @include desktop {
        margin-top: 14px;
      }

      li {
        padding: 4px 0;
      }
    }
  }
}

.footer-action {
  display: flex;
  align-items: center;
  justify-content: center;

  .button--primary {
    background-color: $color-complementary;
    color: $color-white;
    border: 0;
    padding: 8px 24px;
    font-size: 1.125rem;
    height: 40px;
    transition: $speed-slow;
    margin-right: 0;

    @include desktop {
      margin-top: 15px;
      font-size: 1rem;
      height: 36px;
      padding: 8px 41px;
    }

    @include touch {
      width: 100%;
      margin: 0;
      margin-top: 23px;
      max-width: 100%;
    }

    &:hover {
      background-color: $color-complementary-hover;
    }
  }
}
