.vld-overlay {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $zindex__util__loader;
}

.vld-overlay.is-active {
  display: flex;
}

.vld-overlay.is-full-page {
  position: fixed;
  z-index: $zindex__util__loader--full-page;
}

.vld-overlay .vld-background {
  background: $color-background-backdrop;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}

.vld-icon > svg {
  fill: $color-primary;
  transform: scale(1);
}
