// Generated with https://www.gridlover.net/try
// Base font-size: 16px
// Base line-height: 1.6
// Scale factor: 1.6 (5:8 minor sixth)

// Roboto Slab

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto Slab Regular'),
    local('RobotoSlab-Regular'),
    url('#{$path-fonts}/roboto-slab/RobotoSlab-Regular.woff2') format('woff2'),
    url('#{$path-fonts}/roboto-slab/RobotoSlab-Regular.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD,
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src:
    local('Roboto Slab Bold'),
    local('RobotoSlab-Bold'),
    url('#{$path-fonts}/roboto-slab/RobotoSlab-Bold.woff2') format('woff2'),
    url('#{$path-fonts}/roboto-slab/RobotoSlab-Bold.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD,
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}

// Barlow Condensed

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src:
    local('Barlow Condensed Regular'),
    local('BarlowCondensed-Regular'),
    url('#{$path-fonts}/barlow-condensed/BarlowCondensed-Regular.woff2') format('woff2'),
    url('#{$path-fonts}/barlow-condensed/BarlowCondensed-Regular.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD,
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 500;
  src:
    local('Barlow Condensed Medium'),
    local('BarlowCondensed-Medium'),
    url('#{$path-fonts}/barlow-condensed/BarlowCondensed-Medium.woff2') format('woff2'),
    url('#{$path-fonts}/barlow-condensed/BarlowCondensed-Medium.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD,
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  src:
    local('Barlow Condensed Bold'),
    local('BarlowCondensed-Bold'),
    url('#{$path-fonts}/barlow-condensed/BarlowCondensed-Bold.woff2') format('woff2'),
    url('#{$path-fonts}/barlow-condensed/BarlowCondensed-Bold.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD,
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}

// Dancing Script

@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src:
    local('Dancing Script Regular'),
    local('DancingScript-Regular'),
    url('#{$path-fonts}/dancing-script/dancingscript-regular-webfont.woff2') format('woff2'),
    url('#{$path-fonts}/dancing-script/dancingscript-regular-webfont.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 700;
  src:
    local('Dancing Script Bold'),
    local('DancingScript-Bold'),
    url('#{$path-fonts}/dancing-script/dancingscript-bold-webfont.woff2') format('woff2'),
    url('#{$path-fonts}/dancing-script/dancingscript-bold-webfont.woff') format('woff');
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}

@mixin font-family-default() {
  font-family: Roboto Slab, Georgia, Times New Roman, Times, serif;
}

@mixin font-family-title() {
  font-family: Barlow Condensed, Impact, sans-serif;
}

@mixin font-family-script() {
  font-family: Dancing Script, sans-serif;
}

@mixin font-weight-bold() {
  font-weight: 700;
}

@mixin text-uppercase() {
  text-transform: uppercase;
}

@mixin text-align-center() {
  text-align: center;
}

@mixin text-align-right() {
  text-align: right;
}

@mixin text-align-left {
  text-align: left;
}

// Font styling

html {
  @include touch {
    font-size: 14px;
    line-height: 24px;
  }

  @include desktop {
    font-size: 16px;
    line-height: 26px;
  }
}

body {
  @include font-family-default();

  font-size: 1em;
  line-height: 1.625em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.font-family-default {
  @include font-family-default();
}

.font-family-title {
  @include font-family-title();
}

.font-family-script {
  @include font-family-script();
}

.font-weight-bold {
  @include font-weight-bold();
}

.text-uppercase {
  @include text-uppercase();
}

.text-align-right {
  @include text-align-right();
}

.text-align-center {
  @include text-align-center();
}

.text-align-left {
  @include text-align-left();
}

.text-color-dimmed {
  color: $color-text-dimmed;
}

p + p {
  margin-top: $space-1x;
}

a,
b,
i,
strong,
em,
small,
code {
  line-height: 0;
}

b,
strong {
  font-weight: 700;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

///NEW STYLEGUIDE FONTS///
@mixin font-hero() {
  @include font-family-title();

  color: $color-text-default;
  font-size: 2.28rem;
  font-weight: 500;
  line-height: 1.13;

  @include desktop {
    font-size: 4rem;
  }

  &.text-white,
  .text-white {
    color: $color-white;
  }
}

@mixin font-h1() {
  @include font-family-title();

  color: $color-text-default;
  font-size: 1.72rem;
  font-weight: 500;
  line-height: 1.17;

  @include desktop {
    font-size: 2.5rem;
  }
}

@mixin font-h2() {
  @include font-family-title();

  color: $color-text-default;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.19;

  @include desktop {
    font-size: 2rem;
    line-height: 1.25;
  }
}

@mixin font-h3() {
  @include font-family-title();

  color: $color-text-default;
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.33;

  @include desktop {
    font-size: 1.5rem;
    line-height: 1.17;
  }
}

@mixin font-h4() {
  @include font-family-title();

  color: $color-text-default;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;

  @include desktop {
    font-size: 1.125rem;
    line-height: 1.33;
  }
}

@mixin font-h5($font-weight: 400, $line-height: 1.33) {
  @include font-family-title();

  color: $color-text-default;
  font-size: 0.85rem;
  font-weight: $font-weight;
  line-height: 1.5;

  @include desktop {
    font-size: 0.875rem;
    line-height: $line-height;
  }
}

@mixin font-h6() {
  @include font-family-title();

  color: $color-text-default;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.6;

  @include desktop {
    font-size: 0.75rem;
    line-height: 1.65;
  }
}

@mixin font-personal-titles() {
  @include font-family-script();

  color: $color-text-default;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.14;

  @include desktop {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@mixin font-quotes() {
  @include font-family-script();

  color: $color-complementary;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.14;

  @include desktop {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@mixin font-large-intro-copy() {
  @include font-family-default();

  color: $color-text-default;
  font-size: 1.14rem;
  font-weight: normal;
  line-height: 1.56;

  @include desktop {
    font-size: 1.25rem;
    line-height: 1.6;
  }

  &.text-grey,
  .text-grey {
    color: $color-grey;
  }
}

@mixin font-regular-body-copy() {
  @include font-family-default();

  color: $color-text-default;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;

  &.text-grey,
  .text-grey {
    color: $color-grey;
  }

  &.text-white,
  .text-white {
    color: $color-white;
  }

  &.font-bold {
    font-weight: bold;
  }

  &.text-tetrad,
  .text-tetrad {
    color: $color-tetrad;
  }
}

@mixin font-small-body-copy() {
  @include font-family-default();

  color: $color-text-default;
  font-size: 0.86rem;
  font-weight: 400;
  line-height: 1.58;

  @include desktop {
    font-size: 0.75rem;
    line-height: 1.67;
  }

  &.text-grey,
  .text-grey {
    color: $color-grey;
  }

  &.font-bold {
    font-weight: bold;
  }
}

@mixin font-extra-small-body-copy() {
  @include font-family-default();

  color: $color-text-default;
  font-size: 0.57rem;
  font-weight: 400;
  line-height: 1.58;

  @include desktop {
    font-size: 0.625rem;
    line-height: 1.67;
  }

  &.text-grey,
  .text-grey {
    color: $color-grey;
  }
}

.font-hero {
  @include font-hero();
}

.font-h1 {
  @include font-h1();
}

.font-h2 {
  @include font-h2();
}

.font-h3 {
  @include font-h3();
}

.font-h4 {
  @include font-h4();
}

.font-h5 {
  @include font-h5();
}

.font-h6 {
  @include font-h6();
}

.font-personal-titles {
  @include font-personal-titles();
}

.font-quotes {
  @include font-quotes();
}

.font-large-intro-copy {
  @include font-large-intro-copy();
}

.font-regular-body-copy {
  @include font-regular-body-copy();
}

.font-small-body-copy {
  @include font-small-body-copy();
}

.font-extra-small-body-copy {
  @include font-extra-small-body-copy();
}
