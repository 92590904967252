.component--meta-navigation {
  > ul {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }

  &:not(.has-hamburger) > ul {
    justify-content: flex-end;
  }

  li {
    padding: 0 1rem;

    &:last-of-type {
      padding-right: 0;
    }

    a {
      display: flex;
    }
  }
}

@include touch {
  .meta-navigation__profile {
    left: -1.2rem;
    position: relative;

    &.not-logged-in {
      left: auto;
      position: absolute;
      right: 1.5rem;
    }
  }

  .component--meta-navigation {
    svg {
      position: relative;
      transform: scale(1.5);

      &.my-dashboard-icon {
        transform: none;
      }
    }
  }
}

@include desktop {
  .component--meta-navigation {
    svg {
      transform: scale(1.5);

      &.my-dashboard-icon {
        transform: none;
      }
    }
  }
}
