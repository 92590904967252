.page--cart {
  .cart-header-container {
    display: flex;
    flex-direction: column;

    @include desktop {
      margin: 1rem auto 1rem;
    }

    .cart-title {
      @include font-h1;

      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  .fragment--cart-totals .cart-totals__submit-button button::before {
    content: attr(data-default-text);
  }

  .cart-totals__reservation-checkbox {
    &:checked ~ .fragment--cart-totals .cart-totals__submit-button button::before {
      content: attr(data-reservation-text);
    }

    &:checked ~ .fragment--delivery-pickup {
      display: none;
    }

    ~ .fragment--cart-totals .cart-totals__reservation-info-wrapper {
      display: none;
    }

    &:checked ~ .fragment--cart-totals .cart-totals__reservation-info-wrapper {
      display: block;
    }
  }

  .cart-totals__reservation-label .has-float-label > span {
    left: 0;
  }

  .cart-empty-title {
    @include font-personal-titles();
  }

  .cart-empty-description {
    @include font-regular-body-copy();
  }

  .container--download-spol {
    @include touch {
      margin: 0;
      margin-top: -1rem;
    }

    @include desktop {
      margin: -1rem 0 0 0;
    }

    .wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
    }

    .column:last-of-type {
      width: auto;
      flex: 0 0 auto;
    }

    .download-spol {
      @include desktop {
        padding-top: 0.35rem;
      }
    }
  }

  .container--download-spol.download-spol__with-quick-order {
    .column {
      @include desktop {
        padding: 1rem 0;
      }
    }
  }

  .cart-header-container + .container--download-spol {
    margin: -1rem 0 0 0;

    .column {
      padding: 0.5rem 1rem;
    }
  }

  .empty-cart .container--download-spol {
    margin: 0;

    .wrapper {
      justify-content: center;
    }
  }

  .download-spol-button {
    @extend %link-ui-primary;

    display: inline-block;
    font-size: 14px;
    line-height: 1.3;

    @include touch {
      display: block;
      margin: 0 0 1.5rem 0;
    }

    &::before {
      content: '';
      display: inline-block;
      height: 12px;
      margin-right: 0.5rem;
      vertical-align: middle;
      width: 12px;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI0YwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgICAgICAgPHBhdGggZD0iTTEwLjYxNCA4LjI4OHYxLjg2MWMwIC4yNTctLjI3NS40NjUtLjYxNS40NjVIMi4wMDFjLS4zNCAwLS42MTUtLjIwOC0uNjE1LS40NjV2LTEuODYiLz4NCiAgICAgICAgPGc+DQogICAgICAgICAgICA8cGF0aCBkPSJNMy43NSAwTDMuNzUgNy41IiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAyLjI1IDguNjI1KSBtYXRyaXgoMSAwIDAgLTEgMCA3LjUpIi8+DQogICAgICAgICAgICA8cGF0aCBkPSJNNy41IDBMMy43NSAzLjc1IDAgMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMi4yNSA4LjYyNSkgbWF0cml4KDEgMCAwIC0xIDAgMy43NSkiLz4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg0K') no-repeat;
    }

    &[disabled] {
      color: $color-grey;
      cursor: default;
      outline: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &::before {
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzk3OTc5NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4NCiAgICAgICAgPHBhdGggZD0iTTEwLjYxNCA4LjI4OHYxLjg2MWMwIC4yNTctLjI3NS40NjUtLjYxNS40NjVIMi4wMDFjLS4zNCAwLS42MTUtLjIwOC0uNjE1LS40NjV2LTEuODYiLz4NCiAgICAgICAgPGc+DQogICAgICAgICAgICA8cGF0aCBkPSJNMy43NSAwTDMuNzUgNy41IiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAyLjI1IDguNjI1KSBtYXRyaXgoMSAwIDAgLTEgMCA3LjUpIi8+DQogICAgICAgICAgICA8cGF0aCBkPSJNNy41IDBMMy43NSAzLjc1IDAgMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgMi4yNSA4LjYyNSkgbWF0cml4KDEgMCAwIC0xIDAgMy43NSkiLz4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg0K') no-repeat;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .container--cart {
    margin-top: 0;

    .container--order-lines {
      margin-top: 0;
    }
  }

  .empty-cart {
    margin-top: 1rem;
  }
}
