
hr {
  border: 1px solid $color-border-default;
  margin: -1px 0;

  &.is-thin {
    border: 0;
    border-top: 1px solid $color-border-default;
  }
}
