.fragment--order-detail-page {
  .order-detail-page__bg--white {
    position: relative;

    @include desktop {
      padding: 1rem 2rem 0 2.5rem;
    }

    &::before {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
      content: '';
      height: 6px;
      left: 0;
      position: absolute;
      right: 0;
      top: -6px;

      @include desktop {
        min-width: 100%;
        right: calc(0% - ((100vw - 1096px) / 2));
      }
    }
  }

  .order-detail-page__state-wrapper {
    @include desktop {
      align-items: baseline;
      display: flex;
    }

    @include touch {
      background: $color-lighter;
      margin: 0 -1rem;
    }
  }

  .order-detail-page__status {
    @include touch {
      margin: -1rem 0 1rem;
      padding: 1.5rem 1rem 1.5rem;
    }
  }

  .fragment--order-details {
    border: 0;
    box-shadow: none;

    @include desktop {
      dl:first-child {
        padding-right: 3rem;
      }

      dl:last-child {
        padding-left: 3rem;
      }
    }
  }

  .component--order-actions {
    flex-grow: 1;
    justify-self: flex-end;
  }

  .cart-totals__price-list li:last-child {
    margin-bottom: -1.625rem;
    padding-bottom: 3rem;
  }
}
