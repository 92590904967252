.page--registration-confirmation {
  .container--contact {
    .registration-confirmation-title {
      @include font-personal-titles();
    }
  }
}

.contact-extra__heading {
  @include font-personal-titles();

  margin-bottom: 2rem;
}
