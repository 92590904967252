.fragment--page-title {
  position: relative;

  .background-image-combi {
    @include combi-background($background: (url(../images/my-account-first.png) repeat-y right -100px, url(../images/my-account-second.png) repeat-y left -100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left), $backgroundMobile: (url(/images/my-account-first.png) repeat-y right -100px, url(/images/my-account-second.png) repeat-y left -100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left));

    @include desktop {
      top: -4rem; // fixes positioning of background image othervise it'll be different from other pages
      left: 0;
      right: calc(0% - ((100vw - 1096px) / 2));
    }
  }

  .page-title__container {
    padding: 2.5rem 3rem 0 2rem;

    @include touch {
      padding: 1.5rem 1rem 0;
    }
  }

  .page-title__border {
    @include negative-border-radius($color: $color-border-default, $position: top, $size: 1.25rem);

    border-bottom: 0;
    padding: 2px 2px 0;

    @include desktop {
      margin: auto;
    }
  }

  .page-title__inner-border {
    @include negative-border-radius($color: $color-white, $position: top, $size: 1.25rem);

    border-bottom: 0;
    margin: 0;
    padding: 2rem;
  }
}
