.component--filter {
  background-color: $color-background-white;

  @include desktop {
    border-right: solid 1px $color-grey;
    display: flex;
    height: 100%;
  }

  > div {
    width: 100%;
  }

  .filter-container {
    background-color: $color-background-white;
    height: 100vh;
    left: 0;
    overflow-y: auto;
    padding: 2rem 1rem 2.5rem 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 440;

    @include desktop {
      height: auto;
      overflow-y: initial;
      padding: 2rem 1rem 3.5rem 1rem;
      position: static;
    }

    .ais-Pagination > .button-container {
      margin-bottom: 7rem;
    }
  }

  .filter-close-icon {
    padding: 0 0 2.5rem 0;
    text-align: right;

    svg {
      cursor: pointer;
    }
  }

  .filter-section {
    @include desktop {
      padding-right: 1.5rem;

      .ais-CurrentRefinements {
        width: 100%;
      }
    }

    .accordion__body {
      padding-bottom: 0;
    }
  }

  .filter-title {
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .filter-selections_all-option {
    padding: 0 1.1rem 0 0;
    position: relative;

    span {
      background: linear-gradient(-45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50%/75% 75% no-repeat, linear-gradient(45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50%/75% 75% no-repeat;
      height: 0.6rem;
      position: absolute;
      right: 0;
      top: 0.55rem;
      width: 0.6rem;

      @include desktop {
        height: 0.75rem;
        top: 0.45rem;
        width: 0.75rem;
      }
    }
  }

  .filter-quick-links > div {
    margin-bottom: 1rem;

    @include desktop {
      margin-bottom: 0.5rem;
    }
  }

  hr {
    border-top: 0;
    margin: 1.5rem 0;
  }

  .filter-active {
    border-left: solid 2px $color-primary;
    padding-left: 0.5rem;
  }

  input[name='panel'] {
    display: none;
  }

  label.filter-panel {
    cursor: pointer;
    display: block;
    position: relative;

    &.filter-disabled span {
      opacity: 0.4;
    }

    &::after {
      @include tick($direction: bottom, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);

      content: '';
      height: 0.5em;
      position: absolute;
      right: 0.25rem;
      top: 0.3rem;
      transition: transform $speed-fast;
      width: 0.5em;
    }
  }

  input[name='panel']:checked ~ div > label.filter-panel {
    &.filter-active {
      border-left: 0;
      padding-left: 0;
    }

    &::after {
      @include tick($direction: top, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);

      content: '';
      margin-top: 0.25rem;
    }
  }

  input[name='panel']:checked + label.show-all-panel {
    display: none;
  }

  .show-all-panel:disabled {
    pointer-events: none;
  }

  .accordion__content,
  .show-all-container {
    height: 0;
    overflow: hidden;
  }

  .accordion__body {
    padding-bottom: 1rem;
  }

  input[name='panel']:checked ~ .accordion__content,
  input[name='panel']:checked ~ .show-all-container {
    height: auto;
  }

  label.filter-panel.filter-disabled ~ .accordion__content {
    color: $color-grey;
  }

  .filter-search-box {
    margin-top: 24px;
    padding-bottom: 2rem;
  }

  .hierarchy-level {
    margin-bottom: 16px;
  }

  .filter-toggle-visibility {
    border-bottom: 0;
    border-left: 0;
    border-right: 2px solid $color-border-default;
    border-top: 2px solid $color-border-default;
    cursor: pointer;
    float: right;
    height: 0.75em;
    margin-right: 0.2em;
    transform: rotate(135deg);
    transform-origin: center;
    width: 0.75em;
  }

  li .form-checkbox {
    padding-bottom: 0.75rem;
  }

  .filter-star-filled {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxwYXRoIGZpbGw9IiMzODJENTUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMzgyRDU1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik02IC44NTdsMS42NTYgMy4zODYgMy43MDEuNTQzTDguNjggNy40MmwuNjMyIDMuNzIyTDYgOS4zODZsLTMuMzEgMS43NTcuNjMxLTMuNzIyTC42NDMgNC43ODZsMy43MDEtLjU0M3oiLz4KPC9zdmc+Cg==') center center no-repeat;
    display: inline-block;
    height: 12px;
    margin-right: 0.3rem;
    width: 12px;
  }

  .filter-star-empty {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMzgyRDU1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik02IC44NTdsMS42NTYgMy4zODYgMy43MDEuNTQzTDguNjggNy40MmwuNjMyIDMuNzIyTDYgOS4zODZsLTMuMzEgMS43NTcuNjMxLTMuNzIyTC42NDMgNC43ODZsMy43MDEtLjU0M3oiLz4KPC9zdmc+Cg==') center center no-repeat;
    display: inline-block;
    height: 12px;
    margin-right: 0.3rem;
    width: 12px;
  }

  .moreless-link {
    color: $color-grey;
  }
}

// Overwrites from Algolia's InstantSearch
.ais-RangeInput-form {
  align-items: center;
  display: flex;
  padding-top: 0.75rem;

  label {
    flex-basis: 5rem;
    text-align: center;
  }

  > *:not(:first-child) {
    padding-left: 1rem;
  }
}

.ais-RangeInput-submit.ais-RangeInput-submit {
  margin: 0;
}

.ais-CurrentRefinements {
  display: inline-block;

  > ul {
    @include desktop {
      margin-top: 0;
    }
  }
}

.ais-ClearRefinements {
  display: inline-block;
}
