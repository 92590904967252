.tabs {
  display: flex;
  flex-wrap: wrap;

  .tab__content {
    background: $color-background-grey;
    display: none;
    order: 99;
    padding: $space-1x 1rem $space-2x;
    width: 100%;
  }

  .tab__label {
    cursor: pointer;
    padding: 1rem;
  }

  .tab__input {
    display: none;

    &:checked + .tab__label {
      background: $color-background-grey;

      + .tab__content {
        display: block;
      }
    }
  }

  &.has-no-tabs-visible {
    .tab__label {
      display: none;
    }
  }
}
