.component--order-actions {
  @include touch {
    .component--order-actions__links-container {
      background: $color-lighter;
      margin: -1rem 0 0;
    }

    .component--order-actions__link {
      align-items: center;
      border-bottom: solid 0.5px $color-grey;
      display: flex;
      justify-content: space-between;
      margin: 0 1rem;
      padding: 1.5rem 0.2rem 1.5rem 0;

      &:first-child {
        border-top: solid 0.5px $color-grey;
      }

      &::after {
        @include tick($direction: right, $color: $color-tetrad, $size: 0.6rem, $tickness: 1px);
      }

      a {
        @include font-h3();

        display: block;
        flex-grow: 1;
        text-decoration: none;
      }

      &.component--order-actions__more {
        border: 0;
        display: block;
        margin: 0;
        padding: 0;
      }

      &.component--order-actions__more::after {
        content: none;
      }
    }

    .component--order-actions__more-links {
      display: block !important;

      .component--order-actions__link {
        border-top: 0;
      }
    }
  }

  @include desktop {
    .component--order-actions__links-container {
      display: flex;
      justify-content: flex-end;
    }

    .component--order-actions__link {
      height: 1rem;
      line-height: 1rem;
      padding: 0 1rem;

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }

      a {
        @include font-family-title();

        color: $color-primary;
        cursor: pointer;
        font-size: 0.875rem;
        text-decoration: none;
      }

      a:hover {
        color: $color-text-primary-hover;
      }
    }

    .component--order-actions__more {
      position: relative;
    }

    .component--order-actions__more-links {
      background: $color-white;
      border: solid 1px $color-border-light;
      padding: 1.5rem 1rem 0.5rem;
      position: absolute;
      right: 0;
      top: 2.25rem;
    }

    .component--order-actions__more-links .component--order-actions__link {
      height: auto;
      margin: 0;
      padding: 0;
      white-space: nowrap;

      a.order-actions__link {
        @include font-regular-body-copy;

        display: block;
        margin-bottom: 1.5rem;
        text-transform: none;
      }
    }

    .component--order-actions__more-dots {
      cursor: pointer;

      span {
        background: $color-primary;
        border-radius: 50%;
        display: block;
        height: 4px;
        width: 4px;
      }

      span + span {
        margin-top: 4px;
      }
    }
  }
}

.order-action-confirmation__heading {
  @include font-personal-titles();

  margin-bottom: 2rem;
}

.dialog__order-action-popup-step {
  .popup-header-font {
    @include font-personal-titles();
  }

  .list-exist-error_container {
    .fragment--notification {
      margin: 1rem 0 0;

      @include mobile {
        margin: 0.5rem 0 0;
        width: 100%;
      }
    }
  }
}
