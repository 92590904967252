.page--password-reset {
  .password-reset__description {
    padding: 2rem 0;
  }

  .regex-has-pass {
    color: $color-notification-success;
  }

  .is-help {
    display: none;
  }

  input[type='password']:focus,
  input[type='text']:focus {
    ~ .form-notifications .is-help {
      display: block;
    }

    ~ .form-notifications .is-help span:not(:last-child)::after {
      content: ', ';
    }
  }

  .password-reset__new-password {
    padding-bottom: 1rem;
  }

  .is-invalid {
    color: $color-notification-error;
    font-size: 0.85rem;
  }

  .link-expired-title {
    @include font-personal-titles();
  }
}
