.fragment--hr-with-markings {
  img {
    width: 100%;
  }

  p {
    @include font-h4();
    @include text-uppercase();

    padding: 0 $space-1x;
    text-align: center;
  }

  a {
    color: $color-primary;
    text-decoration: none;
  }
}
