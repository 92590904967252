/* stylelint-disable no-descending-specificity */
$color-not-known: grey;

label {
  position: relative;
}

.label-container {
  @include font-weight-bold();

  padding-bottom: 1em;
  padding-top: 0.5rem;
}

.has-absolute-content {
  position: relative;
}

/* Safari */
input[type='search'] {
  -webkit-appearance: none;
}

.select-container,
textarea,
input[type='date'],
input[type='email'],
input[type='file'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'] {
  -webkit-appearance: none;
  border: 1px solid $color-light;
  border-radius: 0;
  box-shadow: none;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6667rem;
  width: 100%;

  &:focus {
    border-color: $color-tetrad;
    outline: none;
  }

  &.is-readonly,
  &:disabled {
    opacity: 0.4;

    ~ .form-notifications {
      opacity: 0.4;
    }
  }

  &.is-small {
    font-size: 0.85rem;
    height: 2.5rem;
    padding: 0.44rem;

    @include desktop {
      font-size: 0.75rem;
    }
  }
}

input[type='file'] {
  padding-right: 3rem;
}

textarea {
  height: auto;
}

select,
select:-moz-ui-invalid {
  box-shadow: none;
}

.form-select {
  .select-container {
    position: relative;

    &.has-no-border {
      border: 0;
    }

    &::after {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTAgNiI+CiAgICA8cGF0aCBmaWxsPSIjMzgyRDU1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDZhLjM0LjM0IDAgMCAwIC4yMy0uMTAzTDkuOSAxLjA5M2EuMzYuMzYgMCAwIDAgLjEtLjIzNy4zNi4zNiAwIDAgMC0uMS0uMjM3TDkuMzk5LjEwM0EuMzQuMzQgMCAwIDAgOS4xNjkgMGEuMzQuMzQgMCAwIDAtLjIzMS4xMDNMNSA0LjE1NSAxLjA2Mi4xMDNBLjM0LjM0IDAgMCAwIC44MzIgMGMtLjA5IDAtLjE3LjA0MS0uMjMuMTAzTC4xLjYyYS4zNi4zNiAwIDAgMC0uMS4yMzdjMCAuMDgyLjA0LjE3NS4xLjIzN2w0LjY3IDQuODA0QS4zNC4zNCAwIDAgMCA1IDZ6Ii8+Cjwvc3ZnPgo=) no-repeat center center;
      content: '';
      display: inline-block;
      height: 6px;
      pointer-events: none;
      position: absolute;
      right: 1rem;
      top: 50%;
      width: 10px;

      @include desktop {
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgMTIgNyI+CiAgICA8cGF0aCBmaWxsPSIjMzgyRDU1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDdhLjQxNC40MTQgMCAwIDAgLjI3Ny0uMTJsNS42MDMtNS42MDVBLjQxNC40MTQgMCAwIDAgMTIgLjk5OGEuNDE0LjQxNCAwIDAgMC0uMTItLjI3NkwxMS4yNzkuMTJhLjQxNC40MTQgMCAwIDAtLjI3Ny0uMTIuNDE0LjQxNCAwIDAgMC0uMjc3LjEyTDYgNC44NDcgMS4yNzUuMTJBLjQxNC40MTQgMCAwIDAgLjk5OCAwYS4zOTEuMzkxIDAgMCAwLS4yNzcuMTJsLS42LjYwMkEuNDE0LjQxNCAwIDAgMCAwIC45OThjMCAuMDk3LjA0OC4yMDUuMTIuMjc3TDUuNzIzIDYuODhjLjA3My4wNzIuMTguMTIuMjc3LjEyeiIvPgo8L3N2Zz4K) no-repeat center center;
        height: 7px;
        width: 12px;
      }
    }
  }

  select {
    -ms-appearance: none;
    color: $color-text-black;
    appearance: none;
    background: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-size: 1rem;
    padding-right: 1rem;
    position: relative;
    width: 100%;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: 0;
    }

    &:invalid {
      color: $color-grey;
    }

    [selected] {
      font-weight: bold;
    }

    > option:disabled {
      background-color: $color-not-known;
      color: $color-not-known;
    }

    &.has-white-bg {
      background-color: $color-white;
    }
  }

  label > span {
    display: block;
  }
}

select:invalid {
  color: red;
}

input[type='file'] ~ span:first-of-type::before {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIHN0cm9rZS13aWR0aD0iMS4zIiBkPSJNMTAuNjkxIDQuOTVMNyAuODQ3IDMuMzA5IDQuOTQ5aDIuNDZ2NC45MjJIOC4yM1Y0Ljk1eiIvPgogICAgICAgIDxwYXRoIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTMuMTUyIDkuMDV2My4yODJhLjgyLjgyIDAgMCAxLS44Mi44MkgxLjY2OGEuODIuODIgMCAwIDEtLjgyLS44MlY5LjA1MSIvPgogICAgPC9nPgo8L3N2Zz4K') no-repeat center center;
  content: '';
  display: inline-block;
  height: 1em;
  position: absolute;
  right: 1.2em;
  top: 1.2em;
  width: 1em;
  z-index: -1;

  @include desktop {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRjAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxwYXRoIHN0cm9rZS13aWR0aD0iMS4zIiBkPSJNMTIuMjE5IDUuNjU2TDggLjk3IDMuNzgxIDUuNjU2aDIuODEzdjUuNjI1aDIuODEyVjUuNjU2eiIvPgogICAgICAgIDxwYXRoIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTUuMDMxIDEwLjM0NHYzLjc1YzAgLjUxNy0uNDIuOTM3LS45MzcuOTM3SDEuOTA2YS45MzguOTM4IDAgMCAxLS45MzctLjkzN3YtMy43NSIvPgogICAgPC9nPgo8L3N2Zz4K') no-repeat center center;
  }
}

/* IE10+ */
input[type='search']::-ms-clear {
  display: none;
}

/* Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// Checkboxes
.form-checkbox {
  margin-top: 0.5rem;

  @include desktop {
    margin-top: 0;
  }

  label {
    display: inline-block;

    > span {
      padding-left: 1.5rem;
      position: relative;
    }
  }

  .has-float-label {
    > span {
      top: 0;
    }

    input[type='checkbox'].has-error ~ span {
      color: $color-grey;

      &:first-of-type::before {
        background-color: $color-notification-error-tint;
        border-color: $color-notification-error;
      }
    }
  }

  input[type='checkbox'] {
    display: none;

    ~ span:first-of-type::before {
      background: none;
      border: 1px solid $color-border-check;
      content: '';
      height: 1rem;
      left: 0;
      position: absolute;
      top: 0;
      width: 1rem;
    }

    &:checked ~ span:first-of-type::before {
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTAgOCI+CiAgICA8cGF0aCBmaWxsPSIjMzgyRDU1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuMyIgZD0iTTkuNjI1IDEuODY3YS41NzYuNTc2IDAgMCAwLS4xNjMtLjRsLS43OS0uODAyYS41NTkuNTU5IDAgMCAwLS43ODkgMEw0LjA3NCA0LjUzNiAyLjM2NyAyLjc5OGEuNTU5LjU1OSAwIDAgMC0uNzkgMGwtLjc5LjgwMWEuNTc2LjU3NiAwIDAgMCAwIC44MDJMMy42OCA3LjMzNWEuNTU5LjU1OSAwIDAgMCAuNzkgMGw0Ljk5My01LjA2N2EuNTc2LjU3NiAwIDAgMCAuMTYzLS40MDF6Ii8+Cjwvc3ZnPgo=') no-repeat center center;

      @include desktop {
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEyIDEwIj4KICAgIDxwYXRoIGZpbGw9IiMzODJENTUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9Ii4zIiBkPSJNMTEgMi41NjJhLjY2OC42NjggMCAwIDAtLjE4LS40NThsLS44NzgtLjkxNUEuNjEyLjYxMiAwIDAgMCA5LjUwMyAxYS42MTIuNjEyIDAgMCAwLS40MzguMTg5TDQuODMyIDUuNjEzIDIuOTM1IDMuNjI2YS42MTIuNjEyIDAgMCAwLS40MzgtLjE4OC42MTIuNjEyIDAgMCAwLS40MzkuMTg4bC0uODc3LjkxNmEuNjY4LjY2OCAwIDAgMCAwIC45MTZMNC4zOTQgOC44MUEuNjEyLjYxMiAwIDAgMCA0LjgzMiA5YS42MTIuNjEyIDAgMCAwIC40MzktLjE4OWw1LjU0OC01Ljc5QS42NjguNjY4IDAgMCAwIDExIDIuNTYxeiIvPgo8L3N2Zz4K') no-repeat center center;
      }
    }

    &:disabled ~ span:first-of-type {
      opacity: 0.4;
    }
  }
}

p.invalid-file-size {
  color: $color-notification-error-text;
}

label input[type='radio']:required,
label input[type='checkbox']:required {
  cursor: pointer;
  display: block;
  opacity: 0;
  position: absolute;
}
// Radio
.form-radio {
  input.radiogroup[type='radio'] {
    display: inline-block;
  }

  label {
    display: flex;
    flex-wrap: wrap;

    span {
      line-height: 1.4rem;
      margin: 5px 0;
      padding-left: 2rem;
      position: relative;
    }
  }
}

// Input
.ie input,
.ie select {
  &:required:invalid {
    outline: none;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px $color-white inset;
  -webkit-text-fill-color: $color-tetrad !important;
}

// Textarea
.form-textarea {
  textarea {
    width: 100%;
  }

  label > span {
    display: block;
  }
}

// Global
// @todo Check when it's needed (we dont need it inside a form-row with 2 input ...
.form-row + .form-row {
  margin-top: 1rem;
}

.form-row,
.form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  h4 {
    flex-basis: 100%;
  }
}

.form-row {
  align-items: flex-start;

  > [class^='form-'] {
    flex-basis: 100%;

    //@todo see previous comment about margin-top
    + [class^='form-'] {
      @include desktop {
        margin-top: 0 !important;
      }
    }

    @include desktop {
      &:first-child:not(:last-child),
      &:last-child:not(:first-child) {
        flex-basis: calc(50% - 0.5rem);
      }
    }

    @include touch {
      flex-basis: 100%;
    }
  }
}

.form-group {
  align-items: start;

  > [class*='form-'] {
    margin: 0 !important;

    @include desktop {
      flex-basis: calc(50% - 1rem);
    }

    @include touch {
      flex-basis: calc(50% - 0.5rem);
    }
  }
}

.form-notifications {
  .is-valid,
  .is-invalid {
    display: none;
    padding: 0.4rem 0 0.5rem 0;
  }

  .is-help {
    @include font-small-body-copy();

    color: $color-grey;
    padding: 0.4rem 0 0.5rem 0;
  }
}

// .form-addon {

// }

.has-float-label {
  display: block;
  position: relative;

  > span {
    background-color: $color-white;
    color: $color-grey;
    cursor: text;
    font-size: 0.6667rem;
    left: 0.6667rem;
    line-height: 1.2em;
    opacity: 1;
    padding-left: 0.5em;
    padding-right: 0.5em;
    position: absolute;
    top: -0.5em;
    // transition: all $speed-slow;
    // white-space: nowrap;

    [dir='rtl'] & {
      right: 0.6667rem;
    }
  }

  //input[type="radio"] ~ span,
  input[type='checkbox'] ~ span {
    @include font-small-body-copy();

    cursor: pointer;
    left: auto;

    &.text-grey *,
    .text-grey * {
      color: $color-grey;
    }
  }

  input[type='password'].password-item,
  input[type='text'].password-item {
    padding-right: 2.5rem;

    ~ .password-reveal-icon {
      content: '';
      height: 16px;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 17px;
    }
  }

  input[type='password'].password-item ~ .password-reveal-icon {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE1IDE0Ij4KICAgIDxwYXRoIGZpbGw9IiM5QTk5OTkiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE0Ljg5MSA3LjQyMUMxNC43NTYgNy4yNDEgMTEuNTEzIDMgNy41IDMgMy40NjYgMyAuMjQzIDcuMjQyLjEwOCA3LjQyM2EuNTUxLjU1MSAwIDAgMCAwIC42NTZDLjI0NSA4LjI1OSAzLjQ4OCAxMi41IDcuNSAxMi41YzMuOTkzIDAgNy4yNTQtNC4yNCA3LjM5LTQuNDJhLjU1LjU1IDAgMCAwIC4wMDEtLjY1OXpNNi41IDUuMTExYy41NTIgMCAxIC40NzIgMSAxLjA1NiAwIC41ODMtLjQ0OCAxLjA1NS0xIDEuMDU1LS41NTMgMC0xLS40NzItMS0xLjA1NSAwLS41ODQuNDQ3LTEuMDU2IDEtMS4wNTZ6bTEgNi4zMzNjLTIuOTI0IDAtNS41Mi0yLjczNS02LjM0LTMuNjk1YTEzLjk2NCAxMy45NjQgMCAwIDEgMi4zOTMtMi4yMmMtLjAzMS4yMDktLjA1My40Mi0uMDUzLjYzOCAwIDIuMzI4IDEuNzk0IDQuMjIyIDQgNC4yMjJzNC0xLjg5NCA0LTQuMjIyYzAtLjIxNC0uMDE3LS40MjMtLjA0Ny0uNjI4YTE0LjA4OCAxNC4wODggMCAwIDEgMi4zODYgMi4yMWMtLjgyNC45NjItMy40MjUgMy42OTUtNi4zNCAzLjY5NXoiLz4KPC9zdmc+Cg==) center center no-repeat;
  }

  input[type='text'].password-item ~ .password-reveal-icon {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE3IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgPHBhdGggZmlsbD0iIzlBOTk5OSIgZD0iTTMuNDQ0IDEyLjM5OGwuODIzLS44NmMtMS4zODgtLjk0LTIuNDU3LTIuMDc3LTIuOTUyLTIuNjVhMTUuNzY3IDE1Ljc2NyAwIDAgMSAyLjcxMy0yLjQ5MyA0LjY1OCA0LjY1OCAwIDAgMC0uMDYxLjcxNmMwIDEuMzA3LjUwOCAyLjQ5MyAxLjMzIDMuMzVsNi4wMzQtNi4zMWMtLjg4NC0uMzU5LTEuODM1LS41OTUtMi44MzEtLjU5NUMzLjkyOSAzLjU1Ni4yNzUgOC4zMTkuMTIyIDguNTJhLjYxNC42MTQgMCAwIDAgLjAwMi43MzdjLjA5LjExNyAxLjM3MiAxLjc3MyAzLjMyIDMuMTR6TTE2Ljg3NiA4LjUyYy0uMDk4LS4xMy0xLjY0Mi0yLjEyNC0zLjkzLTMuNTQ1bC02LjIxNSA2LjVhNC4zNDEgNC4zNDEgMCAwIDAgMS43NjkuMzc3YzIuNSAwIDQuNTMzLTIuMTI3IDQuNTMzLTQuNzQgMC0uMjQtLjAyLS40NzYtLjA1My0uNzA1YTE1LjkwNiAxNS45MDYgMCAwIDEgMi43MDQgMi40ODFjLS45MzQgMS4wOC0zLjg4MiA0LjE0OS03LjE4NCA0LjE0OS0uOTMyIDAtMS44MzItLjI0OC0yLjY2OS0uNjIxbC0uODU4Ljg5N2MxLjA3Mi41MzUgMi4yNjMuOTEgMy41MjcuOTEgNC41MjUgMCA4LjIyLTQuNzYxIDguMzc1LTQuOTY0YS42MTQuNjE0IDAgMCAwIC4wMDEtLjc0eiIvPgogICAgICAgIDxwYXRoIGZpbGw9IiM5QjlCOUIiIGQ9Ik0uNTY3IDE2YS41NTMuNTUzIDAgMCAxLS40MDEtLjE3NC42MTEuNjExIDAgMCAxIDAtLjgzOEwxNC4zMzMuMTc0YS41NDkuNTQ5IDAgMCAxIC44MDEgMCAuNjExLjYxMSAwIDAgMSAwIC44MzhMLjk2NyAxNS44MjZhLjU1My41NTMgMCAwIDEtLjQuMTc0eiIvPgogICAgPC9nPgo8L3N2Zz4K) center center no-repeat;
  }

  .select-container,
  textarea,
  input:not([type='hidden']) {
    &::placeholder {
      color: $color-grey;
      font-size: 1em;
      opacity: 1;
      // transition: all $speed-slow;
    }

    @mixin placeholder-shown-not-focused {
      &::placeholder {
        color: $color-light;
        // opacity: 0;
      }

      + * {
        background-color: transparent;
        font-size: 1em;
        top: 0.85em;
      }
    }

    &:not(:-webkit-autofill).placeholder-shown:not(:focus) {
      @include placeholder-shown-not-focused;
    }

    &:placeholder-shown:not(:focus) {
      @include placeholder-shown-not-focused;
    }

    &:invalid,
    &:-moz-ui-invalid {
      box-shadow: none;
    }

    &.has-error,
    &[pattern]:invalid:not(:focus):not(.placeholder-shown) {
      @include placeholder-shown-invalid;
    }

    &[pattern]:invalid:not(:placeholder-shown) {
      @include placeholder-shown-invalid;
    }

    &[pattern]:valid:not(:focus):not(.placeholder-shown) ~ .form-notifications .is-valid {
      display: block;
    }

    &[pattern]:valid:not(:focus):not(:placeholder-shown) ~ .form-notifications .is-valid {
      display: block;
    }
  }

  .select-container,
  textarea,
  input[type='date'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'] {
    &:optional {
      ~ label[data-optional-label]::after,
      ~ span[data-optional-label]::after {
        content: ' (' attr(data-optional-label) ')';
      }
    }
  }

  textarea {
    margin-bottom: -0.4rem;
  }

  .form-notifications {
    flex-basis: 100%;
  }
}

.form-actions {
  margin: 2rem 0;

  aside {
    display: block;
    margin-top: 0.5rem;
    width: 100%;

    a {
      color: $color-grey;
    }
  }
}

// Fix select element and its container
.select-container {
  background-color: $color-white;
  height: 3rem;
  min-width: 4rem;
  position: relative;

  .is-small {
    font-size: 0.85rem;
    height: 2.5rem;

    @include desktop {
      font-size: 0.75rem;
    }
  }

  > select {
    bottom: 0;
    height: 100%;
    left: 0;
    padding: 0.6667rem 1.5rem 0.6667rem 0.6667rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  .has-float-label & {
    > select {
      padding-top: 0.75rem;
    }
  }
}

input {
  border: 1px solid $color-grey;

  &[type='radio'] {
    display: none;

    + label span {
      padding-left: $space-1x;
      position: relative;

      &:first-of-type::before {
        background: none;
        border: 1px solid $color-border-check;
        border-radius: 50%;
        content: '';
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
      }

      &:first-of-type::after {
        background: $color-tetrad;
        border-radius: 50%;
        content: '';
        display: none;
        height: 8px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 8px;
      }
    }

    @include touch {
      + label span:first-of-type::before {
        height: 14px;
        width: 14px;
      }

      + label span:first-of-type::after {
        display: none;
        height: 6px;
        width: 6px;
      }
    }

    &:checked + label span::after {
      display: block;
    }
  }
}

/* Toogle Switch */
.can-toggle {
  display: flex;

  .can-toggle__switch {
    background: $color-tetrad;
    border-radius: 2.5rem;
    cursor: pointer;
    height: 1.5rem;
    position: relative;
    width: 2.5rem;

    &::after {
      background: $color-white;
      border-radius: 50%;
      content: '';
      height: 1rem;
      left: 4px;
      position: absolute;
      top: 4px;
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
      width: 1rem;
      z-index: 5;
    }
  }

  .can-toggle__label {
    order: -2;
    padding: 0 0.5rem;
  }

  .can-toggle__left-text {
    order: -3;
  }

  .can-toggle__right-text {
    color: $color-text-dimmed;
    order: -1;
  }

  input[type='checkbox']:checked {
    ~ label .can-toggle__switch::after {
      transform: translate3d(1rem, 0, 0);
    }

    ~ .can-toggle__right-text {
      color: $color-text-default;
    }

    ~ .can-toggle__left-text {
      color: $color-text-dimmed;
    }
  }
}

input[step='0.01'] {
  appearance: textfield;
}

/* File Input */
.file-input {
  position: relative;
  margin: 0 0 1.5rem;

  .upload-file-button {
    position: relative;
    min-width: 200px;
    height: auto;
    min-height: 3rem;
    background-color: $color-complementary;
    color: $color-white;
    border: 0;
    margin: 0;
    cursor: pointer;

    &:not([disabled]):hover,
    &:not([disabled]):focus,
    &[disabled] {
      background-color: $color-complementary;
      border: 0;
    }

    &[disabled] {
      opacity: 0.6;
    }
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
      cursor: pointer !important;
    }
  }

  .file {
    background-color: $color-lighter;
    padding: 0.5rem 1.5rem;
    position: relative;
    overflow: hidden;
  }

  .file-name {
    @include font-family-default();

    color: $color-tetrad;
    font-weight: 500;
    font-size: 1rem;
  }

  .file-remove {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    width: 16px;
    height: 16px;
    min-width: auto;
    max-width: none;
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 0.5rem;
    cursor: pointer;

    svg {
      vertical-align: top;
    }
  }
}

/* stylelint-enable no-descending-specificity */
