
.cart-header-container,
.fragment--cart-totals {
  .cart-totals__submit-button-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include desktop {
      align-items: center;
      flex-direction: row-reverse;
    }

    button {
      margin-bottom: 1.5rem;

      @include desktop {
        margin: 0;
        width: 20.6%;
      }
    }
  }
}

.fragment--cart-totals {
  margin: 0 0 $space-1x;
  padding-top: 1rem;

  @include fancy-border($positions: top);

  .cart-totals__reservation-label {
    @include font-small-body-copy;

    @include desktop {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .cart-totals__reservation-options {
    display: flex;
    padding-top: 0.25rem;

    @include desktop {
      padding-left: 1rem;
      padding-top: 0;
    }

    > div:last-child {
      padding-left: 1rem;
    }
  }

  .cart-totals__reservation-list {
    padding-left: 0;
  }

  .cart-totals__reservation-list li {
    color: $color-notification-info-text;
    padding-left: 1.25rem;
    position: relative;

    &::before {
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEyIDEwIj4gICAgPHBhdGggZmlsbD0iIzAwOTFEMiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iLjMiIGQ9Ik0xMSAyLjU2MmEuNjY4LjY2OCAwIDAgMC0uMTgtLjQ1OGwtLjg3OC0uOTE1QS42MTIuNjEyIDAgMCAwIDkuNTAzIDFhLjYxMi42MTIgMCAwIDAtLjQzOC4xODlMNC44MzIgNS42MTMgMi45MzUgMy42MjZhLjYxMi42MTIgMCAwIDAtLjQzOC0uMTg4LjYxMi42MTIgMCAwIDAtLjQzOS4xODhsLS44NzcuOTE2YS42NjguNjY4IDAgMCAwIDAgLjkxNkw0LjM5NCA4LjgxQS42MTIuNjEyIDAgMCAwIDQuODMyIDlhLjYxMi42MTIgMCAwIDAgLjQzOS0uMTg5bDUuNTQ4LTUuNzlBLjY2OC42NjggMCAwIDAgMTEgMi41NjF6Ii8+PC9zdmc+') no-repeat;
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      top: 5px;
      transform: none;
      width: 12px;
    }
  }

  .cart-totals__price-list-subtotal {
    padding: 1rem 0;

    li {
      padding: 0.2rem 0;
    }
  }

  .cart-totals__price-list {
    > li > p {
      color: $color-text-default;
    }

    > li p + p {
      @include touch {
        padding-right: 1.5rem;
      }
    }

    > li:first-child {
      display: block;
    }

    > li:not(.has-grey-background) {
      border-top: solid 1px $color-border-light;
      padding: 0.5rem 0;

      &:first-child {
        border: 0;
      }

      @include desktop {
        padding: 0.5rem 1rem;
      }
    }

    input[name='panel'] {
      display: none;
    }

    label {
      cursor: pointer;
      display: flex;
      position: relative;
    }

    label::after {
      @include tick($direction: bottom, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);

      content: '';
      height: 0.5em;
      position: absolute;
      right: -1rem;
      top: 0.4rem;
      transition: transform $speed-fast;
      width: 0.5em;

      @include touch {
        right: 0.2rem;
      }
    }

    input[name='panel']:checked + label {
      &::after {
        @include tick($direction: top, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);

        content: '';
        margin-top: 0.25rem;
      }
    }

    .accordion__content {
      height: 0;
      overflow: hidden;
    }

    input[name='panel']:checked ~ .accordion__content {
      height: auto;
    }
  }

  .has-grey-background {
    background-color: $color-lighter;
    margin: 0 -1rem;
    padding: 1rem;

    @include desktop {
      margin: 0;
    }
  }

  .has-border {
    margin-top: 1rem;
    position: relative;

    &::after {
      background: $color-light;
      bottom: 0;
      content: '';
      height: 1px;
      position: absolute;
      width: calc(100% - 2rem);
      z-index: 1;
    }
  }

  .cart-totals__reservation-label span {
    cursor: pointer;
  }

  .cart-totals__totaal {
    @include font-h2();

    @include text-uppercase();
  }
}
