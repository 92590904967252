.component--target-destination {
  dl {
    margin: 0;
  }

  address {
    margin-bottom: 0.5rem;
  }

  button {
    @include text-uppercase;
  }

  address,
  dt {
    @include font-regular-body-copy();

    font-style: normal;
  }

  .dialog__inner-wrapper {
    height: 100%;

    @include touch {
      padding: 6rem 1rem;
    }
  }

  .target-destination__close-and-back-button-container {
    width: 100%;
  }

  .is-going-back {
    position: inherit;
  }

  .toggle--target-destination.is-toggle-label-text {
    color: $color-text-dimmed;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .target-destination__list-heading {
    @include font-h3();
    @include text-uppercase;

    margin: 1rem 0;

    @include touch {
      width: 100%;
    }
  }

  .target-destination__list-subheading {
    color: $color-grey;
    margin: 1rem 0;
    width: 100%;

    @include touch {
      width: 100%;
    }
  }

  .target-destination__list-back-button-container {
    @include touch {
      width: 100%;
    }
  }

  .target-destination__list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .dialog__close-icon {
      @include touch {
        left: 1rem;
      }
    }
  }

  .toggle--target-destination.is-toggle-label {
    cursor: pointer;
    display: flex;

    @include desktop {
      color: $color-text-dimmed;
    }

    @include touch {
      color: $color-tetrad;
      display: block;

      &::after {
        float: right;
        margin-right: 0.5rem;
        margin-top: 0.5em;
      }
    }
  }

  .target-destination__backdrop {
    @include desktop {
      background: rgba(255, 255, 255, 0.8);
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
    }
  }

  .target-destination__list {
    background-color: $color-background-white;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;

    @include desktop {
      left: 20%;
      padding: 2.8125rem 2.1875rem;
      position: fixed;
      right: 20%;
    }

    @include touch {
      padding: 7.1875rem 3.0625rem;
    }
  }

  .target-destination__destination {
    border: 1px solid $color-light;
    margin: 1rem 0;
    padding: 2rem;
    width: 48%;

    &:hover {
      border-bottom: 4px solid $color-tetrad;
      border-left: 1px solid $color-tetrad;
      border-right: 1px solid $color-tetrad;
      border-top: 1px solid $color-tetrad;
    }

    @include touch {
      flex-direction: column;
      margin: 1rem 0;
      width: 66%;
    }
  }

  .target-destination__destination.is-selected {
    border: 1px solid $color-tetrad;
    position: relative;

    &::after {
      border-top: 4px solid $color-tetrad;
      bottom: 20%;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .target-destination__destination-comments {
    @include font-small-body-copy();

    color: $color-grey;
    margin-bottom: 0.5rem;
  }

  .target-destination__destination-title {
    @include font-weight-bold;
  }
}

.component--target-destination-notification {
  .target-desination__notification {
    align-items: center;
    background: $color-background-light-red;
    color: $color-primary;
    padding: 0.5rem 0;
  }

  .target-desination__notification-link-text {
    color: $color-primary;
    cursor: pointer;
    line-height: inherit;
    margin-right: auto;
    text-decoration: underline;
  }

  .target-desination__notification-is-close {
    cursor: pointer;
  }
}

.toggle--hamburger.is-toggle-trigger:not(:checked) ~ .container--header .target-desination__notification {
  display: flex;
}

.toggle--hamburger.is-toggle-trigger:checked ~ .container--header .target-desination__notification {
  display: none;
}

.toggle--target-destination.is-toggle-trigger:checked ~ .container--header {
  .toggle--target-destination.is-toggle-label:not(.is-closing)::after {
    color: $color-tetrad;
    margin-left: 0.5rem;
    position: relative;
    right: -0.25rem;
    top: -0.2rem;
    transition: transform $speed-fast;
  }

  .toggle--target-destination.is-toggle-handler {
    @include touch {
      transform: translateX(0);
      transition: transform $speed ease-in-out;
    }
  }

  .is-closing {
    background:
      linear-gradient(-45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat,
      linear-gradient(45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat;
    color: $color-grey;
    height: 1.5rem;
    right: 1rem;
    text-indent: -420rem;
    width: 1.6rem;

    @include touch {
      position: fixed;
      top: 2rem;
    }

    @include desktop {
      left: -8rem;
      position: absolute;
    }
  }
}

.toggle--target-destination.is-toggle-trigger:not(:checked) ~ .container--header {
  .toggle--target-destination.is-toggle-label:not(.is-closing)::after {
    @include desktop {
      @include tick($direction: bottom, $color: $color-text-dimmed, $size: 0.5em, $tickness: 2px);

      margin-left: 0.5rem;
      position: relative;
      top: 0.5rem;
      transition: transform $speed-fast;
    }

    @include touch {
      @include tick($direction: right, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);
    }
  }

  .toggle--target-destination.is-toggle-handler {
    @include touch {
      transform: translateX(100%);
    }
  }
}
