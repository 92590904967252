.fragment--order-overview {
  &.has-border-top {
    @include fancy-border($positions: top);
  }

  .order-overview__title.accordion__title {
    margin-bottom: 0;
  }

  .order-overview__table-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .order-overview__table-head {
    display: none;
  }

  .order-overview__table-cell {
    display: flex;
    padding: 1rem 0;

    &:not(.order-overview__product-name)::before {
      color: $color-text-dimmed;
      content: attr(data-heading);
      width: 50%;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-border-light;
    }

    a {
      color: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      text-decoration: inherit;
    }
  }

  .order-overview__product-name {
    @include font-weight-bold();

    order: -1;
  }

  .order-overview__attr-vol::after {
    content: '\007C';
    padding: 0 5px;
  }

  .order-overview__attributes {
    display: flex;
  }

  @include desktop {
    .order-overview__table {
      display: flex;
      flex-wrap: wrap;
    }

    .order-overview__table-head,
    .order-overview__table-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .order-overview__table-head {
      border-bottom: 1px solid $color-border-default;
      color: $color-text-dimmed;
      padding-bottom: 1rem;
    }

    .order-overview__table-row:not(:last-child) {
      border-bottom: 1px solid $color-border-light;
      margin: 0;
    }

    .order-overview__table-cell {
      border: 0 !important;
      flex-grow: 1;
      overflow: hidden;
      width: 12.5%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }

      &:not(:first-child) {
        padding-left: 0.5rem;
      }

      &::before {
        display: none;
      }
    }

    .order-overview__table-cell--header {
      flex-grow: 1;
      overflow: hidden;
      width: 12.5%;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }

      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }

    .order-overview__quantity,
    .order-overview__quantity-header {
      width: 10%;
    }

    .order-overview__product-name-header {
      width: 27.5%;
    }

    .order-overview__product-name {
      @include font-regular-body-copy();

      order: 0;
      width: 27.5%;
    }

    .order-overview__total,
    .order-overview__total-header {
      justify-content: flex-end;
      text-align: right;
    }
  }
}
