.component--hero-banner {
  // css pattern
  margin-top: 0; // Remove margin on top if component is placed on top of page

  // .hero-banner__container {
  //   background-color: #0f0;
  //   // background-image: linear-gradient(to bottom, black 0, black 10%, white 10%, white 20%, black 20%, black 30%, white 30%, white 40%, black 40%, black 50%, white 50%, white 60%, black 60%, black 70%, white 70%, white 80%, black 80%, black 90%, white 90%, white 100%);
  //   position: relative;
  // }

  .hero-banner__container {
    position: relative;
  }

  .hero-banner__media-container__figure {
    @include background-image-props-for-banners;
  }

  .hero-banner__content-container__text {
    padding: $space-2x 2rem;
  }

  /** Only full image is being used for MVP **/
  // &:not(.is-full-image) {
  //   .hero-banner__container {
  //     overflow: hidden;
  //   }

  //   .hero-banner__media-container__figure-container {
  //     align-items: center;
  //     display: flex;
  //     flex: 0 0 50%;
  //     height: 100%;

  //     @include touch {
  //       position: relative;
  //       transform: translateY(-33.3334%);
  //     }

  //     @include desktop {
  //       max-width: 50%;
  //       position: absolute;
  //     }
  //   }

  //   .hero-banner__media-container__figure {
  //     @include fancy-border($allPositions: true, $width: 1rem);

  //     overflow: hidden;
  //     transform: rotate(45deg) scale(1.1);
  //     transform-origin: center center;

  //     > img {
  //       object-fit: cover;
  //       transform: rotate(-45deg) translateX(0%) scale(1.5);
  //       transform-origin: center center;
  //     }
  //   }

  //   @include touch {
  //     .hero-banner__content-container {
  //       bottom: 25%;
  //       color: $color-white;
  //       position: absolute;
  //       text-align: center;
  //       width: 100%;
  //     }
  //   }

  //   @include desktop {
  //     &.is-text-right .hero-banner__media-container__figure-container {
  //       left: -15%;
  //     }

  //     &:not(.is-text-right) .hero-banner__media-container__figure-container {
  //       right: -15%;
  //     }
  //   }
  // }

  &.is-full-image {
    .hero-banner__content-container {
      @extend %ui-light;

      align-items: center;
      overflow: hidden;
      position: relative;
      z-index: $zindex__component--hero-banner__content-container;

      @include mobile {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    @include tablet {
      .hero-banner__content-container,
      .hero-banner__content-container > .wrapper,
      .hero-banner__content-container > .wrapper > .columns {
        height: 520px;
      }

      .hero-banner__content-container > .wrapper > .columns {
        align-items: center;
      }
    }

    img {
      display: block;

      @include tablet {
        height: 520px;
        max-width: inherit;
      }
    }

    @include touch {
      &.is-text-left .hero-banner__content-container,
      &.is-text-right .hero-banner__content-container {
        text-align: left;
      }

      &.is-text-center .hero-banner__content-container {
        text-align: center;
      }
    }

    .hero-banner__media-container {
      margin-top: -2rem;

      @include touch {
        margin-top: 0;
      }
    }

    .hero-banner__media-container__figure {
      display: flex;
      justify-content: center;
      mix-blend-mode: multiply;
      width: 100%;

      @include tablet {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      @include desktop {
        height: 0;
      }
    }

    &:not(.is-text-right) .hero-banner__container:not(.has-no-overlay) {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgb(32, 42, 79));
    }

    &.is-text-right .hero-banner__container:not(.has-no-overlay) {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgb(32, 42, 79));
    }
  }

  @include desktop {
    &.is-text-right .hero-banner__content-container .column.is-half-desktop {
      margin-left: 50%;
    }

    &.is-text-center .hero-banner__content-container .column.is-half-desktop {
      margin: 0 auto;
      text-align: center;
    }
  }
}
