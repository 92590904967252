// Only show the "tile" presentation and ignore "line" presentation for mobile and tablet
@include desktop {
  .show-as-product-lines .fragment--product-tile {
    // no border style for mobile... That's done in _product-tiles.scss
    background-color: $color-background-white;
    flex-basis: 100%;
    margin-bottom: 0;
    padding: 0 1rem;

    .form-notifications {
      &.is-floating {
        display: none;

        &--extra-space {
          display: none;
        }

        &--list-line {
          display: block;
        }
      }
    }

    .fragment--notification {
      margin: 0;

      &.is-compact {
        padding: 0;
      }
    }

    .toggle--trading.is-toggle-trigger:checked ~ .product-tile__container .toggle--trading.is-toggle-handler .trading-price .has-float-label .form-notifications .is-help {
      white-space: normal;
    }

    .product-tile__favorite {
      align-items: unset;
      all: unset;
      border: 0;
      box-shadow: none;
      cursor: pointer;
      height: auto;
      justify-content: unset;
      order: 0;
      position: static;
      width: auto;

      > svg {
        transform: scale(1.25) translateX(-0.25rem);
      }
    }

    .product-tile__tags {
      left: initial;
      position: initial;
      top: initial;
    }

    .product-tile__container {
      flex-direction: row;
      justify-content: space-between;
      padding: 2em 0;
    }

    .product-tile__image {
      flex-basis: 5rem;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      min-height: auto;
    }

    .product-tile__description {
      width: 10rem;
    }

    .product-tile__category {
      border: 0;
      justify-content: left;
      margin: 0 0 0.5em;
      text-align: left;
    }

    .product-tile__name {
      height: auto;
    }

    .product-tile__details {
      border: 0;
      justify-content: normal;
    }

    .product-tile__details__vertical-line {
      margin: 0 0.25rem;
    }

    .product-tile__price {
      padding-top: 1.6em;
      border-bottom: 0;
      width: 10rem;
    }

    .product-tile__notifications {
      height: auto;
    }

    .product-tile__add-to-cart {
      align-self: center;
      margin-top: 0;
      width: calc(12rem + 8px);

      input[type='number'] {
        width: 4rem;
      }

      .form-group {
        margin: 0.1rem 0;
        min-height: 4rem;
        height: auto;
      }
    }

    .product-tile__request-quote {
      .form-group {
        height: auto;
        min-height: 4rem;
      }
    }

    .product-tile__notifications__cart {
      display: flex;
      justify-content: flex-end;
    }

    .toggle--trading.is-toggle-trigger:not(:checked) ~ .product-tile__container {
      .toggle--trading.is-toggle-handler {
        display: none;
      }

      .product-tile__add-to-cart {
        display: block;
      }
    }

    .toggle--trading.is-toggle-trigger:checked ~ .product-tile__container {
      .toggle--trading.is-toggle-handler {
        align-self: center;
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 24rem;
      }

      .product-tile__add-to-cart {
        display: none;
      }

      .product-tile__notifications__cart.is-trading {
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: 1rem;
      }
    }

    .toggle--trading.is-toggle-trigger:checked ~ .product-tile__container .product-tile__price {
      display: none;
    }

    .toggle--trading.is-toggle-trigger:checked ~ .product-tile__container .toggle--trading.is-toggle-handler > div:nth-child(1) {
      width: 10rem;
    }

    .toggle--trading.is-toggle-trigger:checked ~ .product-tile__container .toggle--trading.is-toggle-handler > div:nth-child(2) {
      flex-wrap: nowrap;
      width: 12rem;

      button {
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .toggle--price-excises.is-toggle-trigger:not(:checked) {
    ~ .toggle--price-excises.is-toggle-label sup::after {
      @include tick($direction: bottom);

      position: relative;
      top: -0.25rem;
    }

    ~ .toggle--price-excises.is-toggle-handler {
      display: none;
    }
  }

  .toggle--price-excises.is-toggle-trigger:checked {
    ~ .toggle--price-excises.is-toggle-label sup::after {
      @include tick($direction: top);

      position: relative;
      top: -0.25rem;
    }

    ~ .toggle--price-excises.is-toggle-handler {
      display: block;
    }
  }

  .toggle--trading.is-toggle-trigger {
    display: none;
  }

  .toggle--trading.is-toggle-label {
    cursor: pointer;
    user-select: none;
  }
}
