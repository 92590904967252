.fragment--place-order-and-toc {
  .place-order-and-toc__agreement-text {
    color: $color-grey;
    margin-bottom: 1.5em;
  }

  .place-order-and-toc__agreement-text > a {
    color: $color-grey;
  }

  .place-order-and-toc__reservation-list li {
    color: $color-notification-info-text;
    padding-left: 1.25rem;
    position: relative;

    &::before {
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEyIDEwIj4gICAgPHBhdGggZmlsbD0iIzAwOTFEMiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iLjMiIGQ9Ik0xMSAyLjU2MmEuNjY4LjY2OCAwIDAgMC0uMTgtLjQ1OGwtLjg3OC0uOTE1QS42MTIuNjEyIDAgMCAwIDkuNTAzIDFhLjYxMi42MTIgMCAwIDAtLjQzOC4xODlMNC44MzIgNS42MTMgMi45MzUgMy42MjZhLjYxMi42MTIgMCAwIDAtLjQzOC0uMTg4LjYxMi42MTIgMCAwIDAtLjQzOS4xODhsLS44NzcuOTE2YS42NjguNjY4IDAgMCAwIDAgLjkxNkw0LjM5NCA4LjgxQS42MTIuNjEyIDAgMCAwIDQuODMyIDlhLjYxMi42MTIgMCAwIDAgLjQzOS0uMTg5bDUuNTQ4LTUuNzlBLjY2OC42NjggMCAwIDAgMTEgMi41NjF6Ii8+PC9zdmc+') no-repeat;
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      top: 5px;
      width: 12px;
    }
  }

  .place-order-and-toc__reservation-info-wrapper {
    background-color: $color-notification-info;
    margin: 1.625rem -1rem;
    padding: 1rem 1.5rem 1rem 2.5rem;
    position: relative;

    @include desktop {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .place-order-and-toc__reservation-info,
  .place-order-and-toc__reservation-list {
    @include font-small-body-copy();
  }

  .place-order-and-toc__reservation-info {
    color: $color-notification-info-text;
    text-indent: -1rem;

    &::before {
      background-color: $color-notification-info-text;
      border-radius: 50%;
      color: $color-notification-info;
      content: 'i';
      display: inline-block;
      height: 1rem;
      left: -0.5rem;
      line-height: 1rem;
      position: relative;
      text-align: center;
      text-indent: 0;
      width: 1rem;
    }
  }
}
