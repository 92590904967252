.fragment--discount {
  .input-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1em;

    @include touch {
      flex-direction: column;

      .button {
        width: 100%;
        max-width: unset;
      }
    }

    label.has-float-label {
      flex-grow: 0.75;
    }

    .button {
      flex-grow: 0.25;
    }
  }
}
