.users-list-header {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    flex: 0 1 auto;
    max-width: 30%;

    .order-table__intro-text {
      white-space: nowrap;
    }

    .order-table__head {
      margin-bottom: 1.5rem;
    }
  }

  .filter-container {
    @include desktop {
      margin: 0;
    }
  }

  &__button-wrap {
    @include desktop {
      flex: 0;
      margin-right: 4rem;
      white-space: nowrap;
    }

    a.button {
      margin: 0;
    }
  }
}
