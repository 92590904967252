.fragment--clear-cart {
  display: flex;
  justify-content: flex-end;

  @include touch {
    height: 100%;
    align-items: flex-end;

    .component--popup .content,
    .component--popup p {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  @include desktop {
    .component--popup .content-container {
      max-height: 28.125rem;
    }
  }

  .clear-cart {
    padding-top: 0.35rem;

    .link svg {
      fill: none;
      stroke: $color-primary;
      margin-right: 0.3rem;
    }

    span.link {
      display: flex;
      color: $color-primary;
      font-size: 0.875rem;
      cursor: pointer;
      line-height: 1.5;

      &:hover {
        text-decoration: underline;
      }
    }

    .bottom-items {
      display: flex;
      width: 70%;
      justify-content: space-between;

      button {
        flex-basis: 50%;
      }

      @include touch {
        flex-direction: column;
      }
    }
  }
}

.clear-cart-container {
  align-self: flex-end;
  margin-top: 1.25rem;

  @include touch {
    align-self: flex-start;
  }
}
