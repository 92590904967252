.barcode-modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 14vh 0 36px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;

  &.is-shown {
    opacity: 1;
    visibility: visible;
    background-color: $color-dark;
  }
}

.barcode-modal-close {
  display: block;
  position: absolute;
  top: 4.43vh;
  right: 13px;
  width: 46px;
  min-width: auto;
  max-width: none;
  height: 46px;
  line-height: 46px;
  text-align: center;
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;
  color: $color-white;

  svg {
    width: 22px;
    height: 22px;
    vertical-align: middle;
  }
}

.barcode-modal-text {
  @include font-family-title();

  color: $color-white;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
  margin-bottom: 7vh;
  padding: 0 36px;
}

.fragment--notification.barcode-alert.is-active.is-global {
  top: 0;
}
