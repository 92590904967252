.component--popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  z-index: $z-index__popup;
  background-color: $color-background-backdrop;
  opacity: 1;

  @include desktop {
    display: table;
  }

  .content-wrapper {
    height: 100%;

    @include desktop {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .title {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .content-container {
    position: relative;
    padding: 48px 48px 24px;
    background-color: $color-background-white;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    overflow: auto;
    height: 100%;

    @include desktop {
      max-width: 724px;
      margin: 0 auto;
      width: 80%;
      height: 80%;
      border: 1px solid $color-border-default;
    }
  }

  .content {
    color: $color-text-dimmed;
    overflow-y: auto;
    font-size: 1rem;
    height: auto;
    min-height: calc(100% - 87px);
    margin-top: 65px;

    @include desktop {
      margin-top: 0;
    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    z-index: $z-index__popup;
    left: 20px;
    top: 12px;
  }
}
