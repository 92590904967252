.fragment--order-table {
  @include touch {
    position: relative;
  }

  .filter-container {
    justify-content: space-between;
    margin: 3rem 0;

    @include touch {
      margin: 2.5rem 0 0;
    }
  }

  .filter-container .search-container {
    position: relative;

    @include touch {
      margin-bottom: 1rem;
    }
  }

  .filter-container > .search-container input[type='text'] {
    padding-right: 2rem;
    width: 17rem;

    @include touch {
      width: 100%;
    }
  }

  .filter-container .form-select {
    width: 17rem;

    @include touch {
      width: 100%;
    }
  }

  .filter-container > .search-container::after {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29uL1NlYXJjaDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPgogICAgICAgIDxnIGlkPSJTZWFyY2gtYmFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTg4LjAwMDAwMCwgLTguMDAwMDAwKSIgc3Ryb2tlPSIjOTc5Nzk3Ij4KICAgICAgICAgICAgPGcgaWQ9Ikljb24vU2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODguMDAwMDAwLCA4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9Inpvb20iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMy41LDEzLjUgTDEwLjM1ODg4MDcsMTAuMjAzMzc0NSIgaWQ9IlBhdGgiIHN0cm9rZS13aWR0aD0iMS41Ij48L3BhdGg+CiAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGN4PSI2IiBjeT0iNiIgcj0iNS41Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+) center center no-repeat;
    content: '';
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1rem;
  }

  table {
    border: 0;
    box-shadow: none;
  }

  table thead {
    border-bottom: solid 1px $color-border-default;
    text-align: left;
  }

  table tr th {
    padding: 0.5rem 0;
  }

  table tr {
    border: 0;

    @include touch {
      display: block;
      max-width: 100%;
      position: relative;
    }
  }

  table tr td {
    border-left: 0;
    padding: 0.75rem 0;

    @include touch {
      border-bottom: 1px solid $color-border-light;
      display: flex;
      padding: 3rem 0;
      width: 100%;
    }
  }

  table tr:not(:last-child) td {
    @include desktop {
      border-bottom: 1px solid $color-border-light;
    }

    @include mobile {
      border-bottom: 1px solid $color-border-default;
    }
  }

  table tr td ul li {
    border-top: 1px solid $color-border-light;
  }

  table tr td ul li:last-child {
    justify-content: flex-end;
    text-align: right;
  }

  table tr td ul li:nth-child(-n+2) {
    border: 0;
  }

  .flex-content {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .flex-item {
    padding: 1rem 0;
    width: 50%;
  }

  .order-table {
    &_layout {
      padding: 2.5rem 0.5rem 5rem 2.5rem;

      @include touch {
        padding: 1.5rem 1rem;
      }
    }

    &_dialog-border {
      @include negative-border-radius($color: $color-border-default, $position: all, $size: 1.25rem);

      padding: 2px;

      @include desktop {
        margin: auto;
      }
    }

    &_dialog {
      @include negative-border-radius($color: $color-white, $position: all, $size: 1.25rem);

      margin: 0;
      padding: 2rem;
    }
  }

  .background-image-combi {
    @include combi-background($background: (url(../images/my-account-first.png) repeat-y right 100px, url(../images/my-account-second.png) repeat-y left 100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left), $backgroundMobile: (url(/images/my-account-first.png) repeat-y right -100px, url(/images/my-account-second.png) repeat-y left -100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left));
  }

  @include desktop {
    .order-table__intro-text {
      margin-bottom: 2rem;
      width: 70%;
    }
  }

  .long-header {
    max-width: 150px;
  }
}
