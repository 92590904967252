.component--saved-items {
  .saved-items__title {
    @include font-h3();
    @include text-uppercase();
  }

  .saved-items__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;

    @include desktop {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }

  .saved-items__box {
    @include fancy-border($allPositions: true, $width: 0.5rem);

    flex: 0 1 139px;
    margin: 1rem;
    padding: 0.5rem;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .glide__arrow {
    height: 42px;
    width: 42px;
  }

  .glide__track.is-hidden-overlay-mobile {
    @include mobile {
      &::after {
        display: none;
      }
    }
  }

  .glide__track.is-hidden-overlay-touch {
    @include tablet {
      &::after {
        display: none;
      }
    }
  }

  .glide__track {
    border: 0;
    position: relative;

    &::after {
      background: $color-white;
      content: '';
      height: 100%;
      opacity: 0.9;
      position: absolute;
      right: 0;
      top: 0;
      width: 13%;
      z-index: 1;

      @include tablet {
        width: 17%;
      }
    }
  }

  .saved-items__name {
    display: block;
    line-height: 1.5;
    margin: 1.1rem 0 0.9rem 0;
    white-space: initial;

    @include font-h5($font-weight: 700, $line-height: 1.5);
  }

  .saved-items__show-all-link {
    margin-top: $space-1x;
    width: 100%;
  }
}
