.fragment--personal-details {
  @include touch {
    position: relative;
  }

  .personal-details__title {
    @include font-h3();
    @include text-uppercase;

    padding-top: 2rem;
  }

  .personal-details__title-border {
    @include fancy-border($positions: top);
  }

  .personal-details__warning,
  .personal-details__success {
    @include font-small-body-copy();

    margin: 1.625rem -2rem 0;
    padding: 1rem 1rem 1rem 2.5rem;
    position: relative;
    text-indent: -1rem;

    @include desktop {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .personal-details__warning {
    background-color: $color-notification-warning;
    color: $color-notification-warning-text;

    &::before {
      background-color: $color-notification-warning-text;
      border-radius: 50%;
      color: $color-notification-warning;
      content: '!';
      display: inline-block;
      height: 1rem;
      left: -0.5rem;
      line-height: 1rem;
      position: relative;
      text-align: center;
      text-indent: 0;
      width: 1rem;
    }
  }

  .personal-details__success {
    background-color: $color-notification-success-tint;
    color: $color-notification-success;

    &::before {
      background-color: $color-notification-success;
      border-radius: 50%;
      color: $color-notification-success-tint;
      content: '\2713';
      display: inline-block;
      height: 1rem;
      left: -0.5rem;
      line-height: 1rem;
      position: relative;
      text-align: center;
      text-indent: 0;
      width: 1rem;
    }
  }

  .personal-details {
    &_layout {
      padding: 2.5rem 1rem 2.5rem 2.5rem;

      @include touch {
        padding: 1.5rem 1rem;
      }
    }

    &_dialog-border {
      @include negative-border-radius($color: $color-border-default, $position: all, $size: 1.25rem);

      padding: 2px;

      @include desktop {
        margin: auto;
      }
    }

    &_dialog {
      @include negative-border-radius($color: $color-white, $position: all, $size: 1.25rem);

      margin: 0;
      padding: 2rem;
    }
  }

  .background-image-combi {
    @include combi-background($background: (url(../images/my-account-first.png) repeat-y right 100px, url(../images/my-account-second.png) repeat-y left 100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left), $backgroundMobile: (url(/images/my-account-first.png) repeat-y right -100px, url(/images/my-account-second.png) repeat-y left -100px, $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left));
  }

  .personal-details__form {
    margin: 1rem 0;
  }

  .checkbox--personal {
    &:not(:checked) ~ .checkbox__personal-form {
      display: none;
    }

    &:checked ~ .checkbox__personal-info {
      display: none;
    }
  }

  .personal-details__request-change label {
    cursor: pointer;
  }

  .field-list {
    padding-bottom: 1rem;
  }

  .personal-details__password-forgotten {
    color: $color-text-dimmed;
    text-align: right;
  }

  .personal-details__2-grid-buttons {
    column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  a.button.is-primary.personal-details__dashboard-btn {
    background-color: $color-complementary;
    color: $color-white;
    border: 1px solid $color-complementary;
    width: 100%;
    max-width: 100%;
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    margin-bottom: 15px;
    display: inline-block;

    @include tablet {
      width: auto;
      min-width: 216px;
      margin-bottom: 0;
    }

    span {
      width: min-content;
      margin-right: 5px;
    }
  }

  .personal-details__container {
    justify-content: space-between;
  }

  @include desktop {
    .personal-details__2-grid-info {
      column-gap: 2rem;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }

    .personal-details__2-grid-input {
      align-items: center;
      column-gap: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }

    .personal-details__password-forgotten {
      text-align: left;
    }
  }
}

.personal-details__changeLabel,
.personal-details__pswdChange,
.personal-details__editIcon {
  @extend .is-color-secondary;

  color: $color-text-dimmed;
}

.profile-breadcrumbs-wrapper {
  display: none;
}
