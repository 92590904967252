table {
  @include fancy-border($positions: (top, bottom));

  border-collapse: collapse;
  margin-bottom: $space-1x;
  margin-top: 0;
  width: 100%;

  tr + tr {
    border-top: 1px solid $color-tetrad;
  }

  td {
    padding: 0.5rem;
  }

  td + td {
    border-left: 1px solid $color-tetrad;
  }

  &[border='0'] {
    border-width: 0;
    box-shadow: none;

    tr + tr {
      border-top-width: 0;
    }

    td + td {
      border-left-width: 0;
    }
  }
}
