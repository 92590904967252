.fragment--product-description {
  &.product-description__detail-container {
    padding: 1.5rem 0;
  }

  .product-description__paragraph {
    padding: 1rem 0;
  }

  .product-description__list {
    padding: 1rem 0;
  }
}
