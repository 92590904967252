.component--product-category-tile {
  background-color: $color-primary-tint;
  position: relative;

  &::after {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOklEQVQoU43MSwoAMAgD0eT+h7ZYaOlHo7N+DNHL2HAGgBWcyGcKbqTghTL4oQiG6IUpOqFEC5bI4QD8PAoKd9j4XwAAAABJRU5ErkJggg==');
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.15;
    position: absolute;
    right: 0;
    top: 0;
  }

  /** General styling for elements **/
  a {
    @include text-uppercase;

    color: $color-primary;
    cursor: pointer;
    margin: 0;
    position: relative;
    text-decoration: none;
    z-index: 1;
  }

  img {
    max-height: 100%;
  }

  .product-category-tile__wrapper {
    padding: 3rem 0;

    @include touch {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .product-category-tile__container {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;

    @include touch {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .large-tile__text-container {
    background-color: $color-primary-tint;
    height: 18.8rem;
    overflow: hidden;
    position: relative;
    transform: rotate(45deg);
    width: 18.8rem;
    word-break: break-all;

    @include touch {
      height: 13.8rem;
      width: 13.8rem;
    }
  }

  .large-tile__text {
    @include font-h2;

    align-items: center;
    color: $color-primary;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transform: rotate(-45deg);
    width: 100%;

    @include touch {
      height: 100%;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  /** Styling for long tile **/
  .long-tile {
    background-color: $color-primary-tint;
    border: 2px solid $color-grey;
    grid-column: span 2;
    grid-row: span 3;
    height: 15.35rem;
    overflow: hidden;
    position: relative;

    @include touch {
      box-shadow: none;
      height: 8rem;
      margin: 0.5rem 0;
      width: 20.9rem;
    }

    &::after {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2NkYGD4z8DAwMiAH/yHKcCnGCyHbBI2xXAxdCuRFaNoxOY2kAIQQJEjWyFRVhPlGaKCh6gABwBcgBIJm5yNfAAAAABJRU5ErkJggg==');
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.05;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .long-tile__media-figure-container {
    align-items: center;
    border: 1px solid $color-grey;
    height: 12rem;
    overflow: hidden;
    position: relative;
    right: -22rem;
    top: -13.5rem;
    transform: rotate(45deg) scale(1.6);
    width: 12rem;
    z-index: 1;

    @include touch {
      height: 14rem;
      right: -13.3rem;
      top: -3.14rem;
      transform: rotate(45deg);
      width: 14rem;
    }
  }

  .long-tile__conainer-text {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    padding: 1rem 3.125rem 1rem 1rem;
    width: 50%;

    @include touch {
      left: 0;
      padding: 1rem;
      position: absolute;
      top: 0;
    }
  }

  .long-tile__text {
    @include font-h3;

    color: $color-primary;
  }

  .long-tile__img {
    object-fit: cover;
    transform: rotate(-45deg) scale(1.5);
    transform-origin: center center;
  }

  .medium-tile__media-figure-container {
    display: none;
  }

  /** Styling for medium tile **/
  .medium-tile__text {
    @include font-h2;

    align-items: center;
    color: $color-primary;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 1.5rem;
    word-break: break-all;
  }

  /** Styling for small tile **/
  .small-tile__wrapper {
    background-color: $color-primary-tint;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    padding: 0 1.8rem;
    position: relative;
    width: 20.9rem;
    z-index: 1;
  }

  .small-tile {
    align-items: center;
    background-color: $color-primary-tint;
    border: 1px solid $color-grey;
    display: flex;
    grid-area: span 1;
    grid-column: span 1;
    height: 2.94rem;
    overflow: hidden;
    position: relative;

    @include touch {
      border: 0;
      box-shadow: none;
      width: 49%;

      &::before {
        background: $color-primary;
        top: 1.3rem;
      }
    }
  }

  .small-tile__text {
    @include font-h4;

    align-items: center;
    color: $color-primary;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    word-break: break-word;

    @include desktop {
      height: 5rem;
    }
  }

  /** Grid area mapping **/
  .large-tile {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid $color-grey;
    display: flex;
    grid-column: span 2;
    grid-row: span 6;
    height: 32rem;
    justify-content: center;

    @include touch {
      border: 2px solid $color-grey;
      box-shadow: none;
      height: 20.9rem;
      margin: 0.5rem 0;
      width: 20.9rem;
    }
  }

  .medium-tile {
    align-items: center;
    background-color: $color-primary-tint;
    border: 2px solid $color-grey;
    display: flex;
    grid-column: span 1;
    grid-row: span 3;
    height: 15.35rem;
    justify-content: center;
    position: relative;
  }

  .tile__image-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    img {
      flex: 1 1 auto;
      object-fit: cover;
    }
  }
}
