.fragment--product-summary {
  .form-notifications .is-invalid {
    width: 500%;

    @include touch {
      width: 350%;
    }
  }

  div[name='trading-quantity'] .form-notifications .is-invalid {
    width: 100%;
  }

  .product-tile__favorite {
    height: 3.25rem;
    padding: 0.9rem;
    position: absolute;
    width: 3.25rem;
    z-index: 1;

    @include touch {
      right: 0;
      top: -0.8rem;
    }

    @include desktop {
      top: -3.4rem;
    }

    @include desktop {
      @include fancy-border($allPositions: true, $width: 0.5rem);

      align-items: center;
      background-color: $color-background-white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      right: 2rem;
      top: -1.9rem;
    }

    > svg {
      display: block;

      @include touch {
        transform: scale(1.25);
      }

      @include desktop {
        transform: scale(1.25);
      }
    }
  }

  .product-summary__content-container {
    padding: 1.75rem 2rem 2.5rem;

    @include touch {
      padding: 1.75rem 0;
    }
  }

  .product-summary__attributes {
    margin-top: 1.625rem;
  }

  .brand-url {
    @include font-regular-body-copy();

    text-decoration: underline;
  }

  .product-summary__content-container.has-border {
    border-bottom: 1px solid $color-border-default;
    border-top: 1px solid $color-border-default;
  }

  .product-summary__variant {
    display: flex;
  }

  .product-summary__variants-heading {
    padding-bottom: 1rem;
  }

  .product-summary__variant-subheading {
    @include font-small-body-copy;

    padding-top: 0.1428rem;
    position: relative;
  }

  .product-summary__variant-list-item {
    padding-left: 1rem;
  }

  .product-summary__variant-list-item .active {
    border-bottom: 2px solid $color-tetrad;
  }

  .product-summary__variant-list-item:not(:last-child) {
    &::after {
      content: '|';
      padding-left: 1rem;
    }
  }

  .product-summary__variant-list {
    display: flex;
    flex-wrap: wrap;
  }

  .product-summary__delivery-indication {
    align-items: center;
    color: $color-grey;
    display: flex;
    padding-top: 1rem;

    > span {
      padding-left: 0.5rem;
    }
  }

  .product-summary__stock-eta {
    display: block;
    padding-bottom: 7px;
    width: 100%;
    font-size: 0.75rem;
    color: $color-notification-error-text;
  }

  .form-input {
    flex-basis: 20%;

    @include touch {
      max-width: 20%;
    }
  }

  .form-addon {
    flex-basis: 80%;

    button {
      display: block;
      max-width: none;
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
    }
  }

  .product-count-notifications {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;

    .is-help.text-grey {
      color: $color-grey;
      font-size: 0.75rem;
      line-height: 1.67;
    }
  }

  .product-tile__notifications {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include desktop {
      justify-content: center;
    }
  }

  .product-summary__attribute {
    padding-bottom: 0.5rem;
  }

  .product-tile__add-to-cart {
    position: relative;
  }

  .notifications__out-of-stock {
    margin-top: 1.75rem;
  }

  .product-tile__notifications__cart__total-of-product {
    &::after {
      content: ')';
    }

    &::before {
      content: '(';
    }
  }

  .product-tile__notifications__cart--inner {
    position: absolute;
    right: 0%;
    top: -1.75rem;

    @include touch {
      right: 0;
    }
  }

  .product-summary__add-to-cart {
    margin: 0.25rem 0;
  }

  .cart-icon__icon {
    height: 16px;
    position: relative;
    top: 2px;
    width: 16px;

    @include touch {
      left: 3px;
      top: 3px;
      transform: scale(0.8);
    }
  }

  .product-tile__price {
    padding: 1.75rem 0 0.5rem;

    &.price-discounted .product-tile__price__price,
    &.price-discounted .product-tile__price__excise {
      color: $color-primary;

      sup {
        color: $color-primary;
      }
    }

    &.price-discounted .toggle--price-excises.is-toggle-label {
      color: $color-primary;

      &::after {
        border-color: $color-primary;
      }
    }

    .product-tile__price__price {
      display: inline-block;
    }

    .toggle--price-excises.is-toggle-label sup {
      top: -0.2em;

      @include desktop {
        top: -0.45em;
      }
    }

    .toggle--price-excises.is-toggle-label::after {
      margin-bottom: 0.7rem;

      @include desktop {
        margin-bottom: 1.2rem;
      }
    }
  }

  .product-tile__price__price {
    @include font-h1();
  }

  .product-tile__price__old-price {
    @include font-regular-body-copy();

    color: $color-text-dimmed;
  }

  .product-summary__title {
    @include font-h2();

    padding-bottom: 0.5rem;
  }

  .product-summary__price {
    @include font-h1();
  }

  .product-summary__vol::after {
    content: '\007C';
    padding-left: 5px;
  }

  .product-summary__sku {
    padding-left: $space-1x;
  }

  .product-tile__subscribe-stock-notifications {
    color: $color-complementary;
    cursor: pointer;

    svg {
      fill: $color-complementary;
    }
  }

  .is-success-transition {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1;
    color: $color-notification-success;

    svg {
      fill: $color-notification-success;
    }

    span {
      margin-left: 4px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .is-success-transition-dark {
    color: $color-tetrad;

    svg {
      fill: $color-tetrad;
    }
  }

  .is-pos-initial {
    position: inherit;
  }

  @include desktop {
    .product-summary__title {
      margin-top: 1.5rem;
    }

    .product-summary__content {
      padding: 0 2em;
    }
  }

  .toggle--trading.is-toggle-trigger:checked ~ .product-tile__container {
    .toggle--trading.is-toggle-handler {
      padding-top: 0;
    }

    .button-container {
      align-items: center;

      button.is-request-quote-button {
        min-width: 200px;
      }

      label {
        margin-top: 1rem;
      }
    }
  }

  .toggle--price-excises.is-toggle-label {
    text-transform: none;
  }
}

.product-detail__summary-wrapper {
  width: 100%;
}

.product-summary__no-request-stock {
  .product-tile__give-alert,
  .is-success,
  .is-success-transition-dark,
  a {
    display: flex;
    align-items: center;
  }

  .product-tile__give-alert {
    justify-content: flex-end;
    font-size: 0.75rem;

    a {
      padding: 6px 0;
      cursor: pointer;
      color: $color-complementary;

      svg {
        stroke: $color-complementary;
      }
    }
  }

  .is-success {
    color: $color-notification-success;

    svg {
      fill: $color-notification-success;
    }
  }

  .is-success-transition-dark {
    color: $color-tetrad;

    svg {
      fill: $color-tetrad;
    }
  }

  span {
    margin-left: 4px;
  }
}

.suggested-price-block {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $color-grey;
  padding: 1.25rem 2rem;
  gap: 12px;

  &__content {
    display: flex;
    color: $color-grey;

    &--subscript {
      margin-left: 4px;
      line-height: 22px;
      font-size: 12px;
    }
  }
}
