$notifications-global--font-size: 1em !default;
$notifications-global--line-height: 1.67 !default;

.notification-global {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid;
  font-size: $notifications-global--font-size;
  line-height: $notifications-global--line-height;
  text-align: center;

  @include touch {
    text-align: left;
    display: block;
  }

  &.is-level-error {
    background: $color-global-notification-error-background;
    color: $color-global-notification-error-text;
    border-bottom-color: $color-global-notification-error-text;
  }

  &.is-level-warning {
    background: $color-global-notification-warning-background;
    color: $color-global-notification-warning-text;
    border-bottom-color: $color-global-notification-warning-text;
  }

  &.is-level-info {
    background: $color-global-notification-info-background;
    color: $color-global-notification-info-text;
    border-bottom-color: $color-global-notification-info-text;
  }

  &:nth-child(n+1) {
    margin-top: 0;
  }

  + .header-background {
    padding-top: 0.5rem;
  }
}
