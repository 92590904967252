.page--lists-details {
  .component--inpage-navigation a[href*='lists'] {
    color: $color-primary;
  }

  .component--search-bar {
    .ais-SearchBox-input {
      height: 2.5rem;
      padding-right: 2.5rem;

      @include desktop {
        padding-right: 2.25rem;
      }

      &.is-error {
        border-color: $color-notification-error;
      }
    }

    .is-error-text {
      color: $color-notification-error-text;
    }

    &:not(.product-autosuggest)::after {
      right: 2rem;
      top: 0.85rem;

      @include desktop {
        right: 1.5rem;
        top: 1.75rem;
      }
    }
  }

  .product-list__view-options {
    @include desktop {
      display: flex;
    }
  }

  .component--search-bar,
  .product-list__switch,
  .product-list__sort-by {
    @include desktop {
      flex: 0 0 calc(33.33% + 1px);
      padding: 1rem 0.63rem 0 0.5rem;
    }
  }

  .order-detail-page__bg--white {
    position: relative;
    z-index: 1;

    @include desktop {
      min-width: 100%;
      padding: 0 3rem 0 3.8rem;
    }

    &::before {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
      content: '';
      height: 6px;
      left: 0;
      position: absolute;
      right: 0;
      top: -6px;
    }
  }

  .fragment--page-title {
    z-index: 0;
  }

  .order-detail-page__state-container {
    padding: 1rem 0;

    @include desktop {
      border-bottom: 1px solid $color-border-default;
      padding: 2rem 0;
    }
  }
}
