.component--category-navigation {
  @include touch {
    overflow-x: hidden;
  }

  .category-list {
    @include font-regular-body-copy();

    position: relative;

    @include touch {
      margin-top: 4em;
    }

    @include desktop {
      max-height: calc(100vh - 200px);
      overflow: auto;

      .is-collapsed {
        display: none;
      }
    }

    ol {
      cursor: default;

      li {
        cursor: pointer;
      }
    }

    li + li {
      margin-top: 1.3em;
    }

    li:first-child {
      margin-bottom: 2.5em;
    }

    .category-list__sub-item--heading {
      color: $color-grey;
    }

    .category-list__sub-item--link.is-active,
    .is-current-page {
      color: $color-primary;
    }

    .has-children::after {
      @include tick($color: $color-grey);

      display: none;
      margin-left: 0.5rem;
    }

    @include desktop {
      .has-children {
        padding-right: 1rem;
      }

      .is-active-list > .category-list__sub-item--heading,
      .is-active-list > li > a:not(.is-active) {
        opacity: 0.5;
      }

      .category-list__item--link.is-active {
        color: $color-primary;
      }

      .category-list__item--link.is-active.has-children::after {
        border-right: 1px solid $color-primary;
        border-top: 1px solid $color-primary;
        display: inline-block;
      }
    }

    @include touch {
      .has-children::after {
        display: block;
        float: right;
        margin-right: 0.5rem;
        margin-top: 0.5em;
      }
    }

    @include desktop {
      ol:hover > li > a:not(.is-active) {
        opacity: 0.5;
      }

      ol:hover > li:hover > a {
        opacity: 1;
      }

      ol:hover > li:hover > a.has-children::after {
        display: inline-block;
      }
    }

    // Sub categories

    @include touch {
      ol {
        height: calc(var(--app-height) - 2rem - 4em);
        left: 0;
        overflow-y: auto;
        padding-top: 2rem;
        position: absolute;
        width: 100%;
      }

      ol ol {
        background-color: $color-background-white;
        bottom: 0;
        display: block;
        height: var(--app-height);
        left: 0;
        padding-top: 4em;
        padding-bottom: 2em;
        padding-left: 1rem;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0 !important; // to ensure back button is visible and javascript added style is overwritten
        transform: translateX(0);
        transition: transform $speed ease-in-out;
        z-index: $zindex--container-header + 22;
      }

      ol ol.is-collapsed {
        height: 100vh;
        overflow-y: hidden;
        transform: translateX(calc(100% + 2rem));
      }
      // .is-current-page + ol {
      //   transform: translateX(0);
      // }
    }

    @include desktop {
      ol {
        height: 100%;
        padding: 2.5rem 0;
        width: 43%;
      }

      ol ol {
        float: right;
        left: 4%;
        position: relative;
        width: 40%;
      }
    }
  }
}

.toggle--category-navigation.is-toggle-label {
  color: $color-primary;
  cursor: pointer;
  user-select: none;

  @include desktop {
    background-color: $color-primary-tint;
    padding: 0.25rem 1rem;
  }

  &:not(.is-closing)::after {
    margin-left: 0.6667rem;
    transition: transform $speed-fast;
  }

  &.is-closing {
    background:
      linear-gradient(-45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat,
      linear-gradient(45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50% / 75% 75% no-repeat;
    color: $color-grey;
    height: 1.5rem;
    padding: 0;
    right: 1rem;
    text-indent: -420rem;
    width: 1.6rem;

    @include touch {
      left: 1rem;
      position: fixed;
      top: 2rem;
    }

    @include desktop {
      left: 0;
      position: absolute;
      top: 1rem;
    }
  }
}

.toggle--category-navigation.is-toggle-handler {
  @include touch {
    background: $color-background-white;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: transform $speed ease-in-out;
    width: 100%;
    z-index: $zindex--container-header + 20;
  }

  @include desktop {
    max-height: 0;
    overflow: hidden;
    // transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  }
}

.toggle--category-navigation.is-toggle-trigger:not(:checked) ~ .container--header {
  .toggle--category-navigation.is-toggle-label:not(.is-closing)::after {
    @include desktop {
      @include tick($direction: bottom, $color: $color-primary, $size: 0.5em, $tickness: 2px);

      position: relative;
      top: -0.25rem;
    }

    @include touch {
      @include tick($direction: right, $color: $color-primary, $size: 0.5em, $tickness: 2px);

      float: right;
      transform: rotate(45deg) translateY(0.75rem);
    }
  }

  .toggle--category-navigation.is-toggle-handler {
    @include touch {
      transform: translateX(100%);
    }
  }
}

.toggle--category-navigation.is-toggle-trigger:checked ~ .container--header {
  overflow-y: auto;

  .toggle--category-navigation.is-toggle-label:not(.is-closing)::after {
    @include tick($direction: top, $color: $color-primary, $size: 0.5em, $tickness: 2px);

    position: relative;
    top: -0.25rem;
  }

  .toggle--category-navigation.is-toggle-handler {
    @include touch {
      transform: translateX(0);
    }

    @include desktop {
      background-color: $color-background-white;
      max-height: 420rem;
      // height: auto;
    }
  }
}

@include desktop {
  .toggle--category-navigation.is-toggle-trigger:checked ~ .container--header::after,
  .toggle--language-switcher.is-toggle-trigger:checked ~ .container--header::after {
    @include backdrop;

    transform: translateY(-3rem);
    z-index: -1;
  }
}
