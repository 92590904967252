.component--cart-icon {
  // .cart-icon__container {
  //   @include font-small-body-copy();

  //   color: $color-primary;
  //   padding: 0.625rem 1rem;
  //   position: relative;
  //   top: -0.5rem;
  //   white-space: nowrap;
  // }

  .cart-icon__container-border {
    @include touch {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
    }

    @include desktop {
      border: 1px solid $color-light;
      padding: 0.625rem 0.5rem 0.625rem 1rem;
    }
  }

  a {
    text-decoration: none;
  }

  .cart-icon__total-price {
    @include font-h5();

    border-left: 1px solid $color-light;
    color: $color-grey;
    padding: 0 0.5rem 0 1rem;
  }

  @include desktop {
    .cart-icon__icon {
      transform: scale(1.3) translateY(0.125rem);
    }
  }

  .cart-icon__total-items {
    @include font-h5();

    color: $color-primary;
    display: inline-flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include desktop {
      padding: 0 0.5rem;
    }

    &::before {
      content: '(';
      margin-left: 0.5714rem;
    }

    &::after {
      content: ')';
    }
  }
}
