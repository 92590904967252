section.show-as-product-lines div[name='trading-quantity'] .form-notifications .is-invalid {
  width: 100%;
}

section.container--list-detail-items:not(.show-as-product-lines) .add-to-list_popup__button.trash-icon {
  position: absolute;
  right: 1rem;
  left: unset;
  top: -1rem;
}

.container--product-tiles.show-as-product-lines .product-tile__favorite[data-modal-window] {
  position: absolute;
  left: 0.5rem;
  top: 2rem;
}

.fragment--product-tile {
  position: relative;

  .form-notifications {
    @include font-family-default();

    font-size: 0.85rem;

    .is-help {
      @include font-extra-small-body-copy();

      width: 0;
      white-space: nowrap;
      padding: 0.25rem 0;
    }

    &.is-floating {
      position: absolute;
      width: calc(100% - 1rem);
      font-size: 0.65rem;
      bottom: 12%;

      &--extra-space {
        bottom: 22%;
      }

      &--list-line {
        display: none;
      }
    }
  }

  .form-notifications .is-invalid {
    width: calc(400% - 4rem);

    @include touch {
      width: calc(300% - 1rem);
    }
  }

  .fragment--notification {
    &.is-compact {
      padding: 0;
    }
  }

  .is-pos-initial {
    position: initial;
  }

  .product-tile__favorite {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    padding: 0;
    position: absolute;
    width: 2rem;
    z-index: 1;

    &.add-to-list_popup__button.trash-icon {
      position: absolute;
      left: 0;
      top: 1.5rem;

      svg {
        fill: $color-white;
        stroke: $color-tetrad;
      }
    }

    @include touch {
      right: 0.6rem;
      top: 1.2rem;
    }

    @include desktop {
      right: 1rem;
    }

    @include desktop {
      @include fancy-border($allPositions: true, $width: 0.25rem);

      background-color: $color-background-white;
      border-radius: 50%;
      right: 1.5rem;
      top: -1rem;
    }

    > svg {
      display: block;

      @include touch {
        transform: scale(1.25);
      }

      @include desktop {
        transform: scale(1);
      }
    }
  }

  @include touch {
    &:nth-child(2n+2) .product-tile__favorite {
      transform: translateX(1rem);
    }
  }

  .product-tile__tags {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0.5rem;
    z-index: 1;

    @include desktop {
      left: 0.25rem;
    }

    @include touch {
      left: 0;
    }
  }

  .product-tile__container {
    background-color: $color-background-white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2em 1em;
    position: relative;
    max-height: $product-tile-container-mobile-height;

    @include desktop {
      max-height: $product-tile-container-full-height;
    }

    a {
      cursor: pointer;
    }
  }

  .product-tile__image {
    min-height: 12rem;
    height: 12rem;
    text-align: center;
    width: 100%;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .product-tile__category,
  .product-tile__details {
    border-bottom: 1px solid $color-border-light;
    border-top: 1px solid $color-border-light;
    line-height: 2rem;
  }

  .product-tile__category {
    @include font-h5();
    @include text-uppercase();

    align-items: center;
    display: flex;
    height: 3em;
    justify-content: center;
    margin: 1em 0 0.5em;
    text-align: center;
  }

  .product-tile__name {
    @include font-h4();

    height: 4.5rem;
    margin: 0.25em 0;
    overflow: hidden;
  }

  .product-tile__details {
    @include font-small-body-copy();

    display: flex;
    flex-grow: 0;
    margin: 0.25rem 0 0.5em;
    white-space: nowrap;
  }

  .product-tile__details__vertical-line {
    border-left: 1px solid $color-border-light;
    margin: 0 2px;
    width: 1px;
  }

  .product-tile__details__attributes {
    > span + span::before {
      content: ' | ';
    }
  }

  .product-tile__price__old-price {
    @include font-extra-small-body-copy();

    color: $color-grey-blueish;
    overflow: hidden;
  }

  .product-tile__price {
    border-bottom: 1px solid $color-border-light;
    min-height: 3.5rem;
    text-align: start;

    .product-tile__price__price {
      @include font-h3();
    }

    &__container {
      min-height: 1rem;
    }

    &.price-discounted .product-tile__price__price,
    &.price-discounted .product-tile__price__excise {
      color: $color-primary;
    }

    &.price-discounted .toggle--price-excises.is-toggle-label {
      color: $color-primary;

      &::after {
        border-color: $color-primary;
      }
    }

    sup {
      @include font-h5();

      vertical-align: top;
      white-space: nowrap;
    }
  }

  .product-tile__toggle-container {
    color: $color-complementary;
    height: 2rem;
    text-align: right;

    svg {
      vertical-align: middle;
    }
  }

  .product-tile__give-alert {
    .is-success-transition {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 4px;
      line-height: 1;
      color: $color-notification-success;

      svg {
        fill: $color-notification-success;
      }

      span {
        margin-left: 4px;
        font-size: 10px;
      }
    }

    .is-success-transition-dark {
      color: $color-tetrad;

      svg {
        fill: $color-tetrad;
      }
    }
  }

  .is-justify-content-space-between {
    justify-content: space-between;
  }

  .is-request-quote-button {
    display: block;
    max-width: none;
    padding: 2px 5px;
    line-height: 1;
    width: 100%;
  }

  .product-tile__notifications {
    @include font-h5();

    align-items: baseline;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    height: 4rem;
    justify-content: center;

    &--auto-height {
      height: auto;
    }
  }

  .product-tile__stock-eta {
    @include font-family-default();

    display: block;
    width: 100%;
    font-size: 0.625rem;
    color: $color-notification-error-text;
    margin: 5px 0 3px;
    white-space: normal;
  }

  .product-tile__notifications__cart--inner {
    @include font-h6();

    align-items: center;
    color: $color-grey;
    display: inline-flex;

    @include touch {
      > span {
        padding-left: 0.5rem;
      }
    }
  }

  .product-tile__notifications__cart--inner.is-trading {
    @include touch {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: 1rem;
    }
  }

  .product-tile__notifications__cart svg {
    left: -3px;
    opacity: 0.5;
    position: relative;
    stroke: $color-svg-cart;
    transform: scale(0.95);

    @include touch {
      left: 5px;
      position: relative;
    }
  }

  button.is-approved svg {
    fill: $color-white;
  }

  .product-tile__notifications__cart.is-approved svg {
    stroke: $color-notification-success;
  }

  .product-tile__notifications__cart.is-approved .product-tile__notifications__cart--inner {
    color: $color-notification-success;
  }

  .product-tile__toggle-container .toggle--trading > svg {
    stroke: $color-complementary;
  }

  .product-tile__give-alert .is-success > svg {
    stroke: $color-notification-success;
  }

  .product-tile__notifications__cart__total-of-product {
    &::after {
      content: ')';
    }

    &::before {
      content: '(';
    }
  }

  .product-tile__subscribe-stock-notifications {
    cursor: pointer;
  }

  .product-tile__add-to-cart {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .form-group {
      flex-wrap: nowrap;
      position: relative;
      height: 4rem;

      .form-input {
        flex-basis: auto;
        width: 32%;
      }

      .form-addon {
        flex-basis: auto;
        width: 68%;
      }

      .form-addon .is-not-logged-in {
        width: 100%;
      }

      button {
        display: block;
        max-width: none;
        padding: 2px 5px;
        line-height: 1;
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }

  .product-tile__request-quote .form-group {
    flex-wrap: nowrap;
    position: relative;
    height: 4rem;

    .form-input {
      flex-basis: auto;
      width: 32%;
    }

    .form-addon {
      flex-basis: auto;
      width: 68%;
    }

    button {
      display: block;
      max-width: none;
      padding: 2px 5px;
      line-height: 1;
      width: 100%;
    }

    input {
      width: 100%;
    }
  }

  .button-container {
    flex-direction: column;
  }
}

.product-tile__notifications__cart {
  min-height: 2rem;

  @include desktop {
    min-height: auto;
  }
}
// no border style for mobile... That's done in _product-tiles.scss
@include desktop {
  /* stylelint-disable no-descending-specificity */

  .fragment--product-tile .form-notifications {
    .is-help {
      font-size: 0.57rem;
    }

    &.is-floating {
      bottom: 14%;
      width: calc(100% - 2rem);

      &--extra-space {
        bottom: 24%;
      }
    }
  }

  .glide--product-carousel .fragment--product-tile,
  .container--product-tiles:not(.show-as-product-lines) .fragment--product-tile,
  .container--list-detail-items:not(.show-as-product-lines) .fragment--product-tile {
    .product-tile__container {
      @include fancy-border($allPositions: true, $width: 0.5rem,  $allPositionsBorderSize: 2px);
    }

    .product-tile__notifications__cart {
      bottom: -1rem;
      left: 0;
      position: absolute;
      right: 0;
    }

    .product-tile__notifications__cart--inner {
      align-items: center;
      background: $color-white;
      border-bottom: 1px solid $color-border-default;
      border-top: 1px solid $color-border-default;
      display: flex;
      height: 23px;
      justify-content: center;
      margin: auto;
      padding: 0;
      position: relative;
      top: -4px;
      width: 65px;
    }

    .product-tile__notifications__cart svg {
      display: block !important;
      // transform: scale(0.6667) translateY(-0.75em);
    }

    .product-tile__notifications__cart--inner::after {
      right: -8px;
      -webkit-transform: rotateZ(135deg) translateY(0) translateX(-1px);
      -moz-transform: rotateZ(135deg) translateX(-0.5px);
    }

    .product-tile__notifications__cart--inner::before {
      left: -8px;
      -webkit-transform: rotateZ(-45deg) translateY(0) translateX(1.1px);
      -moz-transform: rotateZ(-45deg) translateY(-0.5px);
    }

    .product-tile__notifications__cart--inner::after,
    .product-tile__notifications__cart--inner::before {
      background: $color-white;
      border-left: 1px solid $color-grey;
      border-top: 1px solid $color-grey;
      content: '';
      height: 16.2634px;
      position: absolute;
      top: 3px;
      width: 16.2634px;
    }

    .product-tile__notifications__cart.is-approved {
      .product-tile__notifications__cart--inner {
        background-color: $color-notification-success-tint;
        border-bottom: 1px solid $color-notification-success;
        border-top: 1px solid $color-notification-success;
      }

      .product-tile__notifications__cart--inner::after,
      .product-tile__notifications__cart--inner::before {
        background-color: $color-notification-success-tint;
        border-left: 1px solid $color-notification-success;
        border-top: 1px solid $color-notification-success;
      }
    }

    .button-container {
      flex-direction: column;
    }
  }

  /* stylelint-enable no-descending-specificity */
}

.toggle--price-excises.is-toggle-trigger {
  display: none;
}

.toggle--price-excises.is-toggle-label {
  @include font-h5();
  @include text-uppercase;

  cursor: pointer;
  user-select: none;

  &::after {
    @include tick($direction: bottom);

    margin: 0 0 0.1rem 0.5rem;
  }
}

.toggle--price-excises.is-toggle-handler {
  background-color: $color-white;
  border: 1px solid $color-border-light;
  padding: 1rem;
  position: absolute;
  z-index: 1;
  width: auto;
}

.toggle--price-excises.is-toggle-trigger:not(:checked) {
  ~ .toggle--price-excises.is-toggle-label sup::after {
    @include tick($direction: bottom);

    position: relative;
    top: -0.25rem;
  }

  ~ .toggle--price-excises.is-toggle-handler {
    display: none;
  }
}

.toggle--price-excises.is-toggle-trigger:checked {
  ~ .toggle--price-excises.is-toggle-label sup::after {
    @include tick($direction: top);

    position: relative;
    top: -0.25rem;
  }

  ~ .toggle--price-excises.is-toggle-handler {
    display: block;
  }
}

.toggle--trading.is-toggle-trigger {
  display: none;
}

.toggle--trading.is-toggle-label {
  color: $color-complementary;
  cursor: pointer;
  display: block;
  user-select: none;
  text-align: right;

  &::before {
    border-color: $color-complementary;
  }
}

.toggle--trading.is-toggle-trigger:not(:checked) ~ .product-tile__container {
  .toggle--trading.is-toggle-handler {
    display: none;
  }
}

.toggle--trading.is-toggle-trigger:checked ~ .product-tile__container {
  .toggle--trading.is-toggle-handler {
    display: block;
    margin-top: 2rem;

    @include desktop {
      margin-top: 0;
    }

    .form-group .form-input {
      flex-basis: auto;
      width: 32%;
    }
  }

  .toggle--trading.is-toggle-handler .trading-price .has-float-label input {
    padding-left: 2rem;
    padding-right: 4rem;
    height: 2.5rem;
  }

  .toggle--trading.is-toggle-handler .trading-price .has-float-label span.price-currency {
    font-size: 0.75rem;
    padding: 0;
    top: 0.8rem;
  }

  .toggle--trading.is-toggle-handler .trading-price.trading-price--currency-transparent .has-float-label span.price-currency {
    background-color: transparent;
  }

  .toggle--trading.is-toggle-handler .trading-price .has-float-label span.price-package-type {
    font-size: 0.75rem;
    left: auto;
    padding: 0;
    right: 1rem;
    top: 0.8rem;
  }

  .product-tile__add-to-cart {
    display: none;
  }
}

.product-tile__no-request-stock {
  .product-tile__give-alert,
  .is-success,
  .is-success-transition-dark,
  a {
    display: flex;
    align-items: center;
  }

  .product-tile__give-alert {
    justify-content: flex-end;
    font-size: 0.625rem;

    a {
      padding: 6px 0;
      color: $color-complementary;

      svg {
        stroke: $color-complementary;
      }
    }
  }

  .is-success {
    color: $color-notification-success;

    svg {
      fill: $color-notification-success;
    }
  }

  .is-success-transition-dark {
    color: $color-tetrad;

    svg {
      fill: $color-tetrad;
    }
  }

  span {
    margin-left: 4px;
  }
}
