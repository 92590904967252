.container--details__bottom {
  background: $color-background-light-red url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEdJREFUGBmFjdkJADAIQ6X7L+gW3aAnhLQJ6Ncjl9EzRxSHDMAUxBNhlZx2tthgNo/uigs1m65EXmJ+es4QTQSagAcg88edmegeQVXcAlN9AAAAAElFTkSuQmCC) repeat top left;
  padding-top: 3.5rem;
  position: relative;
  z-index: -1;

  @include desktop {
    margin-top: 5rem;
    padding: 3.5rem 0;
  }

  &::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
    content: '';
    height: 6px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
