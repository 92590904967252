.fragment--footer-main {
  @include fancy-border($positions: top bottom, $width: 0.5rem, $border-size: 2px);

  background-color: $color-light;
  margin-bottom: 0.5rem;
  padding: 1rem 0 2rem;

  @include desktop {
    padding: 4rem 0;
  }

  .accordion__content li {
    padding: 0.3rem 0;
  }

  .accordion__content li > a {
    font-weight: normal;
    text-decoration: none;

    &[href][target='_blank']::after {
      background-image: url('data:image/svg+xml;charset=utf-8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTEgMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjOWI5YjliIiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIj48cGF0aCBkPSJNOSwwIEw0LjIsNC44IiAvPjxwb2x5bGluZSBwb2ludHM9IjQuOCAwIDkgMCA5IDQuMiIgLz48cGF0aCBkPSJNOSw5IEwwLjYsOSBDMC4yNjg4LDkgMCw4LjczMTIgMCw4LjQgTDAsMCIgLz48L2c+PC9zdmc+');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked ~ .accordion__content {
    height: auto;
  }

  @include desktop {
    .navigation-container {
      display: flex;
      justify-content: space-between;
    }

    .accordion__body {
      padding: 0.5rem 0;
    }
  }

  @include touch {
    .navigation-column {
      border-bottom: 1px solid $color-medium;
      padding: 1rem 0;
    }

    .navigation-column label {
      display: block;
    }

    .navigation-column label::after {
      @include tick($direction: bottom, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);

      content: '';
      height: 0.5em;
      position: absolute;
      right: 0.25rem;
      top: 0.4rem;
      transition: transform $speed-fast;
      width: 0.5em;
    }

    input[type='checkbox']:checked + label::after {
      @include tick($direction: top, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);

      content: '';
      margin-top: 0.25rem;
    }

    .accordion__content {
      height: 0;
      overflow: hidden;
    }

    .accordion__body {
      padding: 0.5rem 1rem 0;
    }
  }
}
