.fragment--quotes {
  .quotes--table-wrapper {
    padding: 1rem 2rem;
  }

  .quotes--table-white {
    background: $color-background-white;
    margin: 0;
  }

  .quotes--table-grey {
    background: $color-lighter;
    margin: 0 -2rem;

    .quotes--table-wrapper {
      padding-top: 2rem;
    }
  }

  .order-table_dialog {
    background:
      radial-gradient(circle at 0 100%, transparent 1.25rem, $color-lighter 1.25rem),
      radial-gradient(circle at 100% 100%, transparent 1.25rem, $color-lighter 1.25rem),
      radial-gradient(circle at 100% 0, transparent 1.25rem, $color-background-white 1.25rem),
      radial-gradient(circle at 0 0, transparent 1.25rem, $color-background-white 1.25rem);
    background-position: bottom left, bottom right, top right, top left;
    background-size: calc(50% + 1px) calc(50% + 1px) !important;
  }
}
