.component--service-navigation {
  background-color: $color-background-white;
  border-bottom: 1px solid $color-border-default;
  border-top: 1px solid $color-border-default;
  margin: 0 0 0.5rem;
  padding: 0.5rem 0;
  position: relative;
  z-index: $zindex--container-header + 10;

  @include touch {
    border-top: 0;
    height: 100vh;
    overflow-y: auto;
  }

  .service-navigation--container {
    @include touch {
      flex-direction: column;
      height: 100vh;
    }
  }

  .service-navigation__target-destination-navigation {
    @include touch {
      border-top: 1px solid $color-border-light;
      padding-left: 0;
    }
  }

  .service-navigation--inner-container {
    display: flex;
    padding: 0;

    @include touch {
      background-color: $color-background-white-shade;
      display: block;
      flex-direction: column;
      height: 100%;
      padding: 0 1rem;
      width: 100%;
    }
  }

  .service-navigation__right-side-menu {
    padding: 1rem;

    @include touch {
      flex-direction: column;
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      width: 108%;
    }
  }

  .service-navigation--language-switcher-container {
    @include touch {
      padding-bottom: 0;
      width: 100%;
    }
  }

  .component--language-switcher,
  .component--target-destination {
    position: relative;
    top: 2px;
  }
}
