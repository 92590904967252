.fragment--product-carousel {
  margin: 0 auto;
  position: relative;

  &.product-carousel.product-carousel-suplementary {
    padding: 2rem 0 3rem;

    @include desktop {
      padding: 2rem 0;
    }
  }

  &.product-carousel .product-carousel__title {
    @include font-h1();
    @include text-uppercase();

    text-align: center;
  }

  .product-carousel__subtitle {
    @include font-h3();

    color: $color-grey;
    text-align: center;

    &::before,
    &::after {
      content: '--';
      margin: 0 0.5rem;
    }
  }

  .glide {
    @include desktop {
      &::before,
      &::after {
        background: $color-white;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 50px;
        z-index: 1;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

  .glide__slide {
    height: auto;

    @include touch {
      + .glide__slide {
        border-left: 1px solid $color-border-default;
      }

      .product-tile__container {
        padding: 0;
      }
    }

    > div {
      margin: 0 auto;
    }

    .form-notifications.is-floating {
      width: 100%;

      &--extra-space {
        bottom: 21%;
      }
    }
  }
}

.glide__slides {
  margin: 0;

  @include desktop {
    padding: 1rem 0;
  }
}

@include desktop {
  .fragment--product-carousel .glide__slide {
    .form-notifications.is-floating {
      width: calc(100% - 2rem);

      &--extra-space {
        bottom: 24%;
      }
    }
  }

  .fragment--notification.is-compact {
    padding: 0.5rem;
  }
}

@include touch {
  .glide__track {
    border: 1px solid $color-border-default;
  }
}

.glide__arrow {
  background-color: $color-tetrad;
  border: 0;
  cursor: pointer;
  height: 48px;
  margin-top: -30px;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 48px;
  z-index: 2;

  &.glide__arrow--small {
    height: 40px;
    margin-top: -10px;
    width: 40px;
  }

  &.is-hidden {
    display: none;
  }
}

.glide__arrow--left {
  left: 0;
}

.glide__arrow--right {
  right: 0;
}

.glide__slide-thumbnails {
  cursor: pointer;
}

// .glide__slide-thumbnails:nth-child(4) {
//   clear: left;
// }

@include touch {
  .wrapper.wrapper__carousel {
    padding: 0;
  }

  .product-carousel__supplementary-carousel-specifier .fragment--product-tile {
    background: $color-white;
  }

  .product-carousel__supplementary-carousel-specifier .glide--product-carousel {
    background: $color-white;
  }
}

@include desktop {
  .wrapper.wrapper__carousel {
    max-width: 87.5rem;
  }
}
