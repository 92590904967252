.fragment--pagination {
  .pagination__index-status + ul {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .pagination__index-status + ul > li {
    @include font-large-intro-copy;

    flex-shrink: 0;
    margin: 0 0.25rem;

    > a {
      padding: 0 0.3334rem;
    }

    > a[href] {
      @extend %link-ui-primary;
    }

    &.is-deactive {
      opacity: 0.2;
    }
  }
}
