.component--service-block {
  .service-block__wrapper {
    background-color: $color-complementary-tint;
    padding: 4rem 2rem;
    position: relative;

    &::before {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOklEQVQoU43MSwoAMAgD0eT+h7ZYaOlHo7N+DNHL2HAGgBWcyGcKbqTghTL4oQiG6IUpOqFEC5bI4QD8PAoKd9j4XwAAAABJRU5ErkJggg==');
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.15;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .service-block__fancy-border {
    @include fancy-border($allPositions: true, $color-outline: $color-complementary-tint);

    background-color: $color-complementary-tint;
    padding: 2rem;
    position: relative;
    z-index: 0;
  }

  .service-block__main-heading {
    @include desktop {
      margin-bottom: 1rem;
    }
  }

  .service-block__list-item-link {
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
    text-decoration: none;
  }

  .service-block__list-item {
    position: relative;

    + .service-block__list-item {
      break-inside: avoid;
      margin-top: 1.125em;
      page-break-inside: avoid;
    }
  }

  .service-block__list-item__icon {
    left: 0;
    position: absolute;
    top: 0.125em;
  }

  dl {
    @include desktop {
      border-left: 1px solid $color-border-default;
      column-gap: 4rem;
      column-rule: 1px solid $color-border-default;
      columns: 2;
      padding-left: 1.5rem;
    }
  }
}
