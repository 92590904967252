.component--assortment-tile-with-diamond-intro {
  .assortment-tile-with-diamond-intro-wrapper {
    background-color: $color-light;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &::after {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2NkYGD4z8DAwMiAH/yHKcCnGCyHbBI2xXAxdCuRFaNoxOY2kAIQQJEjWyFRVhPlGaKCh6gABwBcgBIJm5yNfAAAAABJRU5ErkJggg==');
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.05;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  .side-heading__container {
    background-color: #ebebeb;
    grid-column: span 2;
    grid-row: span 2;
    height: 53.375rem;
    left: -18.5rem;
    position: relative;
    top: -4.5rem;
    transform: rotate(45deg);
    width: 36rem;
  }

  .side-heading-tiles {
    display: grid;
    grid-auto-rows: 14.5rem;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    padding: 1.5rem 0;
  }

  .side-heading-tiles__text {
    @include font-h4;

    color: $color-primary;
    max-width: 10rem;
    overflow: hidden;
  }

  .side-heading-tiles__image {
    object-fit: cover;
    transform: rotate(-45deg) scale(1.5) translateX(0%);
    transform-origin: center center;
  }

  .side-heading-tiles__tile {
    @include text-uppercase;

    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    position: relative;
    text-decoration: none;
    z-index: 0;
  }

  .side-heading-tiles__media-figure-container {
    @include fancy-border($allPositions: true, $width: 0.5rem);

    height: 10rem;
    overflow: hidden;
    position: relative;
    transform: rotate(45deg);
    width: 10rem;
    z-index: -1;
  }

  .side-heading-tiles__text-container {
    align-items: center;
    background-color: $color-light;
    border-bottom: 1px solid $color-border-default;
    border-top: 1px solid $color-border-default;
    box-shadow: 0 0 0 0.5rem #ebebeb;
    display: flex;
    justify-content: center;
    position: relative;
    top: -1.5rem;
    width: 12.1875rem;
  }

  .side-heading__content-container {
    padding: 11.5rem 4rem 0 10rem;
    transform: rotate(-45deg);
  }

  .side-heading__content-container__heading {
    @include text-uppercase;
  }

  .side-heading__content-container__subtitle {
    line-height: 1.5rem;
    margin: 1rem 0 1.5rem 0;

    @include touch {
      padding: 0 1.3rem;
    }
  }
}
