.component--logistic-details {
  width: 100%;

  .accordion__title {
    margin: 0;
  }

  .logistic-details-container {
    display: flex;
    flex-wrap: wrap;
    margin: 1.25rem 0 0;

    .logistic-details-item {
      flex: 0 100%;

      @include desktop {
        flex: 0 50%;
      }

      dt {
        font-weight: 700;
      }

      dd {
        margin: 0.25rem 0 1rem;
      }
    }
  }
}
