.component--order-bulk-upload {
  text-align: left;

  @include touch {
    a {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  @include desktop {
    margin-left: 1.5rem;
  }

  span.link {
    color: $color-primary;
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }

    @include touch {
      width: auto;
      font-size: 1em;
    }
  }

  .icon-link {
    margin-right: 0.5rem;
    margin-bottom: -0.15rem;
    width: 12px;
    height: 12px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .notification__icon {
      display: initial;
    }

    .button {
      min-width: 200px;
      align-self: flex-start;
      max-width: 100%;
      height: auto;
      min-height: 3rem;
    }

    .cart-continue-shopping {
      min-height: 0.875em;
      padding-left: 2px;
    }

    .bottom-items {
      margin-top: auto;
      margin-left: 0;

      .cart-continue-shopping {
        padding-left: 0.35rem;
      }

      @include desktop {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
      }
    }
  }
}

.empty-cart .component--order-bulk-upload {
  @include touch {
    a {
      justify-content: center;
    }
  }
}
