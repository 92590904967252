.fragment--delivery-pickup {
  margin: $space-2x 0;

  @include fancy-border($positions: top);

  .delivery-pickup__label {
    width: 50%;

    @include font-h4();
    @include text-uppercase();

    span {
      padding-left: $space-1x;
      position: relative;
    }

    span:first-of-type::before {
      top: 2px;
    }

    span:first-of-type::after {
      top: 6px;
    }

    @include desktop {
      width: 25%;

      span:first-of-type::before {
        top: 4px;
      }

      span:first-of-type::after {
        top: 8px;
      }
    }
  }

  .delivery-pickup__address {
    flex: 1 1 auto;
  }

  .delivery-pickup__box {
    @include desktop {
      width: 50%;

      &:first-child {
        padding-right: $space-2x;
      }

      &:nth-child(2) {
        padding-left: $space-2x;
      }
    }
  }

  .delivery-pickup__address-box {
    margin-bottom: $space-1x;
  }

  .delivery-pickup__address,
  .delivery-pickup__tel-email,
  .delivery-pickup__dates,
  .delivery-pickup__opening-times {
    p {
      margin: 0;
    }
  }

  .delivery-pickup__edit-address {
    display: inline-block;
    flex: 0 1 auto;
    padding-left: 0.5rem;
  }

  .delivery-pickup__truck-icon {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  .delivery-pickup__icon-wrapper {
    padding-left: 1.5rem;
    position: relative;
  }

  .delivery-pickup__clock-icon {
    left: 0;
    position: absolute;
    top: 0.25em;
  }

  .delivery-pickup__tel-email {
    margin: 1em 0;
  }

  .delivery-pickup__dates {
    margin: 0 -0.5em;
  }

  .delivery-pickup__date {
    align-items: center;
    display: flex;
    padding: 0.5em;

    @include text-align-center();

    @include desktop {
      flex-direction: column;
      width: 20%;
    }

    > input[type='radio'][name='selectedDeliveryDate'],
    > input[type='radio'][name='selectedPickupDate'] {
      + label span:first-of-type::after,
      + label span:first-of-type::before {
        display: none;
      }

      + label span {
        padding-left: 0;
      }

      &:checked + label {
        border-color: $color-tetrad;
        border-left-width: 4px;
        text-indent: -3px;
      }

      &:disabled + label {
        cursor: auto;
        opacity: 0.4;
      }
    }

    @include desktop {
      > input[type='radio'][name='selectedDeliveryDate']:checked + label,
      > input[type='radio'][name='selectedPickupDate']:checked + label {
        border-bottom-width: 4px;
        border-left-width: 1px;
        text-indent: 0;
      }
    }
  }

  .dates__date-box {
    background-color: $color-background-white;
    border: 1px solid $color-border-light;
    cursor: pointer;
    flex: 1 0 auto;
    padding: 2px;
    position: relative;

    @include desktop {
      width: 100%;
    }

    > span {
      display: block;
    }
  }

  .dates__weekday {
    @include font-h4();

    background-color: $color-tetrad;
    color: $color-white;
    margin-right: 0.125rem;
    padding: 0 0.25rem;

    @include desktop {
      @include font-h3();

      background-color: $color-white;
      color: $color-tetrad;
      margin: 0;
      padding: 0;
    }
  }

  .dates__week-info {
    padding: 0 $space-1x;

    @include desktop {
      padding: 0;
    }
  }

  .delivery-pickup__later-date {
    margin-bottom: 1rem;
    margin-top: $space-1x;
  }

  .delivery-pickup__later-time-container .form-select {
    @include desktop {
      align-items: center;
      display: flex;

      > * {
        margin-right: 1.5rem;
      }
    }
  }

  .delivery-pickup__dialog-adress-box {
    border: 1px solid $color-border-light;
    display: flex;
    flex-direction: column;
    padding: $space-1x;
    width: 100%;

    .delivery-pickup__address-list {
      margin-bottom: auto;
    }

    &.selected {
      border-bottom-width: 4px;
      border-color: $color-tetrad;
    }
  }

  @include desktop {
    .delivery-pickup__date-select {
      max-width: 20rem;
    }

    .delivery-pickup__hour-select {
      max-width: 20rem;
    }
  }
}
