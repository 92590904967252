.fragment--filter-selections {
  .filter-selections_button {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .filter-selections__list .filter-selections__item {
    border-radius: 1rem;
    display: inline-block;
    margin: 0 0.5rem 1rem 0;
    padding: 0.3rem 1.9rem 0.3rem 0.8rem;
    position: relative;

    @include desktop {
      border-radius: 1.5rem;
      padding: 0.1rem 2.1rem 0.1rem 0.8rem;
    }

    span {
      background: linear-gradient(-45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50%/75% 75% no-repeat, linear-gradient(45deg, transparent calc(50% - 1px), currentColor calc(50% - 1px), currentColor calc(50% + 1px), transparent calc(50% + 1px)) 50%/75% 75% no-repeat;
      height: 0.7rem;
      position: absolute;
      right: 0.8rem;
      top: 0.85rem;
      width: 0.7rem;

      @include desktop {
        height: 0.75rem;
        top: 0.55rem;
        width: 0.75rem;
      }
    }

    &:not(.filter-selections__item--clear) {
      background-color: $color-notification-error-tint;
    }
  }
}
