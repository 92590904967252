// This component is only styled for desktop

.component--highlight-categories {
  background: $color-tetrad;

  ul {
    justify-content: center;
    margin: 0 -2rem;
    padding: 1rem 2rem;

    @include desktop {
      min-height: 48px;
    }
  }

  ul > li {
    @include font-h4();
    @include text-uppercase;

    display: inline-block;
    padding: 0 1rem;
    vertical-align: text-top;

    @include desktop {
      line-height: 1.15;
    }

    + li {
      border-left: 1px solid $color-border-white;
    }
  }

  ul:hover > li > a {
    opacity: 0.5;
  }

  ul:hover > li:hover > a {
    opacity: 1;
  }

  ul > li.highlight-categories-more {
    display: none;
  }
}
