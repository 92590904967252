.container--breadcrumbs {
  margin: 0;
  padding-left: 1.5rem;

  @include touch {
    margin-top: 2rem;
    padding-left: 0;
  }

  + * {
    margin-top: 0;

    > .container--container > [class^='container']:first-child {
      margin-top: 0;
    }
  }

  + .component--hero-banner.is-full-image .hero-banner__media-container {
    margin-top: 0;
  }

  + .page--registration .container--contact {
    margin-top: 0;
  }

  + .page--product-list {
    @include desktop {
      padding-top: 0;
    }
  }

  + .page--profile,
  + .page--profile .container--profile {
    @include desktop {
      padding-top: 0;

      .columns {
        margin-top: 0;
      }
    }
  }

  + .page--product-detail {
    @include desktop {
      .product-information__container {
        margin-top: 0.5rem;
      }
    }
  }
}

.fragment--breadcrumbs {
  &.breadcrumbs-container {
    padding-left: 1rem;
    padding-bottom: 2rem;

    @include touch {
      padding-bottom: 0.5rem;
    }
  }

  .breadcrumbs {
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 0;

    @include touch {
      margin: 0 0 1.3rem;
    }

    a,
    span {
      color: $color-text-dimmed;

      &::after {
        border-color: $color-text-dimmed;
      }
    }
  }

  .breadcrumb {
    margin-left: 0.4rem;
  }

  .breadcrumbs__icon-home {
    position: relative;
    top: -2px;
    vertical-align: middle;
  }

  .breadcrumb:last-of-type {
    padding-bottom: 0.2rem;

    &::after {
      display: none;
    }
  }
}
