.component--language-switcher {
  li,
  .is-toggle-label {
    cursor: pointer;
    display: flex;
    width: 100%;

    @include desktop {
      color: $color-text-dimmed;
    }

    @include touch {
      color: $color-tetrad;
      display: inline-block;

      &::after {
        float: right;
        margin-right: 0.5rem;
        margin-top: 0.5em;
      }
    }
  }
}

.toggle--language-switcher.is-toggle-trigger:checked ~ .container--header {
  .toggle--language-switcher.is-toggle-label:not(.is-closing)::after {
    @include tick($direction: top, $color: $color-text-dimmed, $size: 0.5em, $tickness: 2px);

    // color: $color-tetrad;
    position: relative;
    right: -0.25rem;
    top: 0.5rem;
    transition: transform $speed-fast;
  }

  .toggle--language-switcher-list.is-toggle-handler {
    @include touch {
      transform: translateX(0);
    }

    @include desktop {
      max-height: 420rem;
    }
  }
}

.toggle--language-switcher.is-toggle-trigger:not(:checked) ~ .container--header {
  .toggle--language-switcher.is-toggle-label:not(.is-closing)::after {
    @include desktop {
      @include tick($direction: bottom, $color: $color-text-dimmed, $size: 0.5em, $tickness: 2px);

      margin-left: 0.5rem;
      position: relative;
      top: 0.5rem;
      transition: transform $speed-fast;
    }

    @include touch {
      @include tick($direction: right, $color: $color-tetrad, $size: 0.5em, $tickness: 2px);
    }
  }

  .toggle--language-switcher-list.is-toggle-handler {
    @include touch {
      transform: translateX(100%);
    }
  }
}
