// This is the default container from Bloomreach Hippo what contains containers

.container--container {
  > * {
    margin: $distance-between-components 0;

    @include touch {
      margin: 1rem 0;
    }
  }

  > .container--product-information {
    margin: 0;
  }

  > .page--profile {
    margin-top: 0;

    @include touch {
      margin-top: 2rem;
    }
  }

  > .container--addresses,
  > .container--lists,
  > .container--lists-details,
  > .container--reservations,
  > .container--orders,
  > .container--quotes,
  > .container--profile,
  > .page--users-list,
  > .page--product-list {
    @include desktop {
      margin-top: 0;
      padding-top: 5rem;
    }
  }

  .fragment--lists-overview {
    @include touch {
      position: relative;
    }
  }
}
