// Inline-block
@mixin fix-inline-block() {
  font-size: 0;

  > * {
    display: inline-block;
    font-size: 1rem;
    vertical-align: top;
  }
}

// Arrow with borders
@mixin arrow($direction: left, $color: $color-primary, $size: 1rem) {
  height: 0;
  width: 0;

  @if $direction == top {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  }

  @else if $direction == right {
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-top: $size solid transparent;
  }

  @else if $direction == bottom {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }

  @else if $direction == left {
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-top: $size solid transparent;
  }
}

@mixin tick($direction: right, $color: $color-text-default, $size: 0.5em, $display: inline-block, $tickness: 1px) {
  border-bottom: 0;
  border-left: 0;
  border-right: $tickness solid $color;
  border-top: $tickness solid $color;
  content: '';
  display: $display;
  height: $size;
  transform-origin: center;
  width: $size;

  @if $direction == top {
    transform: rotate(-45deg);
  }

  @else if $direction == right {
    transform: rotate(45deg);
  }

  @else if $direction == bottom {
    transform: rotate(135deg);
  }

  @else if $direction == left {
    transform: rotate(-135deg);
  }
}

@mixin negative-border-radius($color: $color-primary, $contrast: transparent, $position: all, $size: 1rem) {
  background-repeat: no-repeat !important;

  @if $position == all {
    background:
      radial-gradient(circle at 0 100%, $contrast $size, $color $size),
      radial-gradient(circle at 100% 100%, $contrast $size, $color $size),
      radial-gradient(circle at 100% 0, $contrast $size, $color $size),
      radial-gradient(circle at 0 0, $contrast $size, $color $size);
    background-position: bottom left, bottom right, top right, top left;
    background-size: calc(50% + 1px) calc(50% + 1px) !important;
  }

  @else if $position == top {
    background:
      radial-gradient(circle at 100% 0, $contrast $size, $color $size),
      radial-gradient(circle at 0 0, $contrast $size, $color $size);
    background-position: top right, top left;
    background-size: calc(50% + 1px) 100% !important;
  }

  @else if $position == right {
    background:
      radial-gradient(circle at 100% 100%, $contrast $size, $color $size),
      radial-gradient(circle at 100% 0, $contrast $size, $color $size);
    background-position: bottom right, top right;
    background-size: 100% calc(50% + 1px) !important;
  }

  @else if $position == bottom {
    background:
      radial-gradient(circle at 0 100%, $contrast $size, $color $size),
      radial-gradient(circle at 100% 100%, $contrast $size, $color $size);
    background-position: bottom left, bottom right;
    background-size: calc(50% + 1px) 100% !important;
  }

  @else if $position == left {
    background:
      radial-gradient(circle at 0 100%, $contrast $size, $color $size),
      radial-gradient(circle at 0 0, $contrast $size, $color $size);
    background-position: bottom left, top left;
    background-size: 100% calc(50% + 1px) !important;
  }
}

// $positions: (pos1, pos2)
@mixin fancy-border($positions: (), $width: 4px, $allPositions: false, $color-border: $color-border-default, $color-outline: $color-border-light, $allPositionsBorderSize: 1px, $border-size: 1px) {
  @if length($positions) != 0 and $allPositions == false {
    $boxShadow: ();

    @each $pos in $positions {
      @if $pos == top {
        $boxShadow: append($boxShadow, 0 -#{$width} 0 0 #{$color-outline}, comma);

        border-top: $border-size solid $color-border;
        // box-shadow: 0 -$width 0 0 $color-outline;
      }

      @if $pos == right {
        $boxShadow: append($boxShadow, #{$width} 0 0 0 #{$color-outline}, comma);

        border-right: $border-size solid $color-border;
        // box-shadow: $width 0 0 0 $color-outline;
      }

      @if $pos == bottom {
        $boxShadow: append($boxShadow, 0 #{$width} 0 0 #{$color-outline}, comma);

        border-bottom: $border-size solid $color-border;
        // box-shadow: 0 $width 0 0 $color-outline;
      }

      @if $pos == left {
        $boxShadow: append($boxShadow, -#{$width} 0 0 0 #{$color-outline}, comma);

        border-left: $border-size solid $color-border;
        // box-shadow: -$width 0 0 0 $color-outline;
      }
    }

    box-shadow: $boxShadow;
  }

  @if $allPositions == true {
    border: $allPositionsBorderSize solid $color-border;
    box-shadow: 0 0 0 $width $color-outline;
  }
}

@mixin background-line($position: none, $color: $color-border-default, $width: 100%, $sitePosition: 0, $zIndex: 0) {
  background: $color;
  bottom: 0;
  content: '';
  height: 2px;
  margin: auto 0;
  position: absolute;
  top: 0;
  width: $width;
  z-index: $zIndex;

  @if $position == left {
    left: $sitePosition;
  }

  @if $position == right {
    right: $sitePosition;
  }
}

@mixin backdrop {
  background: $color-background-backdrop;
  content: '';
  height: 100vh;
  left: 0;
  position: fixed;
  width: 100vw;
}

// (c) Bulma
// https://github.com/jgthms/bulma/blob/master/sass/utilities/mixins.sass
@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}

@mixin combi-background($background: none, $backgroundMobile: none) {
  background: $background;
  bottom: 0;
  left: calc(((100% - 1096px) / 2) + (1096px / 6));
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;

  /* Sadly, the calculations don't work perfectly for the sizes inbetween in this case */
  @media screen and (min-width: $tablet) and (max-width: 1095px) {
    left: calc(1096px / 6);
  }

  @include touch {
    left: 0;

    @if $backgroundMobile != none {
      background: $backgroundMobile;
    }
  }
}

@mixin background-image-props-for-banners {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/** Defaults are for the large teaser banner for single banner layout **/
@mixin teaser-text-container($height, $width, $padding) {
  @include fancy-border($allPositions: true, $width: 0.5rem, $allPositionsBorderSize: 2px);

  background-color: $color-white;
  display: flex;
  flex-direction: column;
  height: $height;
  justify-content: center;
  padding: $padding;
  position: absolute;
  text-align: center;
  width: $width;
}

@mixin teaser-big-text-container {
  @include teaser-text-container($height: 240px, $width: 48%, $padding: 1.5rem);
}

@mixin teaser-small-text-container {
  @include teaser-text-container($height: 5.9rem, $width: 17.7%, $padding: 1rem);
}

@mixin teaser-glide-text-container {
  @include teaser-text-container($height: 31%, $width: 70%, $padding: 0.5rem);
}

@mixin teaser-single-banner-text-container {
  @include teaser-text-container($height: 6.8rem, $width: 12rem, $padding: 0.5rem);
}

@mixin teaser-long-text-container {
  @include teaser-text-container($height: 7rem, $width: 30%, $padding: 1.5rem);
}

@mixin teaser-large-text-container {
  @include teaser-text-container($height: 13.5rem, $width: 30%, $padding: 1.5rem);
}

@mixin placeholder-shown-invalid {
  background-color: $color-notification-error-tint;
  border-color: $color-notification-error;

  ~ span {
    color: $color-notification-error;
  }

  ~ .form-notifications .is-invalid {
    color: $color-notification-error;
    display: block;
    font-size: 0.85em;
  }
}
