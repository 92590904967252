.fragment--order-reservation {
  background-color: $color-background-lighter;

  .order-reservation__container {
    padding: 4rem 1rem 3rem 1rem;
  }

  .order-reservation__accordion-header {
    align-items: center;
    display: flex;
    margin: 0.4rem 0 1rem 0;
    width: 90%;

    &::after {
      @include tick($direction: bottom, $color:  $color-tetrad, $size: 0.5em, $tickness: 2px);

      margin-left: 0.5rem;
    }
  }

  .order-reservation__accordion-header--order-number::before {
    content: '#';
  }

  .order-reservation__show-all-link {
    align-self: flex-end;
    padding-right: 3rem;
    position: relative;
    z-index: 1;
  }

  .order-reservation__accordion-head {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    @include desktop {
      flex-direction: row;
    }
  }

  .accordion__title::after {
    display: none;
  }

  .order-reservation__accordion-head--item {
    display: flex;
    padding: 1rem 0;
    width: 90%;

    &::before {
      color: $color-text-dimmed;
      content: attr(data-heading);
      width: 50%;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-border-light;
    }
  }

  .is-standby {
    order: 1;

    .order-reservation__accordion-head--item {
      opacity: 0.4;
    }

    .order-reservation__accordion-head--item.is-status {
      opacity: 1;
    }
  }

  .accordion__title.active {
    background-color: $color-background-default;
    border-bottom: 1px solid $color-tetrad;

    &::after {
      display: none;
    }

    .order-reservation__accordion-header {
      &::after {
        @include tick($direction: top, $color:  $color-tetrad, $size: 0.5em, $tickness: 2px);

        margin-left: 0.5rem;
      }
    }

    .order-reservation__accordion-head--item {
      &:not(:last-child) {
        border-bottom: 1px solid $color-border-default;
      }
    }
  }

  .accordion__list {
    border: 0;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .accordion__item {
    background-color: $color-white;
    margin-bottom: 1rem;
    width: 100%;
  }

  .accordion__content {
    padding: 1rem;

    @include desktop {
      padding: 1.6rem 2.5rem 2rem 2.5rem;
    }
  }

  .accordion__item.active {
    border: 1px solid $color-tetrad;
  }

  .order-reservation__accordion-head--item-postpone {
    align-items: center;
    display: flex;
  }

  .is-postpone-option,
  .is-reset-postpone-option {
    color: $color-primary;
  }

  svg.is-postpone-icon {
    fill: $color-primary;
    stroke: $color-primary;
  }

  .order-reservation__table-head {
    border-bottom: 1px solid $color-border-default;
    color: $color-text-dimmed;
    display: flex;
    padding: 1rem 0;
  }

  @include desktop {
    .accordion__title {
      margin-bottom: 0;
    }

    .accordion__title::after {
      bottom: 0;
      display: block;
      position: relative;
      right: 2rem;
      top: 0;
    }

    .order-reservation__accordion-header {
      display: none;
    }

    .order-reservation__accordion-head--item {
      padding-left: 1rem;
    }

    .order-reservation__accordion-head--item:not(:last-child),
    .order-reservation__table-cell--header:not(:last-child) {
      flex-grow: 1;
      overflow: hidden;
      width: 15%;

      &:first-child {
        padding-left: 1rem;
      }
    }

    .order-reservation__accordion-head--item:last-child,
    .order-reservation__table-cell--header:last-child {
      flex-grow: 1;
      overflow: hidden;
      width: 35%;

      &:first-child {
        padding-left: 1rem;
      }
    }

    .accordion__title .order-reservation__accordion-head--item {
      border: 0;

      &::before {
        display: none;
      }
    }

    .accordion__title.active {
      border-bottom: 1px solid $color-tetrad;

      &::after {
        display: block;
      }

      .order-reservation__accordion-head--item:not(:last-child) {
        border: 0;
      }
    }
  }
}
