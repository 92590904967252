// Color palette
$color-primary: #f00000; // rgb(229, 0, 0)
$color-complementary: #4ec2c2;
$color-tetrad: #202a4f;

// Grayscale
$color-dark: $color-tetrad;
$color-grey: #979797;
$color-light: #ebebeb;
$color-medium: #d7d7d7;
$color-lighter: #f7f7f7;
$color-white: #fff;
$color-grey-blueish: #bec3c7;
$color-text-black: black;

// Color tints
$color-primary-tint: #fff1f2;
$color-complementary-tint: #b8e7e7;
$color-tetrad-tint: hotpink;

// Color shades
$color-primary-shade: hotpink;
$color-complementary-shade: hotpink;
$color-tetrad-shade: hotpink;

// Text colors
$color-text-default: $color-tetrad;
$color-text-dimmed: $color-grey;
$color-text-primary-hover :#c00f0f;
$color-complementary-hover: #2c7f7c;

// Background colors
$color-background-white: $color-white;
$color-background-white-shade: #fbfbfb;
$color-background-light: #f1f1f1;
$color-background-lighter: $color-lighter;
$color-background-default: $color-light;
$color-background-backdrop: rgba(255, 255, 255, 0.9);
$color-background-grey: rgba(235, 235, 235, 0.4);
$color-background-light-red : #fde5e5;

// Border colors
$color-border-white: $color-white;
$color-border-light: $color-light;
$color-border-default: $color-grey;
$color-border-check: #9b9b9b;

// Notification colors
$color-notification-success-background: #eef7f0;
$color-notification-success-text: #5cb269;
$color-notification-warning-background: #fff3e6;
$color-notification-warning-text: #ff8d0a;
$color-notification-info-background: #e5f4fa;
$color-notification-info-text: #0091d2;
$color-notification-error-background: #fef2f2;
$color-notification-error-text: $color-primary;

// Global notification colors
$color-global-notification-info-background: #eff8fd;
$color-global-notification-info-text: #257dcc;
$color-global-notification-warning-background: #fff2cb;
$color-global-notification-warning-text: #0a2556;
$color-global-notification-error-background: #fcf2f2;
$color-global-notification-error-text: #d65645;

// Old notification colors
// @deprecated
$color-notification-success: $color-notification-success-text;
$color-notification-success-tint: $color-notification-success-background;
$color-notification-warning: $color-notification-warning-background;
$color-notification-info: $color-notification-info-background;
$color-notification-error: $color-notification-error-text;
$color-notification-error-tint: $color-notification-error-background;

// Promotions
$color-secondary-promotion: #faf3c9;

$color-carousel-bg: #382d55;
$color-carousel-stripe: #544a6d;
$color-svg-cart: #666;
