.component--inpage-navigation {
  @include touch {
    .inpage-navigation__shadow {
      position: relative;
    }

    .inpage-navigation__shadow,
    .inpage-navigation__wrapper {
      &::before,
      &::after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 1.5rem;
        z-index: 2;
      }

      &::before {
        background-image: linear-gradient(to right, $color-white, rgba(255, 255, 255, 0));
        left: 0;
      }

      &::after {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $color-white);
        right: 0;
      }
    }

    .inpage-navigation__wrapper {
      &::before,
      &::after {
        bottom: calc(-100% - 1px);
        top: unset;
      }
    }

    .inpage-navigation__menu-nav {
      border-bottom: 1px solid $color-border-default;
      border-top: 1px solid $color-border-default;
    }

    .inpage-navigation__item-list {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 0.5rem 0;
    }

    .inpage-navigation__items {
      flex: 0 0 auto;

      &:not(:last-child) {
        border-right: 1px solid $color-border-default;
      }
    }
  }

  @include desktop {
    padding: 0 1rem;
  }

  .inpage-navigation__submenu {
    display: none;

    @include touch {
      border-bottom: 1px solid $color-border-default;
    }
  }

  .inpage-navigation__title {
    @include text-uppercase;
    @include font-h4();

    color: $color-grey;
    display: inline-block;
    padding: 0.5rem 0;

    @include touch {
      padding: 0.5rem $space-1x;
    }

    &.selected {
      color: $color-primary;

      &::after {
        @include tick($direction: bottom, $color: $color-primary, $size: 0.5em, $tickness: 2px);
      }
    }
  }

  .toggle--inpage-navigation::after {
    @include tick($direction: bottom, $color: $color-grey, $size: 0.5em, $tickness: 2px);

    left: 0.5rem;
    margin-right: 0.5rem;
    position: relative;
    top: -0.25rem;
  }

  .inpage-navigation__toggle:checked {
    ~ .inpage-navigation__wrapper .inpage-navigation__submenu {
      display: inline-block;

      @include touch {
        background: $color-white;
        bottom: calc(-100% - 2px);
        display: flex;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 2;
      }

      @include desktop {
        padding-left: 1rem;
      }
    }

    ~ .toggle--inpage-navigation::after {
      @include tick($direction: top, $color: $color-grey, $size: 0.5em, $tickness: 2px);

      top: 0;
    }

    ~ .toggle--inpage-navigation.selected::after {
      @include tick($direction: top, $color: $color-primary, $size: 0.5em, $tickness: 2px);

      top: 0;
    }
  }
}
