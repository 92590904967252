.container--header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex--container-header;

  .header-background {
    background-color: $color-background-white-shade;
  }

  .icon-link {
    position: absolute;
    top: 1.2rem;
    right: 3.5rem;
    cursor: pointer;
    color: $color-grey;
    width: 24px;
    height: 24px;

    &.inner-search {
      @include desktop {
        display: none;
      }

      display: inline-block;
      top: 0.7rem;
      z-index: $zindex__barcode-icon;
    }
  }
}

.toggle--hamburger.is-toggle-handler {
  background-color: $color-background-white;
}

@include touch {
  .toggle--hamburger.is-toggle-trigger:checked ~ .container--header::after {
    @include backdrop;

    transform: translateY(-0.5rem);
    z-index: -1;
  }

  .toggle--hamburger.is-toggle-trigger:checked ~ .container--header {
    .toggle--search-navigation span {
      margin: 0;
    }

    .meta-navigation__container {
      justify-content: flex-end;
      position: relative;
      right: -1.3571rem;
      top: 0.9285rem;
    }

    .meta-navigation__profile,
    .meta-navigation__favorite,
    .header__second-segment,
    .header__third-segment {
      display: none;
    }

    .header-background {
      background-color: $color-background-white;
    }
  }
}

@include touch {
  .toggle--hamburger.is-toggle-label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 1.3rem;
    justify-content: space-between;
    left: -9px;
    margin: -0.5rem 0 0 -0.5rem;
    position: relative;
    top: 3px;
    user-select: none;
    width: 1.5rem;
    z-index: 439;

    > span {
      background-color: $color-grey;
      height: 2px;
      transition: transform $speed cubic-bezier(0.8, 0, 0.55, 0.94);
      width: 100%;
    }
  }

  .toggle--hamburger.is-toggle-trigger:not(:checked) ~ .container--header {
    .toggle--hamburger.is-toggle-label {
      > span:nth-child(2) {
        opacity: 1;
        transition: opacity $speed cubic-bezier(0.8, 0, 0.55, 0.94);
      }
    }

    .toggle--hamburger.is-toggle-handler {
      display: none;
    }
  }

  .toggle--hamburger.is-toggle-trigger:checked ~ .container--header {
    .toggle--hamburger.is-toggle-label {
      > span:nth-child(1) {
        transform: translateY(8px) rotate(-45deg);
      }

      > span:nth-child(2) {
        opacity: 0;
      }

      > span:nth-child(3) {
        transform: translateY(-8px) rotate(45deg);
      }
    }

    .toggle--hamburger.is-toggle-handler {
      display: block;
    }
  }

  .header-background__gutter {
    padding: 0.5rem 0;
  }

  .component--logo {
    > a {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
