.fragment--order-details {
  @include fancy-border($positions: top);

  border-bottom: 1px solid $color-border-light;

  .order-details__addresses span {
    display: block;
  }

  .download-invoices {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $color-complementary;
    cursor: pointer;
    // line added to remove wierd tap highlight
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    svg {
      stroke: $color-complementary;
    }

    &.not-available {
      color: $color-grey;
      cursor: default;

      svg {
        stroke: $color-grey;
      }
    }
  }

  @include desktop {
    .order-details__dlist-info {
      display: flex;

      > dl {
        width: 50%;
      }
    }
  }
}
